import React, { useState } from 'react';
import { Link } from 'react-router-dom';


const VerifyAddress = () => {
    const [showPassword, setShowPassword] = useState(false);

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="flex flex-col md:flex-row min-h-screen verify-addr">
            {/* Left Section */}
            <div className="md:flex-1 bg-cover bg-center relative fade-in-left verify-addr-bg">
                <div className="absolute inset-0 bg-black opacity-40"></div>
                <div className="absolute inset-0 flex flex-col items-start justify-start md:px-20 px-5 mt-12">
                    <div className="text-center md:text-left">
                    <Link to="/">
                        <img
                            src="assets/images/main-logo.svg"
                            alt="Logo"
                            className="h-10 mx-auto sm:mx-0"
                        />
                        </Link>
                    </div>
                    <div className='w-full'>
                        <h1 className='txt-style-3 text-center !text-white uppercase'>
                            Verify Your Address Details
                        </h1>

                        <form className='verify-addr-form'>
                            <div className='flex w-full justify-between'>
                                <div className="mb-6 w-1/2 me-3">
                                    <input
                                        type="text"
                                        id="address"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                        placeholder="Name*"
                                        required
                                    />
                                </div>
                                <div className="mb-6 w-1/2">
                                    <input
                                        type="email"
                                        id="email"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                        placeholder="Email*"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="mb-6">
                                <input
                                    type="text"
                                    id="address"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                    placeholder="Address*"
                                    required
                                />
                            </div>
                            <div className="mb-6">
                                <input
                                    type="text"
                                    id="city"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                    placeholder="City*"
                                    required
                                />
                            </div>
                            <div className="mb-6">
                                <input
                                    type="text"
                                    id="state"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                    placeholder="State*"
                                    required
                                />
                            </div>
                            <div className="mb-6">
                                <input
                                    type="text"
                                    id="zipcode"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                    placeholder="Zip/Postal Code*"
                                    required
                                />
                            </div>

                            {/* Log In Button */}
                            <div className='flex  justify-between mt-10'>
                                <div>
                                    <Link to='/addaddressdetails'>
                                        <button className=" text-white px-8 py-2 rounded-sm mb-6 btn-style-2 ">
                                            Back
                                        </button>
                                    </Link>
                                </div>
                                <div>
                                    <Link to='/success'>
                                        <button className=" text-white px-10 py-2 rounded-sm mb-6 btn-style-1 ms-5">
                                            Continue
                                        </button>
                                    </Link>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default VerifyAddress;
