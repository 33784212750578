import React from 'react';

const ProgressBar = ({ value, size, color }) => {
  const getSizeClass = () => {
    if (size === 'sm') {
      return 'h-2';
    } else if (size === 'md') {
      return 'h-4';
    } else if (size === 'lg') {
      return 'h-6';
    } else {
      return 'h-4'; // Default size
    }
  };

  return (
    <div className={`relative ${getSizeClass()}   overflow-hidden  `}>
      <div
        className={`absolute top-0 left-0 progress-bg-color h-full transition-all ease-in-out duration-300 rounded-full fade-in-left`}
        style={{ width: `${value}%` }}
      ></div>
    </div>
  );
};

export default ProgressBar;
