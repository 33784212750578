import React, { useState } from "react";

import DonorManagementSideBar from "./DonorManagementSideBar";
import TopHeader from "../Home/TopHeader";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Triangle } from "react-loader-spinner"; // Import Triangle loader

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import * as yup from "yup";

import axios from "axios";
import { TQ_URL } from "../../utility/baseUrl";
import DonorManagementTop from "./DonorManagementTop";

const schema = yup.object().shape({
  document: yup.mixed().required("Document is Required *"),
  note: yup.string().required("Note is Required *"),
  signature: yup.string().required("signature is required"),
});

export const CustomReceipt = () => {
  const [uploadedLogo, setUploadedLogo] = useState([]);

  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const [documentImage, setDocumentImage] = useState(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const [causeList, setCauseList] = useState(false);
  const [isImageHover, setIsImageHover] = useState(false);
  const { state } = useLocation();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const partnerData = JSON.parse(localStorage.getItem("partnerData"));

  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleFileChange = (event) => {
    const files = event.target.files;

    console.log(files);

    const file = event.target.files[0];

    if (file) {
      const fileSizeInMegabytes = file.size / (1024 * 1024); // Convert bytes to megabytes
      const maxSize = 20; // Maximum allowed size in megabytes

      if (fileSizeInMegabytes > maxSize) {
        toast.error(
          `${file.name} File size exceeds the maximum limit of ${maxSize} MB`
        );
        event.target.value = null;
        return;
      }
    }

    setUploadedFile(file);
  };

  const removeFile = () => {
    // Clear the uploadedFile state
    setUploadedFile(null);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);

    const file = e.dataTransfer.files[0];
    if (file) {
      setValue("document", file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setDocumentImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const onSubmitHandler = async (data, event) => {
    console.log("page is hitting success ");

    console.log(uploadedFile);
    if (uploadedFile == null) {
      toast.error("Please select logo  to upload..");
      return;
    }
    event.preventDefault();

    setLoading(true);

    const formData = new FormData();
    formData.append("note", data.note);
    formData.append("partnerId", partnerData.partnerId);
    formData.append("signature", data.signature);

    // uploadedFiles.forEach((file) => {
    formData.append(`file`, uploadedFile);
    // });
    const token = localStorage.getItem("accessTokenPartner");
    axios
      .post(`${TQ_URL}/saveCustomReceiptLogo`, formData, {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log("custom logo");
        console.log(response.data);
        setLoading(false);
        if (response.data.msg == "Logo saved Successfully") successFormSubmit();
        reset();
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.data.message == "Failed to upload files..")
          toast.error("Failed to upload the image.. Please try again.");
        else toast.error("Something Went wrong");
        setTimeout(() => {}, 1000);
        console.error("Error uploading file:", error);
      });
  };

  const successFormSubmit = () => toast.success("Logo Added Successfully!");

  return (
    <>
      {/* <TopHeader /> */}
      <DonorManagementTop />
      <div className="flex">
        <div className="md:w-64 w-1/12 absolute md:relative">
          <DonorManagementSideBar />
        </div>

        <main className="w-full verify-user ">
          <div className=" shadow-md">
            <div className="md:flex justify-between container items-center px-10 md:py-6 py-6">
              <div className="text-center">
                <h2 className="text-2xl font-bold">Customize Receipt</h2>
              </div>
            </div>
          </div>

          <div className="w-full item-center md:py-4 px-16">
            {loading ? (
              <div className="flex items-center justify-center h-[200px] w-full md:pr-20">
                <Triangle
                  height="80"
                  width="80"
                  color="#4fa94d"
                  ariaLabel="triangle-loading"
                  wrapperStyle={{}}
                  visible={true}
                />
              </div>
            ) : (
              <form
                onSubmit={handleSubmit(onSubmitHandler)}
                className="fundraiser-for-other-form md:py-12 md:px-20 p-6 md:me-20"
              >
                <div className="md:flex">
                  <label>Upload Documents</label>
                  <div
                    className="flex justify-between w-full items-center  my-5 md:my-0"
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                  >
                    <div
                      className="mb-6 relative flex flex-col items-center w-full"
                      style={{
                        borderColor: isDragOver ? "blue" : "gray",
                        boxShadow: isDragOver
                          ? "0 0 10px rgba(0, 0, 255, 0.5)"
                          : "none",
                        borderRadius: "8px",
                        borderStyle: "dashed",
                        borderWidth: "2px",
                        paddingInline: "20px",
                        paddingBlock: "10px",
                        cursor: "pointer",
                      }}
                    >
                      {/* Display uploaded files with remove button */}
                      {/* {uploadedFiles.map((file, index) => (
                        <div key={index} className="flex items-center mb-2">
                          <p className="mr-2">{file.name}</p>
                          <button
                            type="button"
                            className="text-red-500 hover:text-red-700 cursor-pointer"
                            onClick={() => removeFile(index)}
                          >
                            &#x2715;
                          </button>
                        </div>
                      ))} */}

                      {uploadedFile && (
                        <div className="flex items-center mb-2">
                          {/* Display the name of the uploaded file */}
                          <p className="mr-2">{uploadedFile.name}</p>

                          {/* Button to remove the file */}
                          <button
                            type="button"
                            className="text-red-500 hover:text-red-700 cursor-pointer"
                            // onClick={() => removeFile()} // Call removeFile without an index since there is only one file
                            onClick={removeFile}
                          >
                            &#x2715; {/* Unicode symbol for a cross */}
                          </button>
                        </div>
                      )}

                      <label htmlFor="documentInput" className="cursor-pointer">
                        <img
                          src="assets/images/cloud-icon.svg"
                          className="mx-auto"
                          alt="Cloud Icon"
                        />
                        <p className="text-xs text-center">
                          <span className="font-bold underline">
                            Click to upload Logo
                          </span>
                        </p>
                      </label>
                      <input
                        {...register("document")}
                        type="file"
                        accept=".jpg, .jpeg, .png"
                        onChange={handleFileChange}
                        className="hidden"
                        id="documentInput"
                      />
                    </div>
                  </div>
                </div>

                <div className="mb-6 relative md:flex">
                  <label>Note</label>
                  <div className="w-full">
                    <textarea
                      {...register("note")}
                      rows={3}
                      style={{ resize: "none" }}
                      placeholder="note"
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                        errors.description && "border-red-500"
                      }`}
                    />
                    <p className="text-red-500 text-sm mt-1">
                      {errors.note?.message}
                    </p>
                  </div>
                </div>

                <div className="mb-6 relative md:flex">
                  <label>Signature</label>
                  <div className="w-full">
                    <input
                      {...register("signature")}
                      placeholder="signature"
                      type="text"
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                        errors.signature && "border-red-500"
                      }`}
                    />
                    <p className="text-red-500 text-sm mt-1">
                      {errors.signature?.message}
                    </p>
                  </div>
                </div>

                <div className="flex  justify-center items-center">
                  {/* Submit Button */}
                  <button
                    type="submit"
                    className="md:w-1/3 w-1/2 text-white p-2 rounded-md btn-style-1 mt-4 "
                  >
                    Submit
                  </button>
                </div>
              </form>
            )}
          </div>
        </main>
      </div>

      <ToastContainer theme="colored" />
    </>
  );
};
