import React from 'react'
import TopHeader from '../Home/TopHeader'
import DonorManagementSideBar from '../DonorManagement/DonorManagementSideBar'
import SocialMediaSideBar from './SocialMediaSideBar'
import { FaFacebook, FaInstagramSquare } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";

const SocialMediaCampaign = () => {
    return (
        <>
            <TopHeader />
            <div className="flex">
                <div className="md:w-64 w-1/12 absolute md:relative">
                    <SocialMediaSideBar />
                </div>

                <main className="w-full verify-user">
                    <div className=" mx-auto shadow-md">
                        <div className="md:flex justify-between container items-center px-10 md:py-6 py-6">
                            <div className="text-center">
                                <h2 className="text-2xl font-bold">Socail media campaign</h2>
                            </div>
                        </div>
                    </div>
                    <div className=" mx-auto h-full bg-slate-200 pt-10 overflow-hidden">
                        <div className="flex flex-col justify-between items-center ">

                            <div className='bx-container  w-full lg:w-3/6   p-4 shadow-lg bg-white' >
                                <div className="flex flex-row container justify-between ">
                                    <div className='flex flex-row'>
                                        <FaFacebook className='mr-2 text-6xl text-[#1877f2]' />
                                        <div className="font-bold text-2xl mb-2 ml-5 mr-2">Facebook  <span className='text-sm block'>Page or Group</span> </div>
                                    </div>
                                    <button
                                        className="btn-style-1 text-white text-lg  rounded px-10 mr-2"

                                    >
                                        Connect
                                    </button>

                                </div>

                            </div>

                        </div>
                        <div className="flex flex-col justify-between items-center mt-7">

                            <div className='bx-container  w-full lg:w-3/6   p-4 shadow-lg bg-white' >
                                <div className="flex flex-row container justify-between ">
                                    <div className='flex flex-row'>
                                        <FaInstagramSquare className='mr-2 text-6xl text-[#f04d48]' />
                                        <div className="font-bold text-2xl mb-2 ml-5 mr-2">Instagram   <span className='text-sm block'>Business or Creator accounts</span> </div>
                                    </div>
                                    <button
                                        className="btn-style-1 text-white text-lg  rounded px-10 mr-2"

                                    >
                                        Connect
                                    </button>

                                </div>

                            </div>

                        </div>
                        <div className="flex flex-col justify-between items-center mt-7">

                            <div className='bx-container  w-full lg:w-3/6   p-4 shadow-lg bg-white' >
                                <div className="flex flex-row container justify-between ">
                                    <div className='flex flex-row'>
                                        <FaSquareXTwitter className='mr-2 text-6xl text-[#000000]' />
                                        <div className="font-bold text-2xl mb-2 ml-5 mr-2">Twitter   <span className='text-sm block'>Profile</span> </div>
                                    </div>
                                    <button
                                        className="btn-style-1 text-white text-lg  rounded px-10 mr-2"

                                    >
                                        Connect
                                    </button>

                                </div>

                            </div>

                        </div>
                    </div>



                </main>
            </div>

        </>
    )
}

export default SocialMediaCampaign