import React from "react";
import TopHeader from "../Home/TopHeader";
import DonorManagementSideBar from "./DonorManagementSideBar";
import axios from "axios";

import { TQ_URL } from "../../utility/baseUrl";
import DonorManagementTop from "./DonorManagementTop";

const Report = () => {
  return (
    <>
      {/* <TopHeader /> */}
      <DonorManagementTop />
      <div className="flex">
        <div className="md:w-64 w-1/12 absolute md:relative">
          <DonorManagementSideBar />
        </div>

        <main className="w-full verify-user">
          <div className=" mx-auto shadow-md">
            <div className="md:flex justify-between container items-center px-10 md:py-6 py-6">
              <div className="text-center">
                <h2 className="text-2xl font-bold">Reports</h2>
              </div>
            </div>
          </div>
          <div className="container mx-auto">
            <div className="flex flex-wrap justify-between webkit-center">
              <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 p-4">
                <div className="max-w-[250px] rounded overflow-hidden shadow-lg py-5 text-center">
                  <div className="font-bold text-xl mb-2">Donor report</div>

                  <a
                    className="btn-style-1 text-white px-4 md:mt-8 py-2 rounded mt-2"
                    download="DonorReport.xlsx"
                    // target="_blank"
                    href="http://prabalta.org:8087/usproject/exportDonors?partnerId=1"
                  >
                    Fetch
                  </a>
                </div>
              </div>
              <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 p-4">
                <div className="max-w-[250px] rounded overflow-hidden shadow-lg py-5 text-center">
                  <div className="font-bold text-xl mb-2">
                    Fundraiser report
                  </div>
                  {/* <button
                    type="submit"
                    className="btn-style-1 text-white px-4 py-2 rounded mt-2"
                  >
                    Fetch
                  </button> */}

                  <a
                    className="btn-style-1 text-white px-4 md:mt-8 py-2 rounded mt-2"
                    download="FundraiserReport.xlsx"
                    target="_blank"
                    href="http://prabalta.org:8087/usproject/exportFundraiserDonations?partnerId=1"
                  >
                    Fetch
                  </a>
                </div>
              </div>

              <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 p-4">
                <div className="max-w-[250px] rounded overflow-hidden shadow-lg py-5 text-center">
                  <div className="font-bold text-xl mb-2">Donation Report</div>
                  {/* <button
                    type="submit"
                    className="btn-style-1 text-white px-4 py-2 rounded mt-2"
                  >
                    Fetch
                  </button> */}

                  <a
                    className="btn-style-1 text-white px-4 md:mt-8 py-2 rounded mt-2"
                    download="DonationReport.xlsx"
                    target="_blank"
                    href="http://prabalta.org:8087/usproject/exportDonations?partnerId=1"
                  >
                    Fetch
                  </a>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Report;
