import axios from "axios";

const axiosConfig = {
  headers: {
    Authorization: "YourAuthToken", // Replace 'YourAuthToken' with the actual authorization token
    "Content-Type": "application/json",
  },
};

export const setAuthorizationToken = () => {
  const token = localStorage.getItem("accessToken"); // Replace with your actual storage key

  console.log(token);

  if (token) {
    axiosConfig.headers.Authorization = `${token}`;
  } else {
    console.error("Token not found in localStorage");
  }
};


export const setPartnerAuthorizationToken = () => {
  const token = localStorage.getItem("accessTokenPartner"); // Replace with your actual storage key

  console.log(token);

  if (token) {
    axiosConfig.headers.Authorization = `${token}`;
  } else {
    console.error("Token not found in localStorage");
  }
};

export const getRequestWithTokenWithData = async (url, data = null) => {
  try {
    setAuthorizationToken();
    const response = await axios.post(url, data);
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error; // Re-throw the error so that the calling function can handle it if needed
  }
};
export const getRequestWithTokenWithDataWithAuth = async (url, data = null) => {
  try {
    setAuthorizationToken();
    const response = await axios.post(url, data, axiosConfig);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error; // Re-throw the error so that the calling function can handle it if needed
  }
};

export const getRequestWithTokenWithDataWithAuthPartner = async (url, data = null) => {
  try {
    setPartnerAuthorizationToken();
    const response = await axios.post(url, data, axiosConfig);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error; // Re-throw the error so that the calling function can handle it if needed
  }
};

export const postRequestWithoutTokenWithoutData = async (url) => {
  try {
    // setAuthorizationToken();
    const response = await axios.post(url);
    return response.data;
  } catch (error) {
    console.error("Error fetching data with post request:", error);
    throw error; // Re-throw the error so that the calling function can handle it if needed
  }
};

export const getRequestWithTokenWithoutData = async (url) => {
  try {
    setAuthorizationToken();
    const response = await axios.get(url, axiosConfig);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error; // Re-throw the error so that the calling function can handle it if needed
  }
};



export const getRequestWithTokenWithoutDataPartner = async (url) => {
  try {
    setPartnerAuthorizationToken();
    const response = await axios.get(url, axiosConfig);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error; // Re-throw the error so that the calling function can handle it if needed
  }
};

export const getRequestWithOutToken = async (url) => {
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error; // Re-throw the error so that the calling function can handle it if needed
  }
};

export const postRequestWithToken = async (url) => {
  try {
    setAuthorizationToken();
    const response = await axios.post(url, axiosConfig);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error; // Re-throw the error so that the calling function can handle it if needed
  }
};

export const patchRequestWithTokenWithData = async (url, data = null) => {
  try {
    setAuthorizationToken();
    const response = await axios.patch(url, data, axiosConfig);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error; // Re-throw the error so that the calling function can handle it if needed
  }
};

export const postMethodWithoutTokenWithData = async (url, data = null) => {
  try {
    const response = await axios.post(url, data);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const postWithTokenWithData = async (url, data = null) => {
  try {
    setAuthorizationToken();
    const response = await axios.post(url, data, axiosConfig);
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error; // Re-throw the error so that the calling function can handle it if needed
  }
};