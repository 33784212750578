import { useState, useEffect, useRef } from "react";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { ActionIcon, Box, Button } from "@mantine/core";
import { IconArrowsCross, IconCheck } from "@tabler/icons-react";
import { MRT_ColumnDef } from "mantine-react-table";
import TopHeader from "../Home/TopHeader";
import Sidebar from "../Home/Sidebar";
import {
  getRequestWithTokenWithData,
  getRequestWithTokenWithoutData,
  patchRequestWithTokenWithData,
} from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import { async } from "q";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const columns = [
  {
    accessorKey: "id",
    header: "Sr. No",
  },
  {
    accessorKey: "orgOrIndiviudalName",
    header: "Organization / Individual  Name ",
  },
  {
    accessorKey: "verifyNo",
    header: "FEIN/Social Security Number",
  },
];

const VerifyUser = () => {
  const [data, setData] = useState([]);
  const isMounted = useRef(true);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }
    getVerifyUserList();
  }, [
    pagination.pageIndex, //refetch when page index changes
    pagination.pageSize, //refetch when page size changes
  ]);

  const getVerifyUserList = async () => {
    const url = new URL(`${TQ_URL}/getUserOrOrgWaitingToVerify`);
    url.search = new URLSearchParams();
    url.searchParams.set("start", `${pagination.pageIndex}`);
    url.searchParams.set("size", `${pagination.pageSize}`);
    try {
      const response = await getRequestWithTokenWithoutData(url.toString());
      console.log(response);

      const dataWithSerialNumber = response.dataList.map((item, index) => ({
        ...item,
        id: index + 1,
      }));

      setData(dataWithSerialNumber);

      setRowCount(response.totalCount);
    } catch (error) {
      console.error("Error in fetching the Fundraiser's history:", error);
      setIsError(true);
      setIsLoading(false);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  const handleAcceptOrReject = async (row, status) => {
    console.log(row, " ", status);

    try {
      let request = {
        userId: row.original.userId,
        verifyNo: row.original.verifyNo,
        isVerified: status,
      };
      const response = await patchRequestWithTokenWithData(
        `${TQ_URL}/verifyUser`,
        request
      );
      console.log(response);
      if (response == "User Verified Successfully") {
        if (status == true) toast.success("User Accepted...");
        else toast.success("User Rejected...");

        setData((prevData) =>
          prevData.filter((dataRow) => dataRow !== row.original)
        );
      } else {
        toast.error("Something Went Wrong...");
      }
    } catch (error) {
      console.error("Error in Verifying user:", error);
    }
  };

  const table = useMantineReactTable({
    columns,
    data,
    enableRowActions: true,
    positionActionsColumn: "last",
    initialState: { showColumnFilters: false },
    rowCount,
    getRowId: (row) => row.fundraiserName,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Error loading data" }
      : undefined,
    renderRowActions: ({ row }) => (
      <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
        <ActionIcon
          color="green"
          // onClick={() => {
          //   table.setEditingRow(row);
          // }}

          onClick={() => handleAcceptOrReject(row, true)}
          title="Accept User"
        >
          <IconCheck />
        </ActionIcon>
        <ActionIcon
          color="red"
          onClick={() => handleAcceptOrReject(row, false)}
          title="Reject User"
        >
          <IconArrowsCross />
        </ActionIcon>
      </Box>
    ),
  });

  return (
    <>
      <TopHeader />
      <div className="flex">
        <div className="md:w-64 w-1/12 absolute md:relative">
          <Sidebar />
        </div>
        <main className="w-full verify-user">
          <div className=" mx-auto shadow-md">
            <div className="md:flex justify-between container items-center px-10 md:py-6 py-6 ">
              <div className="md:text-left text-center">
                <h2 className="text-2xl font-bold">Verify User</h2>
              </div>
              <div className="flex items-center space-x-4 mt-6 md:mt-0 justify-center"></div>
            </div>
          </div>
          <div className="container md:mx-auto md:py-16 py-6">
            <MantineReactTable table={table} />
          </div>
        </main>
        <ToastContainer theme="colored" />
      </div>
    </>
  );
};

export default VerifyUser;
