// Tooltip.js
import React from 'react';

const Tooltip = ({ text, children }) => {
  return (
    <div className="relative group ">
      {children}
      <div className="w-40 text-center text-xs hidden group-hover:block absolute -left-16  bg-gray-800 text-white p-2 rounded-md bottom-full mb-2">
        {text}
        <div className="w-3 h-3 bg-gray-800 absolute  left-1/2 transform -translate-x-1/2 rotate-45"></div>
      </div>
    </div>
  );
};

export default Tooltip;