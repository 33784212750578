import React from "react";
import Header from "../Home/Header";
import Footer from "../Home/Footer";
import { useState, useRef } from "react";
import { useEffect } from "react";
import ProgressBar from "../Common/ProgressBar";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { getRequestWithTokenWithoutData } from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import { isSocial } from "../../utility/baseUrl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CreateFundraiser = ({ onComplete }) => {
  const [counter, setCounter] = useState(1);
  const [value, setValue] = useState("");
  const [selectDropdown, setSelectDropdown] = useState(false);
  const [relationInput, setRelationInput] = useState(false);
  const [relationValue, setRelationValue] = useState("");
  const [otherRelation, setOtherRelation] = useState("");
  const [errorType, setErrorType] = useState("");
  const [errorRelation, setErrorRelation] = useState("");
  const [errorCauseType, setErrorCauseType] = useState("");
  const [errorOtherRelation, setErrorOtherRelation] = useState("");
  const [config, setConfig] = useState({});
  const isMounted = useRef(true);

  const [causeList, setCauseList] = useState([]);
  const [relationList, setRelationList] = useState([]);
  const [isFormFilled, setIsFormFilled] = useState(false);

  const [selectedCauseType, setSelectedCauseType] = useState([]);
  const [userType, setUserType] = useState("");
  const navigate = useNavigate();
  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setUserType(JSON.parse(localStorage.getItem("userData")));
  }, []);

  useEffect(() => {
    onComplete();
  }, [onComplete]);

  useEffect(() => {
    fetch("/config.json")
      .then((response) => response.json())
      .then((data) => {
        setConfig(data);
        console.log(data.partnerId);
      })
      .catch((error) => console.error("Error loading config.json:", error));
  }, []);

  useEffect(() => {
    if (isMounted.current && config.partnerId) {
      getCauseList();
      getRelationList();
      isMounted.current = false;
    }
    setSelectedValues([]);
  }, [config]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (counter < 10) {
        setCounter((prevCounter) => prevCounter + 1);
      }
    }, 100);

    return () => clearInterval(intervalId);
  }, [counter]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    const uniqueValues = [...new Set(value)];

    setSelectedValues(uniqueValues);

    setErrorCauseType(uniqueValues ? "" : "Please select a Cause type");

    const selectedCauseIds = uniqueValues.map((item) => item.causeId);
    setSelectedCauseType(selectedCauseIds);
    setCauseList(causeList);
  };

  const getCauseList = async () => {
    const partnerId = config.partnerId;
    console.log(partnerId);
    if (!partnerId) {
      throw new Error("Partner ID is not defined");
    }
    try {
      const response = await getRequestWithTokenWithoutData(
        `${TQ_URL}/getCauseList?partnerId=${partnerId}`
      );
      console.log(response);
      setCauseList(response.data);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        console.error("Error:", error.response.data.error);
        //  toast.error(error.response.data.error);
      } else {
        console.error("Error fetching cause list data:", error.message);
        //  toast.error("Error fetching cause list data.");
      }
    }
  };

  const getTruncatedCauses = (selected, maxLength) => {
    const selectedCause = selected.map((cause) => cause.causeName).join(", ");

    if (selectedCause.length > maxLength) {
      return selectedCause.slice(0, maxLength).trim() + "...";
    }

    return selectedCause;
  };

  const getRelationList = async () => {
    try {
      const response = await getRequestWithTokenWithoutData(
        `${TQ_URL}/getRelationList`
      );
      console.log(response);
      setRelationList(response.data);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        console.error("Error:", error.response.data.error);
        //   toast.error(error.response.data.error);
      } else {
        console.error("Error fetching relation list data:", error.message);
        toast.error("Error fetching relation list data.");
      }
    }
  };

  const getTypeValue = (event) => {
    const selectedValue = event.target.value;
    console.log("Selected value>>", selectedValue);

    setValue(selectedValue);
    setRelationValue("");
    setErrorType(selectedValue ? "" : "Please select a fundraiser type");

    if (selectedValue === "Someone Else") {
      setSelectDropdown(true);
    } else {
      setSelectDropdown(false);
      setRelationInput(false);
      setErrorRelation("");
    }
  };

  const getRelationValue = (event) => {
    const selectedRelationValue = event.target.value;
    console.log("Selected relation value>>", selectedRelationValue);
    setRelationValue(selectedRelationValue);
    setErrorRelation(selectedRelationValue ? "" : "Please select a relation");

    if (selectedRelationValue == 6) setRelationInput(true);
    else {
      setRelationInput(false);
      setErrorOtherRelation("");
    }
  };

  const handleInputChange = (event) => {
    setOtherRelation(event.target.value);
    if (event.target.value !== "") setErrorOtherRelation("");
    else setErrorOtherRelation("Please Enter Relation Type");
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    console.log(selectedCauseType);
    console.log(otherRelation);

    if (selectedCauseType.length <= 0) {
      setErrorCauseType("Please select Cause Type");
      return;
    }

    if (!isSocial) {
      console.log("inside isloclhost");
      if (!value) {
        setErrorType("Please select a type");
        return;
      }

      if (selectDropdown && !relationValue) {
        setErrorRelation("Please select a relation");
        return;
      }

      if (relationInput && !otherRelation) {
        setErrorOtherRelation("Please enter relation name");
        return;
      }

      setErrorCauseType("");
      setErrorType("");
      setErrorRelation("");
      setErrorOtherRelation("");

      setIsFormFilled(true);

      const values = {
        relationValue: relationValue,
        selectedCauses: selectedCauseType,
        otherInputValue: otherRelation,
      };

      console.log(values);
      console.log(value);

      if (value === "Someone Else") {
        navigate("/individual", { state: values });
      } else if (value === "Organization / Individual") {
        navigate("/organisation", { state: values });
      }
    } else {
      setIsFormFilled(true);

      const values = {
        relationValue: relationValue,
        selectedCauses: selectedCauseType,
        otherInputValue: otherRelation,
      };

      console.log(values);
      console.log(value);

      navigate("/organisation", { state: values });
    }
  };

  return (
    <>
      <Header />
      <ProgressBar value={30} size="sm" color="blue" />

      <h2 className="text-xl md:text-3xl font-bold mb-4 text-center uppercase md:py-10 py-5">
        Raising Fund
      </h2>

      <div className="top-fundraiser-sec md:p-8 p-4 flex flex-col md:flex-row items-center justify-start pe-6">
        <div className="2xl:w-1/2 md:w-1/2 md:pr-8 relative">
          <img
            src="assets/images/newCroppedImg.png"
            alt="Image"
            className="object-cover mb-6 md:mb-0 below-live-feed-img  w-full h-auto"
          />
          <div className="counter-overlay  bottom-20 left-16 right-0 bg-blue-500 text-white text-center p-2">
            <p className="text-4xl font-bold ">
              {counter}+{" "}
              <p className="text-xs font-semibold blue-box-text">
                Happy Clients{" "}
              </p>
            </p>
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          <h2 className="text-xl md:text-3xl font-bold mb-4">
            Who Are You Fundraising For:
          </h2>

          <div className="flex items-center my-4 w-full">
            <FormControl
              className={`bg-gray-50 border border-gray-300 text-black-900 font-semibold text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block md:mt-7 w-full p-2.5 ${
                errorCauseType && "border-red-500"
              }`}
            >
              <InputLabel
                style={{ fontWeight: "bold" }}
                className="text-black-900 font-semibold text-sm"
                id="demo-multiple-checkbox-label"
              >
                Select Causes
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectedValues}
                onChange={handleChange}
                input={<OutlinedInput label="Select Causes" />}
                // renderValue={(selected) =>
                //   selectedValues.map((x) => x.causeName).join(", ")
                // }
                renderValue={(selected) => getTruncatedCauses(selected, 80)}
                MenuProps={MenuProps}
              >
                {causeList.map((variant) => (
                  <MenuItem
                    key={variant.causeId}
                    value={variant}
                    style={{ padding: "5px", fontSize: "16px", margin: "2px" }}
                  >
                    <Checkbox
                      checked={selectedValues.includes(variant)}
                      style={{
                        fontSize: "16px",
                        margin: "5px",
                      }}
                    />
                    <ListItemText primary={variant.causeName} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {errorCauseType && <p style={{ color: "red" }}>{errorCauseType}</p>}

          <div>
            {!isSocial && (
              <>
                <div className="flex items-center my-4 w-full">
                  <select
                    onChange={getTypeValue}
                    value={value}
                    className={`bg-gray-50 border border-gray-300 text-black-900 font-semibold text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block md:mt-7 w-full custom-padding  ${
                      errorType && "border-red-500"
                    }`}
                  >
                    <option value="">Select fundraiser type</option>
                    <option value="Organization / Individual">
                      {userType.userType}
                    </option>
                    <option value="Someone Else">Someone Else</option>
                  </select>
                </div>
                {errorType && <p style={{ color: "red" }}>{errorType}</p>}

                {selectDropdown && (
                  <select
                    onChange={getRelationValue}
                    value={relationValue}
                    className={`bg-gray-50 border border-gray-300 text-black-900 font-semibold text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block mt-7 w-full custom-padding ${
                      errorRelation && "border-red-500"
                    }`}
                  >
                    <option value="">Select relation</option>
                    {relationList.map((relation, index) => (
                      <option
                        key={relation.relationId}
                        value={relation.relationId}
                      >
                        {relation.name}
                      </option>
                    ))}
                  </select>
                )}
                {errorRelation && (
                  <p style={{ color: "red" }}>{errorRelation}</p>
                )}

                {relationInput && (
                  <input
                    type="text"
                    name="otherRelation"
                    onChange={handleInputChange}
                    value={otherRelation}
                    placeholder="Enter relation type"
                    className={`bg-gray-50 border border-gray-300 text-black-900 font-semibold text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block mt-7 w-full custom-padding ${
                      errorOtherRelation && "border-red-500"
                    }`}
                  />
                )}
                {errorOtherRelation && (
                  <p style={{ color: "red" }}>{errorOtherRelation}</p>
                )}
              </>
            )}
          </div>

          <p className="text-black font-medium my-10 italic text-xs md:text-lg">
            *To receive money raised, Please make sure you have added the
            following details.
            <br />
            <p className="mt-3 ms-7">
              - Verification
              {/* <br />- Bank Details */}
            </p>
          </p>

          <div className="w-full flex md:block justify-center">
            <button
              type="submit"
              className="btn-style-1 py-2 text-white px-20 rounded mb-4 "
            >
              Next
            </button>
          </div>
        </form>
        <ToastContainer theme="colored" />
      </div>

      <Footer />
    </>
  );
};

export default CreateFundraiser;
