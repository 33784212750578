import React,{useState} from 'react';
import Header from '../Home/Header';
import Footer from '../Home/Footer';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ReCAPTCHA from "react-google-recaptcha";



const schema = yup.object().shape({
    name: yup.string().required('Name is required'),
    email: yup.string().email('Invalid email').required('Email is required'),
    phone: yup.string().required('Phone is required'),
    message: yup.string().required('Message is required'),
});

const ContactUs = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });
    const [captchaResponse, setCaptchaResponse] = useState(null);

    const handleCaptchaResponseChange = (response) => {
        setCaptchaResponse(response);
    };
    const onSubmit = (data) => {
        data.captchaResponse = captchaResponse;
        console.log('Form data:', data);
    };

    

    return (
        <>
            <Header />
            <div className='h-[400px] contact-us-banner   flex items-center justify-center '>

                <h2 className="md:text-4xl text-xl font-bold  text-white ">
                    Get in Touch With Us
                </h2>
            </div>
            <div className="container mx-auto md:flex justify-center items-center my-10">
                {/* Left side: Image */}
                <div className="flex-shrink-0 md:w-1/2">
                    <div className="md:p-8 mx-4 md:mx-0 flex flex-col  justify-start pb-20 ">
                        <h2 className="txt-style-7  font-family-poppins text-left  text-xl">Reach Us</h2>
                        <div className='flex space-y-6  flex-col '>
                            {/* Icon Box 1 */}
                            <div className='md:flex md:space-x-10 mt-6 md:mt-0'>
                                <div className="text-center icon-box">
                                    <img
                                        src="assets/images/email.png"
                                        alt="Logo"
                                        className="h-14 "
                                    />
                                    <p className="text-sm font-semibold">Mail</p>
                                </div>
                                <div className='flex flex-col justify-center'>
                                    <h1 className='text-xl font-semibold mt-2'>Lorem ipsum is a dummy Text</h1>
                                    <h1 className='text-sm font-normal'>Lorem ipsum is a dummy Text</h1>
                                </div>
                            </div>

                            {/* Icon Box 2 */}
                            <div className='md:flex md:space-x-10 mt-6 md:mt-0'>
                                <div className="text-center icon-box">
                                    <img
                                        src="assets/images/call.png"
                                        alt="Logo"
                                        className="h-14 "
                                    />
                                    <p className="text-sm font-semibold">Phone</p>
                                </div>
                                <div className='flex flex-col justify-center'>
                                    <h1 className='text-xl font-semibold'>Lorem ipsum is a dummy Text</h1>
                                    <h1 className='text-sm font-normal'>Lorem ipsum is a dummy Text</h1>
                                </div>
                            </div>

                            {/* Icon Box 3 */}
                            <div className='md:flex md:space-x-10 mt-6 md:mt-0'>
                                <div className="text-center icon-box">
                                    <img
                                        src="assets/images/location.png"
                                        alt="Logo"
                                        className="h-14 "
                                    />
                                    <p className="text-sm font-semibold">LOcation</p>
                                </div>
                                <div className='flex flex-col justify-center'>
                                    <h1 className='text-xl font-semibold'>Lorem ipsum is a dummy Text</h1>
                                    <h1 className='text-sm font-normal'>Lorem ipsum is a dummy Text</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Right side: Contact Form */}
                <div className="flex-grow md:ml-8 m-2">
                    <form onSubmit={handleSubmit(onSubmit)} className="max-w-lg mx-auto border py-20 px-10 rounded-lg  bg-white">
                        <div className="mb-4">
                            <label htmlFor="name" className="block text-sm font-bold mb-1">
                                Name
                            </label>
                            <input
                                type="text"
                                id="name"
                                {...register('name')}
                                className={`border p-2 w-full shadow-md rounded-lg ${errors.name && 'border-red-500'}`}
                            />
                            <p className="text-red-500 text-sm mt-1">{errors.name?.message}</p>
                        </div>

                        <div className="mb-4">
                            <label htmlFor="email" className="block text-sm font-bold mb-1">
                                Email
                            </label>
                            <input
                                type="text"
                                id="email"
                                {...register('email')}
                                className={`border p-2 w-full shadow-md rounded-lg ${errors.email && 'border-red-500'}`}
                            />
                            <p className="text-red-500 text-sm mt-1">{errors.email?.message}</p>
                        </div>

                        <div className="mb-4">
                            <label htmlFor="phone" className="block text-sm font-bold mb-1">
                                Phone
                            </label>
                            <input
                                type="text"
                                id="phone"
                                {...register('phone')}
                                className={`border p-2 w-full shadow-md rounded-lg ${errors.phone && 'border-red-500'}`}
                            />
                            <p className="text-red-500 text-sm mt-1">{errors.phone?.message}</p>
                        </div>

                        <div className="mb-4">
                            <label htmlFor="message" className="block text-sm font-bold mb-1">
                                Message
                            </label>
                            <textarea
                                id="message"
                                {...register('message')}
                                className={`border p-2 w-full shadow-md rounded-lg ${errors.message && 'border-red-500'}`}
                            />
                            <p className="text-red-500 text-sm mt-1">{errors.message?.message}</p>
                        </div>

                        <ReCAPTCHA
    sitekey="6LfzeE0pAAAAACjUzxnLFhCJuQPpKu5aT9dm9lTJ"
    onChange={handleCaptchaResponseChange}
/>

                        <button
                            type="submit"
                            className="bg-blue-500 text-white px-6 py-2 rounded btn-style-4"
                        >
                            Submit
                        </button>
                    </form>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ContactUs;
