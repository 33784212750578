// export const TQ_URL = "https://prabalta-ecd23a1754ce.herokuapp.com"
//export const TQ_URL = "http://localhost:8081";

//const REACT_APP_BASE_URL = "http://localhost:8080/prabalta";
export const TQ_URL = "https://prabalta.org:8087/usproject";

//export const isSocial =
  //window.location.hostname === "localhost" && window.location.port === "3000";

 export const isSocial = window.location.hostname === "social.prabalta.org";

//export const TQ_URL = "https://prabalta.org:8087/usproject";

//export const TQ_URL = "https://prabalta.org:8087/uatOnePrabalta";
console.log("API URL:", TQ_URL);

export const ACCESS_TOKEN = "accessToken";

export const OAUTH2_REDIRECT_URI =
  "https://social.prabalta.org/oauth2/redirect";

export const GOOGLE_AUTH_URL =
  TQ_URL + "/oauth2/authorize/google?redirect_uri=" + OAUTH2_REDIRECT_URI;
export const FACEBOOK_AUTH_URL =
  TQ_URL + "/oauth2/authorize/facebook?redirect_uri=" + OAUTH2_REDIRECT_URI;
export const GITHUB_AUTH_URL =
  TQ_URL + "/oauth2/authorize/github?redirect_uri=" + OAUTH2_REDIRECT_URI;
