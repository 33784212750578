import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Triangle } from "react-loader-spinner";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Slider from "react-slick";
import Header from "../Home/Header";
import ProgressBar from "../Common/ProgressBar";
import Footer from "../Home/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { DatePickerInput } from "@mantine/dates";
import { TQ_URL } from "../../utility/baseUrl";

const schema = yup.object().shape({
  username: yup
    .string()
    .min(5, "Name must be at least 5 characters")
    .max(50, "Name must be at most 50 characters")
    .required("Name is Required *"),
  description: yup.string().required("Description is Required *"),
  title: yup.string().required("Title is required"),
  address: yup.string().required("Address is Required *"),
  zipcode: yup
    .number()
    .required("ZipCode is Required *")
    .positive("ZipCode should be positive or non-zero")
    .typeError("ZipCode should not be Empty"),
  state: yup.string().required("State is Required *"),
  country: yup.string().required("Country is Required *"),
  fundraiserGoal: yup
    .number()
    .required("Fundraiser goal is required")
    .positive("Fundraiser goal should be positive or non-zero")
    .typeError("Fundraiser goal Amount should not be Empty")
    .max(12000, "Amount should not exceed $12,000"),
  targetDate: yup.date("Invalid date").required("Target date is required"),
  termsAndConditions: yup
    .boolean()
    .oneOf([true], "Please accept the terms and conditions"),
});

const Other = () => {
  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [filePreviews, setFilePreviews] = useState([]); // For storing file previews
  const [showSliderModal, setShowSliderModal] = useState(false); // For slider modal
  const [isDragOver, setIsDragOver] = useState(false);
  const [causeList, setCauseList] = useState(false);
  const [relation, setRelation] = useState("");
  const [otherRelationInput, setOtherRelationInput] = useState("");

  const [stateName, setStateName] = useState(null);
  const [notFoundMessage, setNotFoundMessage] = useState("");
  const [countryFlag, setCountryFlag] = useState(false);
  const [config, setConfig] = useState({});
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const value1 = state?.selectedCauses || "defaultValue1";
    const value2 = state?.relationValue || "";
    const value3 = state?.otherInputValue || "";

    setCauseList(value1);
    setRelation(value2);
    setOtherRelationInput(value3);

    console.log("selected causes:", value1);
    console.log("selected relation:", value2);
  }, [state]);

  useEffect(() => {
    fetch("/config.json")
      .then((response) => response.json())
      .then((data) => {
        setConfig(data);
        console.log(data.partnerId);
      })
      .catch((error) => console.error("Error loading config.json:", error));
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleFileChange = (event) => {
    const files = event.target.files;
    const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
  
    if (files.length > 0) {
      const newFiles = Array.from(files);
      const validFiles = newFiles.filter((file) => {
        if (file.size > maxSize) {
          toast.error(`File "${file.name}" is too large. Maximum size allowed is 5 MB.`);
          return false;
        }
        return true;
      });
  
      const newPreviews = validFiles.map((file) => URL.createObjectURL(file));
  
      setUploadedFiles((prevFiles) => [...prevFiles, ...validFiles]);
      setFilePreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);
  
      validFiles.forEach((file) => {
        console.log("File added:", file.name);
      });
    }
  };

  const removeFile = (index) => {
    setUploadedFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });

    setFilePreviews((prevPreviews) => {
      const newPreviews = [...prevPreviews];
      newPreviews.splice(index, 1);
      return newPreviews;
    });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);

    const files = e.dataTransfer.files;
    if (files.length > 0) {
      const newFiles = Array.from(files);
      const newPreviews = newFiles.map((file) => URL.createObjectURL(file));

      setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
      setFilePreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);

      newFiles.forEach((file) => {
        console.log("File added:", file.name);
      });
    }
  };

  const formatCurrency = (value) => {
    if (!value) return "";
    const options = {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    };
    return new Intl.NumberFormat("en-US", options).format(value);
  };

  const handleFundraiserGoalChange = (e, onChange) => {
    let value = e.target.value.replace(/\D/g, "");
    if (value === "") {
      onChange(null);
      return;
    }
    value = parseInt(value, 10);
    onChange(value);
  };

  const successFormSubmit = () => toast.success("Form Submitted Successfully!");

  const onSubmitHandler = async (data, event) => {
    console.log("Submit Clicked", data);
    const partnerId = config.partnerId;
    console.log(partnerId);
    if (!partnerId) {
      throw new Error("Partner ID is not defined");
    }
    if (uploadedFiles.length === 0) {
      toast.error("Please select document to upload..");
      return;
    }
    event.preventDefault();
    setLoading(true);
    const formData = new FormData();
    const targetDate = new Date(data.targetDate);
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const selectedDate = targetDate.toLocaleDateString("en-IN", options);

    console.log("target date:" + data.targetDate);
    console.log("selected date:" + selectedDate);

    formData.append("fundraiserName", data.username);
    formData.append("partnerId", partnerId);
    formData.append("relationId", parseInt(relation));
    formData.append("otherInput", otherRelationInput);
    formData.append("address", data.address);
    formData.append("zipcode", data.zipcode);
    formData.append("state", data.state);
    formData.append("country", data.country);
    formData.append("desc", data.description);
    formData.append("causeTypesIds", causeList);
    formData.append("resourceType", 1);
    formData.append("title", data.title);
    formData.append("currencyType", "USD");
    formData.append("goalAmt", parseFloat(data.fundraiserGoal));
    formData.append("status", 1);
    formData.append("targetDate", selectedDate);
    uploadedFiles.forEach((file) => {
      formData.append("file", file);
    });

    console.log(formData);
    const token = localStorage.getItem("accessToken");

    // Send the file and additional data using Axios
    axios
      .post(`${TQ_URL}/saveFundraiserDetails`, formData, {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        // Handle successful response
        setLoading(false);
        if (response.data.Msg == "Fundraiser Details Saved Successfully")
          successFormSubmit();

        setTimeout(() => {
          navigate(`/fundraiser/${response.data.fundraiserId}`);
        }, 1000);
      })
      .catch((error) => {
        // Handle error
        setLoading(false);
        if (error.response.data.message == "Failed to upload files..")
          toast.error("Failed to upload the image.. Please try again.");
        else toast.error("Something Went wrong");
        setTimeout(() => {}, 1000);
        console.error("Error uploading file:", error);
      });
  };

  const zipCodeDetails = async (event) => {
    const zipcode = event.target.value;
    if (zipcode < 0) {
      setValue("zipcode", "");
      return;
    }
    console.log(zipcode);

    if (!zipcode) {
      setNotFoundMessage("Zip Code is required");
      setStateName("");
      setValue("state", "");
      setValue("country", "");
      setCountryFlag(false);
      return;
    }
    if (zipcode.length === 5) {
      try {
        const response = await fetch(`${TQ_URL}/getAddressDetails`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            zipcode: zipcode,
          }),
        });

        if (response.status == 200) {
          const res = await response.json();
          if (res.Status == "Succsess") {
            setStateName(res.State.state);
            setValue("state", res.State.state);
            setValue("country", "USA");
            setNotFoundMessage("");
            setCountryFlag(true);
          } else {
            setNotFoundMessage("Incorrect Zip Code");
            setStateName("");
            setCountryFlag(false);
          }
        }
      } catch (error) {
        console.error("Error making post request:", error);
      }
    } else {
      setStateName("");
      setValue("state", "");
      setValue("country", "");
      setCountryFlag(false);
      setNotFoundMessage("Incorrect Zip Code");
    }
  };

  const openSliderModal = () => {
    setShowSliderModal(true);
  };

  const closeSliderModal = () => {
    setShowSliderModal(false);
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <Header />
      <ProgressBar value={50} size="sm" color="blue" />

      <h2 className="md:text-3xl text-xl font-bold mb-4 text-center uppercase py-10">
        Add details for Someone Else
      </h2>

      <div className="md:pb-20 md:flex justify-between">
        <div className="2xl:w-1/4 lg:w-1/3 md:me-20 mx-10 md:mx-0">
          <img
            src="assets/images/add-details-others-bg.jpg"
            className="md:w-full"
            alt="Background"
          />
        </div>

        <div className="md:w-2/3 m-4 md:m-0">
          {loading ? (
            <div className="flex flex-col items-center justify-center h-screen w-full">
              <Triangle
                height="120"
                width="120"
                color="blue"
                ariaLabel="triangle-loading"
                wrapperStyle={{}}
                visible={true}
              />
            </div>
          ) : (
            <form
              onSubmit={handleSubmit(onSubmitHandler)}
              className="fundraiser-for-other-form md:py-12 md:px-20 p-6 md:me-20"
            >
              {/* Upload Document */}
              <div className="md:flex items-center">
                <label>Upload Documents</label>
                <div
                  className="flex justify-between w-full items-center my-5 md:my-0"
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                >
                  <div
                    className="mb-6 relative flex flex-col w-full"
                    style={{
                      borderColor: isDragOver ? "blue" : "gray",
                      boxShadow: isDragOver
                        ? "0 0 10px rgba(0, 0, 255, 0.5)"
                        : "none",
                      borderRadius: "8px",
                      borderStyle: "dashed",
                      borderWidth: "2px",
                      paddingInline: "20px",
                      paddingBlock: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <div className="flex justify-between items-center">
                      {/* Display uploaded files with remove button */}
                      <div className="flex flex-col w-full">
                        {uploadedFiles.map((file, index) => (
                          <div
                            key={index}
                            className="flex justify-between items-center mb-2"
                          >
                            <p className="mr-2">{file.name}</p>
                            <button
                              type="button"
                              className="text-red-500 hover:text-red-700 cursor-pointer"
                              onClick={() => removeFile(index)}
                            >
                              &#x2715;
                            </button>
                          </div>
                        ))}
                      </div>
                      {/* Preview Button */}
                      {uploadedFiles.length > 0 && (
                        <button
                          type="button"
                          onClick={openSliderModal}
                          className="text-blue-500 underline ml-2"
                        >
                          Show Preview
                        </button>
                      )}
                    </div>

                    <label
                      htmlFor="documentInput"
                      className="cursor-pointer mt-4"
                    >
                      <img
                        src="assets/images/cloud-icon.svg"
                        className="mx-auto"
                        alt="Cloud Icon"
                      />
                      <p className="text-xs text-center">
                        <span className="font-bold underline">
                          Click to upload
                        </span>
                        <br />
                        Doc, PNG, JPG, JPEG, MP4, PDF (Max. 800 x 400, Size up
                        to 2 MB)
                      </p>
                    </label>
                    <input
                      {...register("document")}
                      type="file"
                      accept=".jpg, .jpeg, .png, .doc, .pdf, .mp4"
                      onChange={handleFileChange}
                      className="hidden"
                      id="documentInput"
                      multiple
                    />
                  </div>
                </div>
              </div>

              {/* Enter individual's name */}
              <div className="mb-6 md:flex">
                <label>Individual's name</label>
                <div className="w-full">
                  <input
                    {...register("username")}
                    placeholder="Enter individual's name"
                    type="text"
                    autoComplete="off"
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                      errors.username && "border-red-500"
                    }`}
                  />
                  <p className="text-red-500 text-sm mt-1">
                    {errors.username?.message}
                  </p>
                </div>
              </div>

              {/* Address */}
              <div className="mb-6 md:flex">
                <label>Address</label>
                <div className="w-full">
                  <input
                    {...register("address")}
                    placeholder="Address"
                    type="text"
                    autoComplete="password"
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                      errors.address && "border-red-500"
                    }`}
                  />
                  <p className="text-red-500 text-sm mt-1">
                    {errors.address?.message}
                  </p>
                </div>
              </div>

              {/* zipcode */}
              <div className="mb-6 md:flex">
                <label>Zip Code</label>
                <div className="w-full">
                  <input
                    {...register("zipcode")}
                    placeholder="zipcode"
                    autoComplete="password"
                    onBlur={zipCodeDetails}
                    maxLength={5}
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value, 10))
                        .toString()
                        .slice(0, 5);
                    }}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                      errors.zipcode && "border-red-500"
                    }`}
                  />
                  <p className="text-red-500 text-sm mt-1">
                    {errors.zipcode?.message}
                  </p>
                  {!errors.zipcode?.message && (
                    <p className="text-red-500 text-sm mt-1">
                      {notFoundMessage}
                    </p>
                  )}
                </div>
              </div>

              {/* state */}
              <div className="mb-6 md:flex">
                <label>State</label>
                <div className="w-full">
                  <input
                    {...register("state")}
                    placeholder="state"
                    type="text"
                    defaultValue={stateName}
                    readOnly
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                      errors.state && stateName == "" ? "border-red-500" : ""
                    }`}
                  />
                  <p
                    className={`text-red-500 text-sm mt-1 ${
                      stateName && "hidden"
                    }`}
                  >
                    {errors.state?.message}
                  </p>
                </div>
              </div>

              {/* country */}
              <div className="mb-6 md:flex">
                <label>Country</label>
                <div className="w-full">
                  <input
                    {...register("country")}
                    placeholder="country"
                    type="text"
                    value={countryFlag ? "USA" : ""}
                    readOnly
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                      errors.country && stateName == "" ? "border-red-500" : ""
                    }`}
                  />
                  <p
                    className={`text-red-500 text-sm mt-1 ${
                      stateName && "hidden"
                    }`}
                  >
                    {errors.country?.message}
                  </p>
                </div>
              </div>

              {/* title */}
              <div className="mb-6 relative md:flex">
                <label>Title</label>
                <div className="w-full">
                  <input
                    {...register("title")}
                    placeholder="title"
                    type="text"
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  ${
                      errors.title && "border-red-500"
                    }`}
                  />
                  <p className="text-red-500 text-sm mt-1">
                    {errors.title?.message}
                  </p>
                </div>
              </div>

              {/* Description */}
              <div className="mb-6 relative md:flex">
                <label>Description</label>
                <div className="w-full">
                  <textarea
                    {...register("description")}
                    rows={3}
                    style={{ resize: "none" }}
                    placeholder="Description"
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                      errors.description && "border-red-500"
                    }`}
                  />
                  <p className="text-red-500 text-sm mt-1">
                    {errors.description?.message}
                  </p>
                </div>
              </div>

              {/* Fundraiser Goal */}
              <div className="mb-6 relative md:flex">
                <label>Fundraiser Goal</label>
                <div className="w-full relative">
                  <Controller
                    name="fundraiserGoal"
                    control={control}
                    render={({ field }) => (
                      <>
                        <input
                          type="text"
                          placeholder="Fundraiser Goal"
                          className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                            errors.fundraiserGoal && "border-red-500"
                          }`}
                          value={field.value ? formatCurrency(field.value) : ""}
                          onChange={(e) =>
                            handleFundraiserGoalChange(e, field.onChange)
                          }
                        />
                        <span className="absolute inset-y-0 left-0 flex items-center pl-2.5 pointer-events-none text-gray-500">
                          $
                        </span>
                      </>
                    )}
                  />
                  <p className="text-red-500 text-sm mt-1">
                    {errors.fundraiserGoal?.message}
                  </p>
                </div>
              </div>

              {/* Target Date */}
              <div className="mb-6 md:flex">
                <label>Target Date</label>
                <div className="w-full">
                  <Controller
                    name="targetDate"
                    control={control}
                    render={({ field }) => (
                      <>
                        <DatePickerInput
                          placeholder="Pick date"
                          {...field}
                          required
                          className={`bg-gray-50  border-gray-300 text-gray-900 text-sm focus:border-blue-500 ${
                            errors.targetDate && "border-red-500"
                          }`}
                          onChange={(value) => {
                            const dateValue =
                              value instanceof Date
                                ? value
                                : typeof value === "string"
                                ? new Date(value)
                                : null;

                            field.onChange(dateValue);
                          }}
                          minDate={new Date()}
                        />
                      </>
                    )}
                  />
                  {errors.targetDate && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.targetDate.message}
                    </p>
                  )}
                </div>
              </div>

              {/* Terms and Conditions Checkbox */}
              <div className="mb-6 md:flex justify-between">
                <div></div>

                <div className="md:w-4/6">
                  <input
                    {...register("termsAndConditions")}
                    type="checkbox"
                    className="mr-2"
                  />
                  <label className="text-dark-200">
                    Clicking implies agreement with our{" "}
                    <a onClick={() => setShowTermsPopup(true)}>
                      {" "}
                      <u>Terms and Conditions</u>
                    </a>
                  </label>
                  <p className="text-red-500 text-sm mt-1">
                    {errors.termsAndConditions?.message}
                  </p>
                  <div className="flex w-full md:justify-between items-center justify-center">
                    {/* Submit Button */}
                    <button
                      type="submit"
                      className="w-1/3 text-white p-2 rounded-md btn-style-1 mt-4 "
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>

              {/* Terms and Conditions Popup */}
              {showTermsPopup && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                  <div className="bg-white p-6 rounded-md max-w-md w-full">
                    <h2 className="text-xl font-bold mb-4">
                      Terms and Conditions
                    </h2>
                    <p>
                      By accessing or using our services, you agree to comply
                      with all applicable laws and regulations. You also agree
                      not to use our services for any illegal or unauthorized
                      purpose, and to respect the rights of others in the
                      community.
                    </p>
                    <div className="mt-4">
                      <button
                        onClick={() => {
                          setShowTermsPopup(false);
                        }}
                        className="mt-6 p-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 cursor-pointer"
                      >
                        Accept
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </form>
          )}
        </div>
      </div>

      {/* Modal for displaying images in a slider */}
      {showSliderModal && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-md max-w-lg w-full h-auto relative">
            <button
              onClick={closeSliderModal}
              className="absolute top-2 right-2 text-red-500 text-2xl font-bold"
            >
              &times;
            </button>
            <Slider {...sliderSettings}>
              {filePreviews.map((preview, index) => (
                <div key={index} className="flex justify-center items-center">
                  <img
                    src={preview}
                    alt={`Preview ${index}`}
                    className="w-full max-h-96 object-contain"
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      )}

      <ToastContainer theme="colored" />
      <Footer />
    </>
  );
};

export default Other;
