import React, { useState, useEffect } from "react";
import TopHeader from "../Home/TopHeader";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { Link } from "react-router-dom";

import { getRequestWithOutToken } from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import axios from "axios";

const columns = [
  {
    accessorKey: "id",
    header: "ID",
  },
  {
    accessorKey: "fundraiserName",
    header: "Fundraiser Name",
  },
  {
    accessorKey: "donorName",
    header: "Donor Name",
  },
  {
    accessorKey: "transactionId",
    header: "Transaction Id",
  },
  {
    accessorKey: "donatedAmount",
    header: "Donated Amount",
  },
  {
    accessorKey: "formattedDate",
    header: "Donation Date",
  },
];

const initialData = [
  {
    orgIndividualName: "John Doe",
    cause: "Cancer",
    totalMoneyReimbursed: "$10,000",
    reimbursedDate: "2023-12-31",
  },
  {
    orgIndividualName: "Jane Smith",
    cause: "Animal Welfare",
    totalMoneyReimbursed: "$8,500",
    reimbursedDate: "2023-11-30",
  },
  {
    orgIndividualName: "Robert Johnson",
    cause: "Environmental Conservation",
    totalMoneyReimbursed: "$15,000",
    reimbursedDate: "2023-10-31",
  },
  {
    orgIndividualName: "Sarah Williams",
    cause: "Education",
    totalMoneyReimbursed: "$12,000",
    reimbursedDate: "2023-09-30",
  },
  {
    orgIndividualName: "Michael Davis",
    cause: "Healthcare",
    totalMoneyReimbursed: "$9,000",
    reimbursedDate: "2023-08-31",
  },
  {
    orgIndividualName: "Emma Wilson",
    cause: "Disaster Relief",
    totalMoneyReimbursed: "$7,500",
    reimbursedDate: "2023-07-31",
  },
  {
    orgIndividualName: "Daniel Brown",
    cause: "Poverty Alleviation",
    totalMoneyReimbursed: "$5,000",
    reimbursedDate: "2023-06-30",
  },
  // Add more data as needed
];

const AllTransactions = () => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }
    getAllTransaction();
  }, [
    pagination.pageIndex, //refetch when page index changes
    pagination.pageSize, //refetch when page size changes
  ]);

  const getAllTransaction = async () => {
    const url = new URL(`${TQ_URL}/allTransection`);
    url.search = new URLSearchParams();
    url.searchParams.set("start", `${pagination.pageIndex}`);
    url.searchParams.set("size", `${pagination.pageSize}`);
    try {
      const response = await getRequestWithOutToken(url.toString());

      console.log(response);
      const dataWithSerialNumber = response.datalist.map((item, index) => ({
        ...item,
        id: index + 1,
      }));

      setData(dataWithSerialNumber);
      //setData(response.datalist);
      setRowCount(response.totalCount);
    } catch (error) {
      console.error("Error in fetching All Trasactions:", error);
      setIsError(true);
      setIsLoading(false);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  const table = useMantineReactTable({
    columns,
    data: data,

    initialState: { showColumnFilters: false },
    rowCount,

    manualPagination: true,

    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Error loading data" }
      : undefined,
  });

  return (
    <div>
      <TopHeader />
      <div className="flex">
        <div className=""></div>
        <main className="w-full verify-user ">
          <div className="mx-auto shadow-md">
            <div className="flex justify-between container items-center px-10 py-6 mx-auto">
              <div className="text-left flex items-center">
                <Link to="/money-details">
                  <img
                    src="/assets/images/main-logo.svg"
                    alt="Logo"
                    className="h-10 px-6 my-5 sm:mx-0"
                  />
                </Link>
                <h2 className="text-2xl font-bold">
                  Checkout the list of all transactions
                </h2>
              </div>
            </div>
          </div>
          <div className="flex justify-end container mx-auto md:pt-10"></div>
          <div className="md:px-10 py-16 container mx-auto">
            <MantineReactTable table={table} />
          </div>
        </main>
      </div>
    </div>
  );
};

export default AllTransactions;
