import React, { useEffect, useState } from 'react';
import DonorManagementTop from '../DonorManagement/DonorManagementTop';
import AnalyticsSidebar from './AnalyticsSidebar';
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { Box, Button } from '@mui/material';
import { TQ_URL } from '../../utility/baseUrl';
import { getRequestWithOutToken, getRequestWithTokenWithoutDataPartner } from '../../utility/apiRequest';
import { useNavigate } from 'react-router-dom';

const FundraiserPerformance = () => {
    const navigate = useNavigate();
    const [fundraiserInfo, setFundraiserInfo] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [columnFilters, setColumnFilters] = useState([]);
    const [statusFilter, setStatusFilter] = useState(1);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    
    
    const [sorting, setSorting] = useState([]);
    
    const [value, setValue] = React.useState(0);
   
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
  

    useEffect(() => {
        if (!fundraiserInfo.length) {
            setIsLoading(true);
          } else {
            setIsRefetching(true);
          }

        getFundraiserList();
    }, [
        pagination.pageIndex, //refetch when page index changes
        pagination.pageSize, //refetch when page size changes
        // sorting, //refetch when sorting changes
        // activeTab,
        // columnFilters,
        statusFilter
    ]);

    const getFundraiserList = async () => {
        
        const url = new URL(`${TQ_URL}/getAllFundraiserDetail`);
        url.search = new URLSearchParams();
        url.searchParams.set("start", `${pagination.pageIndex}`);
        url.searchParams.set("size", `${pagination.pageSize}`);
        url.searchParams.set("status", `${statusFilter}`);

        try {
            const response = await getRequestWithOutToken(
                url.toString()
            );

            //  setDonationData(response);
            console.log(response);

            console.log(response.datalist);

            const dataWithSerialNumber = response.datalist.map((item, index) => ({
                ...item,
                id: index + 1,
            }));

            console.log("This is Data",dataWithSerialNumber);

            setFundraiserInfo(dataWithSerialNumber);
            console.log(fundraiserInfo);
            setRowCount(response.totalCount);

            setIsRefetching(false);
        } catch (error) {
            console.error("Error in fetching the fundraiser data:", error);
            setIsError(true);
            setIsLoading(false);
            console.error(error);
            return;
        }
        setIsError(false);
        setIsLoading(false);
        //  setIsRefetching(false);
    };



    const columns = [
        {
            accessorKey: "id",
            header: "Sr. no",
            sortable: true,
            
        },
        {
            accessorKey: "fundraiserName",
            header: "Fundraiser Name",
            sortable: true,
        },
        {
            accessorKey: "targetAmount",
            header: "Target amount",
            sortable: true,
        },
        {
            accessorKey: "totalMoneyRaised",
            header: "Amount generate",
            sortable: true,
        },
        {
            accessorKey: "cause",
            header: "Cause Name",
            sortable: true,
        },
        {
            accessorKey: "createdBy",
            header: "Created by",
            sortable: true,
        },
        {
            accessorKey: "createdDate",
            header: "Created date",
            sortable: true,
        },
        {
            accessorKey: "targetDate",
            header: "Target date",
            sortable: true,
        },

    ];

    const table = useMantineReactTable({
        columns,
        data: fundraiserInfo,

        initialState: { showColumnFilters: false },
        rowCount,
        manualFiltering: true,
        manualPagination: true,
        manualSorting: true,

        onColumnFiltersChange: setColumnFilters,

        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        mantineTableBodyRowProps: ({ row }) => ({
            onClick: () => {
              const rowData = row.original;
              console.log(rowData.fundraiserId);
              const fid = rowData.fundraiserId
              navigate("/fundraiserPerformanceDetails", { state: { fid } });
            },
            sx: { cursor: "pointer" },
          }),

        state: {
            columnFilters,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
          },

       
    });

    const handleStatusFilterChange = (status) => {
        setStatusFilter(status);
        setIsDropdownOpen(false); // Close the dropdown when an option is selected
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <>
            <DonorManagementTop />
            <div className="flex">
                <div className="md:w-64 w-1/12 absolute md:relative">
                    <AnalyticsSidebar />
                </div>

                <main className="w-full verify-user overflow-x-hidden">
                    <div className=" mx-auto shadow-md">
                        <div className="md:flex justify-between container items-center px-10 md:py-6 py-6">
                            <div className="text-center">
                                <h2 className="text-2xl font-bold">Analytics and Reports
                                </h2>
                                <div className="relative">
                                <div className="flex items-center mt-5">
                                    <span className="mr-2">Status:</span>
                                    <div className="relative ">
                                        <button className="border border-gray-300 bg-white rounded-md py-1 px-3 text-sm shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-200" type="button" onClick={toggleDropdown}>
                                            {statusFilter || "Active " } <span className='text-stone-950'> <b> V </b></span> 
                                        </button>
                                        {isDropdownOpen && (
                                            <ul className="absolute right-0 mt-2 w-36 bg-white border border-gray-300 rounded-md shadow-md divide-y divide-gray-200 z-10">
                                                <li>
                                                    <button className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 w-full text-left focus:outline-none" onClick={() => handleStatusFilterChange(1)}>
                                                        Active 
                                                    </button>
                                                </li>
                                                <li>
                                                    <button className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 w-full text-left focus:outline-none" onClick={() => handleStatusFilterChange(5)}>
                                                        Complete
                                                    </button>
                                                </li>
                                            </ul>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container px-10 py-5">
                        <MantineReactTable table={table} />
                    </div>
                    </div>
                </main>
                
                
            </div>
        </>
    );
}

export default FundraiserPerformance;
