import React, { useEffect, useState } from 'react'
import DonorManagementTop from '../DonorManagement/DonorManagementTop'
import AnalyticsSidebar from './AnalyticsSidebar'
import { PieChart } from "@mui/x-charts/PieChart";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { LineChart } from '@mui/x-charts';
import { useLocation } from 'react-router-dom';
import { TQ_URL } from '../../utility/baseUrl';
import { getRequestWithOutToken } from '../../utility/apiRequest';

const FundraiserPerformanceDetails = () => {
    const [fundraiserInfo, setFundraiserInfo] = useState();
    const [donorList, setDonorLIst] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [columnFilters, setColumnFilters] = useState([]);
    const [statusFilter, setStatusFilter] = useState(1);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);


    const [sorting, setSorting] = useState([]);

    const [value, setValue] = React.useState(0);

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const { state } = useLocation();
  
  const fundraiserId = state?.fid;

  localStorage.setItem("fid",fundraiserId)

    const data = [
        { receiptNo: "001", name: "John Doe", date: "2023-04-12", paymentMethod: "Credit Card", amount: "$100" },
        { receiptNo: "002", name: "Jane Smith", date: "2023-04-15", paymentMethod: "PayPal", amount: "$75" },
        // Add more dummy data as needed
    ];

    const columns = [
        {
            accessorKey: "receiptNo",
            header: "Recipt No",
            sortable: true,

        },
        {
            accessorKey: "name",
            header: "Name",
            sortable: true,
        },
        {
            accessorKey: "date",
            header: "Date",
            sortable: true,
        },
        {
            accessorKey: "paymentMethod",
            header: "Payment",
            sortable: true,
        },

        {
            accessorKey: "amount",
            header: "Amount",
            sortable: true,
        },

    ];

    const table = useMantineReactTable({
        columns,
        data: data,

        initialState: { showColumnFilters: false },
        // rowCount,
        // manualFiltering: true,
        // manualPagination: true,
        // manualSorting: true,

        // onColumnFiltersChange: setColumnFilters,

        // onPaginationChange: setPagination,
        // onSortingChange: setSorting,
        // mantineTableBodyRowProps: ({ row }) => ({
        //     onClick: () => {
        //       const rowData = row.original;

        //       console.log(rowData);

        //     },
        //     sx: { cursor: "pointer" },
        //   }),

        // state: {
        //     columnFilters,
        //     isLoading,
        //     pagination,
        //     showAlertBanner: isError,
        //     showProgressBars: isRefetching,
        //     sorting,
        //   },


    });


    ///chart

    const xLabels = [
        1, 2, 3, 4, 5, 6, 7
    ];

    useEffect(() => {
        // if (!fundraiserInfo.length) {
        //     setIsLoading(true);
        // } else {
        //     setIsRefetching(true);
        // }
        console.log("This is fundraiser", fundraiserId);

        getFundraiserList();
    }, [
        pagination.pageIndex, //refetch when page index changes
        pagination.pageSize, //refetch when page size changes
        // sorting, //refetch when sorting changes
        // activeTab,
        // columnFilters,
        statusFilter
    ]);

    const getFundraiserList = async () => {

        const fid = localStorage.getItem("fid");
        console.log("Local fid",fid);
        const url = new URL(`${TQ_URL}/getFundraiserDonoationList`);
        url.search = new URLSearchParams();
        url.searchParams.set("start", `${pagination.pageIndex}`);
        url.searchParams.set("size", `${pagination.pageSize}`);
        url.searchParams.set("fundraiserId", `${fid}`);

        try {
            const response = await getRequestWithOutToken(
                url.toString()
            );

            //  setDonationData(response);
            console.log(response);

            console.log(response.fundraiserDetail);
           
            // const dataWithSerialNumber = response.datalist.map((item, index) => ({
            //     ...item,
            //     id: index + 1,
            // }));

            // console.log("This is Data", dataWithSerialNumber);

            setFundraiserInfo(response.fundraiserDetail);
            setDonorLIst(response.DonorList);
            console.log(fundraiserInfo.title);
            // setRowCount(response.totalCount);

            setIsRefetching(false);
        } catch (error) {
            console.error("Error in fetching the fundraiser data:", error);
            setIsError(true);
            setIsLoading(false);
            console.error(error);
            return;
        }
        setIsError(false);
        setIsLoading(false);
        //  setIsRefetching(false);
    };

    return (
        <>
            <DonorManagementTop />
            <div className="flex">
                <div className="md:w-64 w-1/12 absolute md:relative">
                    <AnalyticsSidebar />
                </div>

                <main className="w-full verify-user overflow-x-hidden mb-10">
                    <div className=" mx-auto shadow-md">
                        <div className="md:flex justify-between container items-center px-10 md:py-6 py-6">
                            <div className="text-center">
                                <h2 className="text-2xl font-bold">Analytics and Reports
                                </h2>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="md:flex justify-between container items-center px-10 md:py-6 py-6">
                            <div className="text-center">
                                <h4 className="text-xl font-bold">{fundraiserInfo?.title}
                                </h4>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-12 gap-4 md:h-60 donor-info">
                            <div className="col-span-12 md:col-span-7 content-center">
                                <div className="flex flex-col ">
                                    <div className="flex flex-col md:flex-row justify-between px-10">
                                        <div className="counter-item">
                                            <p className="font-extrabold">Name:</p>
                                            <p>{fundraiserInfo?.fundraiserName}</p>
                                        </div>
                                        <div className="counter-item">
                                            <p className="font-extrabold">Created Date:</p>
                                            <p>{fundraiserInfo?.startDate}</p>
                                        </div>

                                    </div>
                                    <div className="flex flex-col md:flex-row justify-between px-10 mt-5">
                                        <div className="counter-item">
                                            <p className="font-extrabold">Target Date:</p>
                                            <p>{fundraiserInfo?.startDate}</p>
                                        </div>
                                        <div className="counter-item">
                                            <p className="font-extrabold">Target Amount:</p>
                                            <p>{fundraiserInfo?.goalAmt}</p>
                                        </div>
                                        <div className="counter-item">
                                            <p className="font-extrabold">Generated Amount:</p>
                                            <p>{fundraiserInfo?.amtAchieved}</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="col-span-12 md:col-span-5 content-center">
                                <PieChart
                                    colors={["#E1A2C6", "#C6B4F0"]}
                                    series={[
                                        {
                                            data: [
                                                {
                                                    id: 0,
                                                    value: fundraiserInfo?.goalAmt,
                                                    label: "Targeted Amount ",
                                                },
                                                {
                                                    id: 1,
                                                    value: fundraiserInfo?.amtAchieved,
                                                    label: "Achieved Amount",
                                                },
                                            ],
                                            innerRadius: 20,
                                            outerRadius: 100,
                                            paddingAngle: 2,
                                            cx: 150,
                                            cy: 100,
                                        },
                                    ]}
                                />
                            </div>
                        </div>





                    </div>
                    <div className="container px-10 mt-5">
                        <MantineReactTable table={table} />
                    </div>
                    <div className="container px-10 mt-5">
                        <LineChart
                            xAxis={[{ scaleType: 'point', data: xLabels, label: "Days" }]}
                            series={[
                                {
                                    data: [2, 5.5, 2, 8.5, 1.5, 5],
                                },
                            ]}
                            yAxis={[{
                                label: "Money",
                            }]}

                            height={350}
                            margin={{ left: 50, right: 30, top: 30, bottom: 80 }}
                            grid={{ vertical: true, horizontal: true }}

                        />
                    </div>
                    <div className="container ">
                        <div className="md:flex justify-between container items-center px-10 md:py-6 py-6">
                            <div className="text-center">
                                <h4 className="text-xl font-bold">Social Media Performance

                                </h4>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-9 gap-4">
                            <div className="col-span-12 md:col-span-3  sm:col-span-4">
                                <div className="flex flex-col  justify-between px-10 ">

                                    <div className="max-w-xs  webkit-centerForAnalytics border-4 mx-5">
                                        <div className="text-left">
                                            <h4 className="text-sm font-bold p-5">Post Title : 10000 reach in day

                                            </h4>
                                        </div>
                                        <img className="rounded-t-lg border-4 h-32  mx-5" src="assets/images/cdonation.jpg" alt="" />
                                        <div className="p-3 pt-5">


                                            <p className=" text-lg text-left"> Reach: 1000k</p>
                                            <p className=" text-lg text-left"> eng. rate:10%</p>
                                            <p className=" text-lg text-left"> total likes: 200</p>
                                            <p className=" text-lg text-left">  One day ago</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-12 md:col-span-3  sm:col-span-4">
                                <div className="flex flex-col  justify-between px-10 ">

                                    <div className="max-w-xs  webkit-centerForAnalytics border-4 mx-5">
                                        <div className="text-left">
                                            <h4 className="text-sm font-bold p-5">Post Title : 10000 reach in day

                                            </h4>
                                        </div>
                                        <img className="rounded-t-lg border-4 h-32  mx-5" src="assets/images/cdonation.jpg" alt="" />
                                        <div className="p-3 pt-5">


                                            <p className=" text-lg text-left"> Reach: 1000k</p>
                                            <p className=" text-lg text-left"> eng. rate:10%</p>
                                            <p className=" text-lg text-left"> total likes: 200</p>
                                            <p className=" text-lg text-left">  One day ago</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-12 md:col-span-3  sm:col-span-4">
                                <div className="flex flex-col  justify-between px-10 ">

                                    <div className="max-w-xs  webkit-centerForAnalytics border-4 mx-5">
                                        <div className="text-left">
                                            <h4 className="text-sm font-bold p-5">Post Title : 10000 reach in day

                                            </h4>
                                        </div>
                                        <img className="rounded-t-lg border-4 h-32  mx-5" src="assets/images/cdonation.jpg" alt="" />
                                        <div className="p-3 pt-5">


                                            <p className=" text-lg text-left"> Reach: 1000k</p>
                                            <p className=" text-lg text-left"> eng. rate:10%</p>
                                            <p className=" text-lg text-left"> total likes: 200</p>
                                            <p className=" text-lg text-left">  One day ago</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default FundraiserPerformanceDetails