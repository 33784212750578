import React, { useState } from "react";
import { getRequestWithTokenWithData } from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";

const schema = yup.object().shape({
  fundName: yup.string().required("Fund Name is Required"),
});

const CreateFund = ({ onClose }) => {
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const partnerData = JSON.parse(localStorage.getItem("partnerData"));
  const partnerId = partnerData.partnerId; // Fetching partnerId from localStorage

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = async (data) => {
    setLoading(true);
    try {
      const response = await getRequestWithTokenWithData(
        `${TQ_URL}/addFundForImported?partnerId=${partnerId}&fundName=${data.fundName}`
      );
      if (response.success) {
        toast.success("Fund added successfully!");
        reset();
        onClose(); // Close the modal on successful save
      } else {
        toast.error("Failed to add fund.");
      }
    } catch (error) {
      console.error("Error adding fund:", error);
      toast.error("An error occurred while adding fund.");
    }
    setLoading(false);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center overflow-y-scroll z-50 px-4">
      <div className="fade-in-bottom rounded-xl w-[500px]">
        <div className="bg-white rounded-t-xl max-w-2xl w-full flex justify-between items-center">
          <h2 className="text-xl font-bold mb-4 text-start pt-4 px-10">Add Fund</h2>
          <button onClick={onClose} className="px-2 cursor-pointer">
            {/* SVG code */}
          </button>
        </div>
        <div className="p-6 bg-blue-50 rounded-b-xl">
          {successMessage && <div className="mb-4 text-green-600">{successMessage}</div>}
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <div className="mb-6">
              <input
                {...register("fundName")}
                placeholder="Enter Fund Name"
                type="text"
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                  errors.fundName && "border-red-500"
                }`}
              />
              <p className="text-red-500 text-sm mt-1">{errors.fundName?.message}</p>
            </div>
            <div className="flex justify-between">
              <button
                type="submit"
                className="w-full text-white p-2 rounded-md mb-6 btn-style-1 mr-2"
                disabled={loading}
              >
                {loading ? "Adding Fund..." : "Add Fund"}
              </button>
              <button
                type="button"
                onClick={onClose}
                className="w-full text-white p-2 rounded-md mb-6 btn-style-1 bg-gray-400 hover:bg-gray-500"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CreateFund;
