import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  getRequestWithTokenWithData,
  getRequestWithTokenWithoutData,
} from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const VerifyUsers = () => {
  const [securityNo, setsecurityNo] = useState("");
  const { state } = useLocation();
  const navigate = useNavigate();

  const userIdFromAddress = state?.userId || "0";

  const handleInputChange = (e) => {
    setsecurityNo(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const buttonClicked = e.nativeEvent.submitter.name;
    console.log(buttonClicked);

    const redirectValue = localStorage.getItem("redirectFrom");
    console.log(redirectValue);

    if (buttonClicked === "submit") {
      // Now you can use the 'peinNo' state variable to send to the backend
      console.log("Submitting peinNo:", securityNo);
      const localStorageData = localStorage.getItem("userData");
      let userId;
      if (localStorageData) {
        const userDataFromLocalStorage = JSON.parse(localStorageData);

        console.log(userDataFromLocalStorage.userId);
        userId = userDataFromLocalStorage.userId;
      } else {
        userId = userIdFromAddress;
        console.log(userId);
      }

      console.log(userId);

      e.preventDefault();
      const response = await fetch(`${TQ_URL}/addVerifyUserDetails`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          verifyNo: securityNo,
          userId: parseInt(userId),
        }),
      });

      const res = await response.json();

      console.log("BODY >>", res);
      if (response.status === 200) {
        // if (redirectValue == "profile") {
        //   infoSaved(res.message);
        //   navigate("/userprofile");
        // } else {
        //   infoSaved(res.message);
        //   navigate("/success");
        navigate("/verifysuccess");
        // }
      } else if (
        response.status === 400 &&
        res.message &&
        res.message.length > 0
      ) {
        let errorMsg = "";
        res.message.forEach((msg) => {
          errorMsg = errorMsg + "  " + msg;
        });

        console.log(errorMsg);

        toast.error(errorMsg + "\n", { position: "top-right" });
      } else {
        infoSaveFailed();
      }
    } else if (buttonClicked === "skip") {
      if (redirectValue == "profile") navigate("/userprofile");
      else navigate("/success");
    }
  };

  const infoSaved = (msg) => {
    toast.success(msg, {
      position: "top-right",
    });

    // setTimeout(() => {
    //   navigate("/success");
    // }, 2000);
  };
  const infoSaveFailed = () => {
    toast.error("Failed to saved the details", { position: "top-right" });
  };

  return (
    <div className="flex flex-col md:flex-row min-h-screen verify-addr">
      {/* Left Section */}
      <div className="md:flex-1 bg-cover bg-center relative fade-in-left verify-addr-bg">
        <div className="absolute inset-0 bg-black opacity-40"></div>
        <div className="absolute inset-0 flex flex-col items-start justify-start md:px-20 px-5 mt-12">
          <div className="text-center md:text-left">
            <Link to="/">
              <img
                src="assets/images/main-logo.svg"
                alt="Logo"
                className="h-10 mx-auto sm:mx-0"
              />
            </Link>
          </div>
          <div className="w-full flex flex-col justify-center  h-2/3">
            <h1 className="txt-style-3 text-center !text-white uppercase">
              Verify User
            </h1>

            <form className="verify-addr-form " onSubmit={handleSubmit}>
              <div className="mb-6">
                <input
                  type="text"
                  id="peinNo"
                  value={securityNo}
                  onChange={handleInputChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Enter FEIN/SSN no.*"
                  required
                />
              </div>
              {/* Log In Button */}
              <div className="flex  justify-center mt-10">
                <div>
                  {/* <Link to="/success"> */}
                  <button
                    type="submit"
                    name="submit"
                    className=" text-white px-10 py-2 rounded-sm  btn-style-1 ms-5"
                  >
                    Submit
                  </button>
                  {/* </Link> */}
                </div>
                <div>
                  <button
                    type="submit"
                    name="skip"
                    className=" text-white px-10 py-2 rounded-sm btn-style-1 ms-5"
                    onClick={() => {
                      // Remove the required attribute when the "Skip" button is clicked
                      document
                        .getElementById("peinNo")
                        .removeAttribute("required");
                    }}
                  >
                    Skip
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer theme="colored" />
    </div>
  );
};

export default VerifyUsers;
