import React, { useEffect } from "react";
import Header from "../Home/Header";
import Footer from "../Home/Footer";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from "@material-tailwind/react";

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
      />
    </svg>
  );
}

const FAQData = [
  // Your FAQ data
];

const HowWeWork = () => {
  const [open, setOpen] = React.useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    // Scroll to top when the component is mounted
    window.scrollTo(0, 0);
  }, []);

  const handleOpen = (value) => setOpen(open === value ? 0 : value);

  const handleCreateFundraiserClick = () => {
    if (localStorage.getItem("accessToken") !== null) {
      navigate("/createfundraiser");
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      <Header />

      <h2 className="md:text-3xl text-xl font-extrabold mb-4 flex items-center justify-center h-full ">
        How We Work ?
      </h2>

      <div className="how-we-work-banner-section text-white 2xl:px-20 md:px-10 px-4 flex justify-between w-full flex-col md:flex-row">
        <div className="flex items-center fade-in-left">
          <div>
            <h1 className="banner-text-2 md:w-7/12 md:leading-[5rem] leading-[2.8rem]">
              Need Funds To{" "}
              <span className="yellow-texture-bg ">Achieve Your Goals? </span>
            </h1>
            <p className="banner-text-1 font-semibold font-family-poppins mt-5 ">
              Don't worry you've come to the right platform.
            </p>
            {/* Donate Now Button */}

            <button
              onClick={handleCreateFundraiserClick}
              className="btn-style-1 text-white text-md py-2 px-6 rounded !font-semibold my-4 after-arrow"
            >
              Start a Free Fundraiser
            </button>
          </div>
        </div>
      </div>

      <div className="bg-gray-100">
        <div className="md:flex md:py-2 container mx-auto mt-[30px]">
          <div className="md:w-1/2 2xl:w-6/12 mx-2 mb-2 fade-in-left">
            <img
              src="assets/images/donorMgmt.png"
              className="w-full md:w-auto md:h-[400px]"
              alt="Vector"
            />
          </div>
          <div className="md:w-1/2 2xl:w-6/12 flex flex-col items-start justify-center md:text-left mx-4 md:mx-2 fade-in-right">
            <div>
              <h2 className="md:text-2xl text-md font-extrabold md:mb-4 mb-2">
                If You are Donor?
              </h2>
              <ul className="flex flex-col list-disc pl-6">
                <li className="md:mb-4 mb-4 md:text-lg text-sm md:font-bold">
                  Browse or search fundraiser, Click on desired fundraiser.
                </li>
                <li className="md:mb-4 mb-4 md:text-lg text-sm md:font-bold">
                  Input transaction details for donation.
                </li>
                <li className="md:mb-4 mb-4 md:text-lg text-sm md:font-bold">
                  Provide payment information like credit card information.
                </li>
                <li className="md:mb-4 mb-4 md:text-lg text-sm md:font-bold">
                  Receive an email confirmation for successful donation.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}

      {/* <div className="box-content h-auto rounded-t-2xl p-4 border-4 how-we-work-modal-section  text-black flex  mb-12 flex-col md:flex-row fade-in-right shadow-md md:w-[70rem]"> */}
      <div className="">
        <div className="py-10 md:py-2  md:flex justify-between overflow-hidden mt-[30px] ">
          <div className="md:w-1/2 2xl:w-6/12 flex flex-col  items-start justify-center md:text-left mx-4 md:mx-2 md:ml-[7.5rem] fade-in-right">
            <div>
              <h2 className="md:text-3xl text-md font-extrabold md:mb-4 mb-2">
                Want to create new Fundraiser ?
              </h2>
              <ul className="flex flex-col list-disc pl-6">
                <li className="md:mb-4 mb-4 md:text-lg text-sm md:font-bold">
                  Login to the Our website(Required)
                </li>
                <li className="md:mb-4 mb-4 md:text-lg text-sm md:font-bold">
                  Choose fundraiser type or relation (if it is raised for
                  someone else)
                </li>
                <li className="md:mb-4 mb-4 md:text-lg text-sm md:font-bold">
                  Provide the necessary document and personal details.
                </li>
                <li className="md:mb-4 mb-4 md:text-lg text-sm md:font-bold">
                  Receive an email confirmation for successful fundraiser
                  creation.
                </li>
              </ul>
            </div>
          </div>

          {/* <div className="md:w-1/2 2xl:w-6/12 me-[-150px] fade-in-left">
            <img src="assets/images/hww-vector-2.png" className="w-full" />
          </div> */}

          <div className="md:w-1/2 2xl:w-6/12 me-[-150px]">
            <img src="assets/images/fundraiserHWW.png" className="w-full" />
          </div>
        </div>
      </div>
      {/* </div> */}

      {/* <div className="box-content h-auto rounded-t-2xl p-4 border-4  text-black flex  mb-12 flex-col md:flex-row fade-in-right shadow-md md:w-[70rem]"> */}
      <div className="bg-gray-100">
        <div className="md:flex md:py-4 container mx-auto mt-[30px] ">
          <div className="md:w-1/2 2xl:w-6/12  mx-4 mb-4 fade-in-left">
            <img
              src="assets/images/donorMgmt.png"
              className="w-full md:w-auto"
            />
          </div>
          <div className="md:w-1/2 2xl:w-6/12 flex flex-col items-start justify-center md:text-left mx-4 md:mx-2 fade-in-right">
            <div>
              <h2 className="md:text-2xl text-md font-extrabold md:mb-4 mb-2">
                If You are Recurring Donor ?
              </h2>
              <ul className="flex flex-col list-disc pl-6">
                <li className="md:mb-4 mb-4 md:text-xl text-sm md:font-bold">
                  Login to the Our website( Required)
                </li>

                <li className="md:mb-4 mb-4 md:text-xl text-sm md:font-bold">
                  Choose freqency and fill the amount and other necessary
                  Information.
                </li>

                <li className="md:mb-4 mb-4 md:text-xl text-sm md:font-bold">
                  Receive an email confirmation for successful subscription.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* </div> */}
      {/* </div>
      </div> */}
      <Footer />
    </>
  );
};

export default HowWeWork;
