import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Triangle } from "react-loader-spinner"; // Import Triangle loader
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Header from "../Home/Header";
import ProgressBar from "../Common/ProgressBar";
import Footer from "../Home/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { DatePickerInput } from "@mantine/dates";
import { TQ_URL } from "../../utility/baseUrl";
import DonorManagementTop from "./DonorManagementTop";

import DonorManagementSideBar from "./DonorManagementSideBar";
import { Select, TextInput, Button } from "@mantine/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TopHeader from "../Home/TopHeader";

const schema = yup.object().shape({
  donor: yup.string().required("Donor is required"),
  donationDate: yup.date().required("Donation date is required"),
  donationAmount: yup
    .number()
    .required("Donation amount is required")
    .positive("Donation amount should be positive or non-zero")
    .typeError("Donation amount should be a number"),
  fundraiserId: yup.string().required("Fundraiser is required"),
});

const AddDonation = () => {
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [fundraiserOptions, setFundraiserOptions] = useState([]);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      fundraiserId: "",
    },
  });

  const partnerData = JSON.parse(localStorage.getItem("partnerData"));
  const token = localStorage.getItem("accessTokenPartner");
  useEffect(() => {
    // Fetch fundraiser data from API
    const fetchFundraiserData = async () => {
      try {
        const response = await axios.get(
          `https://prabalta.org:8087/usproject/getAllPartnerFundraiser?partnerId=${partnerData.partnerId}`,
          {
            headers: {
              Authorization: token, // Pass the authorization token
              "Content-Type": "application/json", // Specify the content type
            },
          }
        );

        const { data } = response;
        const fundList = data && data.fundList ? data.fundList : [];

        if (fundList != "Fundraiser List is Empty")
          setFundraiserOptions(fundList);
        else setFundraiserOptions([]);
      } catch (error) {
        console.error("Error fetching fundraiser data:", error);
      }
    };

    const fetchUserList = async () => {
      try {
        const response = await axios.get(
          `https://prabalta.org:8087/usproject/getAllDonorForDropdown?partnerId=${partnerData.partnerId}`,
          {
            headers: {
              Authorization: token, // Pass the authorization token
              "Content-Type": "application/json", // Specify the content type
            },
          }
        );

        // Extract the fundraisersList array from the response data
        const { data } = response;
        const userList = data && data.userList ? data.userList : [];

        console.log(userList);

        if (userList != "NO User Exsist") setUsers(userList);
        else setUsers([]);
      } catch (error) {
        console.error("Error fetching Donor data:", error);
      }
    };

    fetchUserList();
    fetchFundraiserData();
  }, []); // Empty dependency array ensures this effect runs only once on component mount

  const successFormSubmit = () => toast.success("Form Submitted Successfully!");

  const selectedFundraiserId = watch("fundraiserId");

  useEffect(() => {
    const fetchInitialData = async () => {
      console.log(localStorage.getItem("fundraiserId"));
      const fundraiserId = parseInt(localStorage.getItem("fundraiserId"));

      if (fundraiserId) {
        setValue("fundraiserId", fundraiserId);
      }
    };

    fetchInitialData();
  }, [setValue]);

  const onSubmitHandler = async (data) => {
    console.log(data);

    setLoading(true);
    try {
      const response = await axios.post(
        `${TQ_URL}/saveDonationMannualy`,
        // data,
        {
          donor: data.donor,
          donationDate: data.donationDate,
          donationAmount: data.donationAmount,
          fundraiserId: data.fundraiserId,
          partnerId: partnerData.partnerId,
        },
        {
          headers: {
            Authorization: token, // Pass the authorization token
            "Content-Type": "application/json", // Specify the content type
          },
        }
      );
      if (response.status === 200) {
        successFormSubmit();

        // if (localStorage.getItem("fundraiserId")) {
        //   setTimeout(() => {
        //     navigate(`/fundraiserInfo`);
        //   }, 1000);
        // }

        localStorage.removeItem("fundraiserId");
      } else {
        toast.error("Error saving donation. Please try again later.");
      }
    } catch (error) {
      toast.error("Error saving donation. Please try again later.");
    }
    setLoading(false);
  };

  const handleAddFund = () => {};

  return (
    <>
      {/* <TopHeader /> */}
      <DonorManagementTop />
      <div className="flex">
        <div className="md:w-64 w-1/12 absolute md:relative">
          <DonorManagementSideBar />
        </div>

        <main className="w-full verify-user">
          <div className=" mx-auto shadow-md">
            <div className="md:flex justify-between container items-center px-10 md:py-6 py-6 ">
              <div className="text-center">
                <h2 className="text-2xl font-bold">Add Donation</h2>
              </div>
            </div>
          </div>

          <div className="md:pb-20 md:flex justify-between">
            <div className="md:w-full m-4 md:m-0">
              {loading ? (
                 <div className="flex items-center justify-center h-[200px] w-full md:pr-20">
                 <Triangle
                   height="80"
                   width="80"
                   color="#4fa94d"
                   ariaLabel="triangle-loading"
                   wrapperStyle={{}}
                   visible={true}
                 />
               </div>
              ) : (
                <form
                  onSubmit={handleSubmit(onSubmitHandler)}
                  className="fundraiser-for-other-form md:py-12 md:px-20 p-6 md:me-20 md:ml-[100px] md:mt-10"
                >
                  {/* Donor Dropdown */}
                  <div className="mb-6 md:flex">
                    <label>Donor</label>

                    {users.length > 0 ? (
                      <div className="w-full">
                        {/* Replace this dropdown with your actual donor selection dropdown */}
                        <select
                          {...register("donor")}
                          className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                            errors.donor && "border-red-500"
                          }`}
                        >
                          <option value="">Select donor...</option>
                          {users.map((currentUser) => (
                            <option
                              key={currentUser.importedDonorId}
                              value={currentUser.importedDonorId}
                            >
                              {currentUser.donorName}
                            </option>
                          ))}
                        </select>
                      </div>
                    ) : (
                      <div className="w-full flex items-center justify-between">
                        <span>No users available.</span>
                        <Link to={`/addDonorManually`}>
                          <button
                            className="btn-style-1 text-white py-1 px-1 md:py-1  md:px-2 rounded  md:block mx-2"
                            // onClick={() => handleAddFund()}
                          >
                            Add Donor
                          </button>
                        </Link>
                      </div>
                    )}

                    <p className="text-red-500 text-sm mt-1">
                      {errors.donor?.message}
                    </p>
                  </div>

                  {/* Donation Date */}
                  <div className="mb-6 md:flex">
                    <label>Donation Date</label>
                    <div className="w-full">
                      <Controller
                        name="donationDate"
                        control={control}
                        render={({ field }) => (
                          <DatePickerInput
                            placeholder="Pick date"
                            {...field}
                            required
                            className={`bg-gray-50  border-gray-300 text-gray-900 text-sm focus:border-blue-500 ${
                              errors.donationDate && "border-red-500"
                            }`}
                            onChange={(value) => {
                              // Parse the string into a Date object if it's not already a Date
                              const dateValue =
                                value instanceof Date
                                  ? value
                                  : typeof value === "string"
                                  ? new Date(value)
                                  : null;

                              field.onChange(dateValue);
                            }}
                            minDate={new Date(0)} // Set minDate to the beginning of time
                            maxDate={null} // Set maxDate to null to allow all past dates
                          />
                        )}
                      />
                      {errors.donationDate && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.donationDate.message}
                        </p>
                      )}
                    </div>
                  </div>

                  {/* Donation Amount */}
                  <div className="mb-6 relative md:flex">
                    <label>Donation Amount</label>
                    <div className="w-full">
                      <input
                        {...register("donationAmount")}
                        placeholder="Donation Amount"
                        type="number"
                        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                          errors.donationAmount && "border-red-500"
                        }`}
                      />
                      <p className="text-red-500 text-sm mt-1">
                        {errors.donationAmount?.message}
                      </p>
                    </div>
                  </div>

                  {/* Fundraiser Dropdown */}
                  <div className="mb-6 md:flex">
                    <label>Fundraiser</label>

                    {fundraiserOptions.length > 0 ? (
                      <div className="w-full">
                        <select
                          {...register("fundraiserId")}
                          value={selectedFundraiserId}
                          onChange={(e) =>
                            setValue("fundraiserId", e.target.value)
                          }
                          className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                            errors.fundraiserId && "border-red-500"
                          }`}
                        >
                          <option value="">Select fundraiser...</option>
                          {fundraiserOptions.map((fundraiser) => (
                            <option
                              key={fundraiser.fundId}
                              value={fundraiser.fundId}
                            >
                              {fundraiser.fundName}
                            </option>
                          ))}
                        </select>
                      </div>
                    ) : (
                      <div className="w-full flex items-center justify-between">
                        <span>No Fundraisers available.</span>
                        <button
                          className="btn-style-1 text-white py-1 px-1 md:py-1  md:px-2 rounded  md:block mx-2"
                          onClick={() => handleAddFund()}
                        >
                          Add Fund
                        </button>
                      </div>
                    )}
                    <p className="text-red-500 text-sm mt-1">
                      {errors.fundraiserId?.message}
                    </p>
                  </div>

                  {/* Submit Button */}
                  <div className="mb-6 md:flex justify-center">
                    <button
                      type="submit"
                      className="w-1/3 text-white p-2 rounded-md btn-style-1 mt-4 "
                    >
                      Submit
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </main>
      </div>
      <ToastContainer theme="colored" />
    </>
  );
};

export default AddDonation;
