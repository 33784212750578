import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import fetchConfig from './config';  // Ensure this import path is correct

const RootComponent = () => {
  const [config, setConfig] = useState(null);

  useEffect(() => {
    fetchConfig().then((config) => {
      document.documentElement.style.setProperty('--primary-color', config.primaryColor);
      document.documentElement.style.setProperty('--secondary-color', config.secondaryColor);
      document.documentElement.style.setProperty('--facebook-url', config.facebookUrl);
      document.documentElement.style.setProperty('--instagram-url', config.instagramUrl);
      document.documentElement.style.setProperty('--linkdin-url', config.linkdenUrl);
      document.documentElement.style.setProperty('--x-url', config.xUrl);
      document.documentElement.style.setProperty('--thread-url', config.threadUrl);
      document.documentElement.style.setProperty('--email', config.email);
      document.documentElement.style.setProperty('--phoneNumber', config.phoneNumber);
      setConfig(config);
    });
  }, []);

  return (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RootComponent />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
