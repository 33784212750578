import React, { useRef, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { TQ_URL } from "../../utility/baseUrl";

const schema = yup.object().shape({
  causeTitle: yup.string().required("Cause Title is required"),
  shortDescription: yup.string().required("Short Description is required"),
  // image: yup.mixed().required("Image is required"),
  // image: yup.mixed().test("required", "Image is required", (value) => {
  //   return value instanceof File || value instanceof Blob;
  // }),
});

const AddCausePopup = ({ onClose }) => {
  const [uploadedImage, setUploadedImage] = useState(null);
  const fileInputRef = useRef();
  const [loading, setLoading] = useState(false);
  const [newcause, setNewcause] = useState("");
  const [config, setConfig] = useState({});

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    fetch("/config.json")
      .then((response) => response.json())
      .then((data) => {
        setConfig(data);
        console.log(data.partnerId);
      })
      .catch((error) => console.error("Error loading config.json:", error));
  }, []);

  const handleCreateCause = (data, event) => {
    console.log("Form data:", data);
    const partnerId = config.partnerId;
    console.log(partnerId);
    if (!partnerId) {
      throw new Error("Partner ID is not defined");
    }

    // if (uploadedImage == null) {
    //   toast.error("Select Cause Image!");
    //   return;
    // }

    event.preventDefault();
    setLoading(true);

    const formData = new FormData();

    formData.append("causeName", data.causeTitle);
    formData.append("partnerId", partnerId);

    formData.append("desc", data.shortDescription);
    formData.append("actStatus", 1);

    //  formData.append(`file`, uploadedImage);

    console.log(formData);
    const token = localStorage.getItem("accessToken");

    axios
      .post(`${TQ_URL}/saveCause`, formData, {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response.data);

        if (response.data.msg == "Cause saved Successfully") causeCreated();
        else causeNotCreated();

        setTimeout(() => {
          setTimeout(onClose(response.data.savedData), 2000);
        }, 3000);
      });

    // setTimeout(() => {
    //   setTimeout(onClose(), 2000);
    // }, 3000);
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    setValue("image", file);
    setUploadedImage(file);
  };

  const handleClickUpload = () => {
    fileInputRef.current.click();
  };

  const handleRemoveImage = () => {
    setValue("image", null);
    setUploadedImage(null);
  };

  const causeCreated = () => toast.success("Cause Added Successfully!");
  const causeNotCreated = () => toast.success("Failed to add Cause!");

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center overflow-y-scroll z-50 px-4">
      <div className="fade-in-bottom rounded-xl w-[500px]">
        <div className="bg-white rounded-t-xl max-w-2xl w-full flex justify-between items-center">
          <h2 className="text-xl font-bold mb-4 text-start pt-4 px-10">
            Add Cause
          </h2>

          <button onClick={onClose} className="px-2 cursor-pointer">
            <svg
              className="w-8"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  d="M6.99486 7.00636C6.60433 7.39689 6.60433 8.03005 6.99486 8.42058L10.58 12.0057L6.99486 15.5909C6.60433 15.9814 6.60433 16.6146 6.99486 17.0051C7.38538 17.3956 8.01855 17.3956 8.40907 17.0051L11.9942 13.4199L15.5794 17.0051C15.9699 17.3956 16.6031 17.3956 16.9936 17.0051C17.3841 16.6146 17.3841 15.9814 16.9936 15.5909L13.4084 12.0057L16.9936 8.42059C17.3841 8.03007 17.3841 7.3969 16.9936 7.00638C16.603 6.61585 15.9699 6.61585 15.5794 7.00638L11.9942 10.5915L8.40907 7.00636C8.01855 6.61584 7.38538 6.61584 6.99486 7.00636Z"
                  fill="#0F0F0F"
                ></path>
              </g>
            </svg>
          </button>
        </div>

        <div className="p-6 bg-blue-50 rounded-b-xl">
          <form onSubmit={handleSubmit(handleCreateCause)}>
            <label
              className="block mb-2 font-bold
            "
            >
              Cause Title{" "}
              <span className=" text-[10px] font-normal text-gray-700">
                (Max 60 Chars)
              </span>
            </label>
            <input
              type="text"
              {...register("causeTitle")}
              className={`border p-2 w-full shadow-md rounded-lg ${
                errors.causeTitle && "border-red-500"
              }`}
            />
            <p className="text-red-500 text-sm mt-1">
              {errors.causeTitle?.message}
            </p>

            <label className="block mt-4 mb-2 font-bold">
              Short Description{" "}
              <span className=" text-[10px] font-normal text-gray-700">
                (Max 100 Chars)
              </span>
            </label>
            <textarea
              {...register("shortDescription")}
              rows={3}
              style={{ resize: "none" }}
              className={`border p-2 w-full shadow-md rounded-lg ${
                errors.shortDescription && "border-red-500"
              }`}
            />
            <p className="text-red-500 text-sm mt-1">
              {errors.shortDescription?.message}
            </p>

            {/* <label className="block mt-4 mb-2 font-bold">Upload Image</label>
            <div
              className={`mb-4 border p-6 w-full bg-white shadow-md  rounded cursor-pointer ${
                uploadedImage ? "hover-shadow" : ""
              }`}
              onClick={handleClickUpload}
            >
              <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                onChange={handleFileInputChange}
                className="hidden"
              />
              {uploadedImage && (
                <div className="flex items-center justify-between">
                  <p className="mr-4">{uploadedImage.name}</p>
                  <button type="button" onClick={handleRemoveImage}>
                    &#x2715;
                  </button>
                </div>
              )}
              {!uploadedImage && <p>Click to upload an image</p>}
              <p className="text-red-500 text-sm mt-1">
                {errors.image?.message}
              </p>
            </div> */}

            <div className="flex justify-end">
              <button
                onClick={onClose}
                className="bg-gray-300 text-gray-700  py-2 mr-2 px-10 btn-style-3 !rounded-md"
              >
                Close
              </button>
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded btn-style-1"
              >
                Create Cause
              </button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer theme="colored" />
    </div>
  );
};

export default AddCausePopup;
