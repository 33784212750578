import React from 'react';
import DonorManagementTop from '../DonorManagement/DonorManagementTop';
import { Link } from 'react-router-dom';
const AnalyticsDashboard = () => {
    return (
        <>
            <DonorManagementTop />
            <main className="flex h-full my-10 justify-center">
                <div className="container px-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 ">
                    {/* Box 1 */}
                    <Link to = "/fundraiserPerformance" className="max-w-xs  webkit-centerForAnalytics border-b-4 border-transparent transition ease-in-out delay-150 shadow-lg hover:border-[#ff7b00] ">
                        <img className="  h-32 md:h-44" src="assets/images/fperformance.jpg" alt="" />
                        <div className="p-3 pt-5">
                            <h4 className="mb-2 lg:lg:text-xl sm:text-lg  text-gray-900 dark:text-white font-semibold text-center ">Fundraiser Performance</h4>
                        </div>
                    </Link>
                    <a href="#" className="max-w-xs  webkit-centerForAnalytics border-b-4 border-transparent transition ease-in-out delay-150 shadow-lg hover:border-[#ff7b00] ">
                        <img className="rounded-t-lg  h-32 md:h-40" src="assets/images/cdonation.jpg" alt="" />
                        <div className="p-3 pt-5">
                            <h4 className="mb-2 lg:text-xl sm:text-lg  text-gray-900 dark:text-white font-semibold text-center">Causewise Donation</h4>
                        </div>
                    </a>
                    <a href="#" className="max-w-xs  webkit-centerForAnalytics border-b-4 border-transparent transition ease-in-out delay-150 shadow-lg hover:border-[#ff7b00] ">
                        <img className="rounded-t-lg  h-32 md:h-40" src="assets/images/rdonate.jpg" alt="" />
                        <div className="p-3 pt-5">
                            <h4 className="mb-2 lg:text-xl sm:text-lg  text-gray-900 dark:text-white font-semibold text-center">Recurring Causewise Donation</h4>
                        </div>
                    </a>
                    <a href="#" className="max-w-xs  webkit-centerForAnalytics border-b-4 border-transparent transition ease-in-out delay-150 shadow-lg hover:border-[#ff7b00] ">
                        <img className="rounded-t-lg  h-32 md:h-40" src="assets/images/newuser.jpg" alt="" />
                        <div className="p-3 pt-5">
                            <h4 className="mb-2 lg:text-xl sm:text-lg  text-gray-900 dark:text-white font-semibold ">Fundraiser New Users</h4>
                        </div>
                    </a>

                    <a href="#" className="max-w-xs  webkit-centerForAnalytics  mt-4 border-b-4 border-transparent transition ease-in-out delay-150 shadow-lg hover:border-[#ff7b00] ">
                        <img className="rounded-t-lg  h-32 md:h-40" src="assets/images/mdonation.jpg" alt="" />
                        <div className="p-3 pt-5">
                            <h4 className="mb-2 lg:text-xl sm:text-lg  text-gray-900 dark:text-white font-semibold text-center">Monthly new
                                Doners</h4>
                        </div>
                    </a>
                    <a href="#" className="max-w-xs  webkit-centerForAnalytics mt-4 border-b-4 border-transparent transition ease-in-out delay-150 shadow-lg hover:border-[#ff7b00] ">
                        <img className="rounded-t-lg  h-32 md:h-40" src="assets/images/hww1_copy1.png" alt="" />
                        <div className="p-3 pt-5">
                            <h4 className="mb-2 lg:text-xl sm:text-lg  text-gray-900 dark:text-white font-semibold text-center">Monthly new
                                Recurring
                                Donor</h4>
                        </div>
                    </a>
                    <a href="#" className="max-w-xs  webkit-centerForAnalytics  mt-4 border-b-4 border-transparent transition ease-in-out delay-150 shadow-lg hover:border-[#ff7b00] ">
                        <img className="rounded-t-lg  h-32 md:h-40" src="assets/images/retenation.jpg" alt="" />
                        <div className="p-3 pt-5">
                            <h4 className="mb-2 lg:text-xl sm:text-lg  text-gray-900 dark:text-white font-semibold text-center">Donor Retantion</h4>
                        </div>
                    </a>
                    <a href="#" className="max-w-xs  webkit-centerForAnalytics  mt-4 border-b-4 border-transparent transition ease-in-out delay-150 shadow-lg hover:border-[#ff7b00] ">
                        <img className="rounded-t-lg  h-32 md:h-40" src="assets/images/hww1_copy1.png" alt="" />
                        <div className="p-3 pt-5">
                            <h4 className="mb-2 lg:text-xl sm:text-lg  text-gray-900 dark:text-white font-semibold ">Amount Raised
                                per Email Sent
                                Reports</h4>
                        </div>
                    </a>
                    <a href="#" className="max-w-xs  webkit-centerForAnalytics  mt-4 border-b-4 border-transparent transition ease-in-out delay-150 shadow-lg hover:border-[#ff7b00] ">
                        <img className="rounded-t-lg  h-32 md:h-40" src="assets/images/dreport.jpg" alt="" />
                        <div className="p-3 pt-5">
                            <h4 className="mb-2 lg:text-xl sm:text-lg  text-gray-900 dark:text-white font-semibold ">
                                Donation Page
                                Conversion
                                Reports</h4>
                        </div>
                    </a>
                </div>

            </main>
        </>
    );
};

export default AnalyticsDashboard;
