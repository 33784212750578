import React from "react";

import DonorManagementSideBar from "./DonorManagementSideBar";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { useCallback, useEffect, useState, useMemo } from "react";
import { useNavigate, Link } from "react-router-dom";
import { IconEdit, IconEye, IconTrash } from "@tabler/icons-react";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import axios from "axios";
import Typography from "@mui/material/Typography";
// import Box from "@mui/material/Box";

import {
  ActionIcon,
  Box,
  Button,
  Loader,
  MantineProvider,
} from "@mantine/core";
import TopHeader from "../Home/TopHeader";
import {
  getRequestWithTokenWithData,
  getRequestWithTokenWithoutData,
  getRequestWithOutToken,
  postRequestWithoutTokenWithoutData,
  getRequestWithTokenWithoutDataPartner,
} from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import DonorManagementTop from "./DonorManagementTop";
import CreateFund from "./CreateFund";

const AllFundraisersList = () => {
  const navigate = useNavigate();
  const donorDetails = [
    {
      id: 1,
      donorName: "John Doe",
      donorEmail: "john@example.com",
      phone: "+1234567890",
      totalDonatedAmt: 100,
    },
    {
      id: 2,
      donorName: "Jane Smith",
      donorEmail: "jane@example.com",
      phone: "+1987654321",
      totalDonatedAmt: 200,
    },
    {
      id: 3,
      donorName: "Alice Johnson",
      donorEmail: "alice@example.com",
      phone: "+1122334455",
      totalDonatedAmt: 300,
    },
    {
      id: 1,
      donorName: "John Doe",
      donorEmail: "john@example.com",
      phone: "+1234567890",
      totalDonatedAmt: 100,
    },
    {
      id: 2,
      donorName: "Jane Smith",
      donorEmail: "jane@example.com",
      phone: "+1987654321",
      totalDonatedAmt: 200,
    },
    {
      id: 3,
      donorName: "Alice Johnson",
      donorEmail: "alice@example.com",
      phone: "+1122334455",
      totalDonatedAmt: 300,
    },
  ];

  const [fundraiserData, setFundraiserData] = useState([donorDetails]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);

  const [sorting, setSorting] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [value, setValue] = React.useState(0);
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [modalopen, setModalOpen] = useState(false);

  useEffect(() => {
    if (!fundraiserData.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    getAllFundraiserList();
  }, [
    pagination.pageIndex, //refetch when page index changes
    pagination.pageSize, //refetch when page size changes
    sorting, //refetch when sorting changes
    activeTab,
    columnFilters,
  ]);
  const partnerData = JSON.parse(localStorage.getItem("partnerData"));

  const getAllFundraiserList = async () => {
    console.log(activeTab);
    const url = new URL(`${TQ_URL}/getAllFundraiserList`);
    url.search = new URLSearchParams();
    url.searchParams.set("start", `${pagination.pageIndex}`);
    url.searchParams.set("size", `${pagination.pageSize}`);
    url.searchParams.set("activeTab", `${activeTab}`);

    url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
    url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
    url.searchParams.set("partnerId", `${partnerData.partnerId}`);

    try {
      const response = await getRequestWithTokenWithoutDataPartner(
        url.toString()
      );

      //  setDonationData(response);
      console.log(response);

      console.log(response.datalist);

      const dataWithSerialNumber = response.datalist.map((item, index) => ({
        ...item,
        id: index + 1,
      }));

      console.log(response.datalist);

      setFundraiserData(dataWithSerialNumber);
      setRowCount(response.totalCount);

      setIsRefetching(false);
    } catch (error) {
      console.error("Error in fetching the all donation list:", error);
      setIsError(true);
      setIsLoading(false);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    //  setIsRefetching(false);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        sortable: true,
      },

      {
        accessorKey: "fundraiserName",
        header: "Fundraiser name",
        sortable: true,
      },

      {
        accessorKey: "formattedTargerDate",
        header: "Target Date",
        sortable: true,
      },
      {
        accessorKey: "causeNames",
        header: "Cause Name",
        sortable: true,
      },
      {
        accessorKey: "targetAmt",
        header: "Target amount",
        sortable: true,
      },
      {
        accessorKey: "amountAchieved",
        header: "Generated amount",
        sortable: true,
      },
      {
        accessorKey: "formattedDate",
        header: "Created Date",
        sortable: true,
      },
    ],
    []
  );

  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      console.log("searchText:", searchText);

      const url = new URL(`${TQ_URL}/searchImportedFundraiserList`);
      url.search = new URLSearchParams();
      url.searchParams.set("start", `${pagination.pageIndex}`);
      url.searchParams.set("size", `${pagination.pageSize}`);
      url.searchParams.set("activeTab", `${activeTab}`);
      url.searchParams.set("keyword", `${searchText}`);
      url.searchParams.set("partnerId", `${partnerData.partnerId}`);
      try {
        const response = await getRequestWithTokenWithoutDataPartner(
          url.toString()
        );

        //  setDonationData(response);
        console.log(response);

        console.log(response.dataList);

        const dataWithSerialNumber = response.dataList.map((item, index) => ({
          ...item,
          id: index + 1,
        }));

        console.log(response.dataList);

        setFundraiserData(dataWithSerialNumber);
        setRowCount(response.totalCount);

        setIsRefetching(false);
      } catch (error) {
        console.error("Error in fetching the searched donor's history:", error);
        setIsError(true);
        setIsLoading(false);
        console.error(error);
        return;
      }
    }
  };
  const handleChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleEdit = (row) => {
    console.log("inside edit");
    console.log(activeTab);

    console.log(row);
  };

  const handleTabs = (e, value) => {
    console.log(e);
    setValue(e);
    console.log("Value:", value);
    if (e == 0) {
      setActiveTab(1);
      //  getAllDonationsDonorList(donorIdd);
      console.log("data from imported donors");
    } else {
      console.log("data from website donors");
      setActiveTab(2);
      //  getAllDonationsDonorList();
    }
  };

  const table = useMantineReactTable({
    columns,
    data: fundraiserData,

    initialState: { showColumnFilters: false },
    rowCount,

    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onColumnFiltersChange: setColumnFilters,

    // enableRowActions: true,
    // positionActionsColumn: "last",

    onPaginationChange: setPagination,
    onSortingChange: setSorting,

    mantineTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        const rowData = row.original;
        const fundraiserId = row.original.fundraiserId;
        console.log(fundraiserId);
        console.log(activeTab);
        localStorage.setItem("activeTabFundraiser", activeTab);
        navigate("/fundraiserInfo", { state: { fundraiserId } });
      },
      sx: { cursor: "pointer" },
    }),

    state: {
      columnFilters,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });
  function importModal() {
    setModalOpen(true);
  }
  return (
    <>
      {/* <TopHeader /> */}
      <DonorManagementTop />
      <div className="flex">
        <div className="md:w-64 w-1/12 absolute md:relative">
          <DonorManagementSideBar />
        </div>

        <main className="w-full verify-user">
          <div className=" mx-auto shadow-md">
            <div className="md:flex justify-between container items-center px-10 md:py-6 py-6">
              <div className="text-center">
                <h2 className="text-2xl font-bold">All Fundraiser List</h2>
              </div>
            </div>
          </div>

          <div className="w-full flex flex-col md:flex-row items-center md:mt-4">
            <div className=" md:mx-8 rounded  p-1 flex  w-full  justify-between md:flex-grow-0 md:w-6/12">
              <input
                type="text"
                placeholder="Search..."
                className="py-2 pr-8 pl-4 block w-full rounded-lg bg-gray-200 border border-gray-300 focus:outline-none focus:ring focus:border-blue-300"
                onChange={handleChange}
                onKeyDown={handleKeyPress}
              />

              
            </div>

            <div className="flex mx-8 my-2">
                {/* Two buttons */}

                {activeTab == 1 && (
                  
                    <button className="btn-style-1 text-white py-1 px-1 md:py-2  md:px-4 rounded  md:block mx-2"
                    onClick={() => importModal()}>
                      +Add Fund
                    </button>
                 
                )}
              </div>
          </div>

          <div className="container md:mx-auto md:py-16 py-6">
            {/*   <div sx={{ width: "100%" }}>*/}
            <div style={{ borderBottom: "1px solid #ddd" }}>
              <Tabs
                value={value}
                onChange={(event, newValue) => handleTabs(newValue)}
                aria-label="basic tabs example"
              >
                <Tab label="Imported Fundraisers" value={0} />
                <Tab label="Website Fundraisers" value={1} />
              </Tabs>
            </div>

            <div className="md:py-4 py-6">
              {value === 0 && <MantineReactTable table={table} />}
              {value === 1 && (
                <MantineReactTable table={table} /> // Replace `table2` with your data for tab 2
              )}
            </div>
          </div>
          {/* <MantineReactTable table={table} /> */}
          {/*  </div>*/}
        </main>
      </div>
      {modalopen && <CreateFund onClose={() => setModalOpen(false)} />}
    </>
  );
};
export default AllFundraisersList;
