import React, { useEffect, useState, useMemo } from "react";
import TopHeader from "../Home/TopHeader";
import DonorManagementSideBar from "./DonorManagementSideBar";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AOS from "aos";
import "aos/dist/aos.css";
import DonorManagementTop from "./DonorManagementTop";
// import { PieChart, Pie, Tooltip, Cell } from "recharts";

import { PieChart } from "@mui/x-charts/PieChart";

import {
  getRequestWithTokenWithData,
  postRequestWithoutTokenWithoutData,
  getRequestWithTokenWithoutData,
  getRequestWithOutToken,
  getRequestWithTokenWithoutDataPartner,
} from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";

export const FundraiserInformation = () => {
  var donorId;

  const [donorData, setDonorData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);

  const [pieData, setPieData] = useState([]);

  const actTab = localStorage.getItem("activeTabFundraiser");

  console.log(actTab);

  const [sorting, setSorting] = useState([]);
  const [activeTab, setActiveTab] = useState(actTab);
  const [value, setValue] = React.useState(parseInt(actTab) - 1);
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { state } = useLocation();
  const partnerData = JSON.parse(localStorage.getItem("partnerData"));

  const fundraiserId = state?.fundraiserId;

  console.log(fundraiserId);
  // var fundraiserId = "202402121";
  //  var activeType = 2;

  const [fundraiserInfo, setFundraiserInfo] = useState({});
  //   const [donorType, setDonorType] = useState();

  //   useEffect(() => {
  // if (rowData.importedDonorId != 0) {
  //   setDonorId(rowData.importedDonorId);
  //   setDonorType(1);
  // } else {
  //   setDonorId(rowData.moneyDonationDonorId);
  //   setDonorType(2);
  // }
  //   }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        sortable: true,
      },

      {
        accessorKey: "donorName",
        header: "Donor name",
        sortable: true,
      },

      {
        accessorKey: "donatedAmt",
        header: "Donated amount",
        sortable: true,
      },
      {
        accessorKey: "donationDate",
        header: "Donation Date",
        sortable: true,
      },
    ],
    []
  );

  useEffect(() => {
    AOS.init();
    fetchFundraiserInfo();

    getAllDonationsForFundraiserList();
  }, []);

  useEffect(() => {
    fetchFundraiserInfo();

    getAllDonationsForFundraiserList();
  }, [
    pagination.pageIndex, //refetch when page index changes
    pagination.pageSize, //refetch when page size changes
    sorting, //refetch when sorting changes
    activeTab,
    columnFilters,
    actTab,
  ]);

  const fetchFundraiserInfo = async () => {
    try {
      const response = await getRequestWithTokenWithData(
        `${TQ_URL}/getFundraiserDetails`,
        {
          fundraiserId: `${fundraiserId}`,
          activeTab: parseInt(actTab),
        }
      );

      console.log(response.donorInfo);

      const pieData1 = [
        {
          name: "TargetAmount",
          value: response.datalist.targetAmt,
          fill: "#bbff99",
        },
        {
          name: "GeneratedAmount",
          value: response.datalist.amountAchieved,
          fill: "#ccff66",
        },
      ];

      setPieData(pieData1);

      setFundraiserInfo(response.datalist);
    } catch (error) {
      console.error("Error in fetching data for fundraiser id:", error);
    }
  };

  const getAllDonationsForFundraiserList = async () => {
    const url = new URL(`${TQ_URL}/getDonationListForFundraiser`);
    url.search = new URLSearchParams();
    url.searchParams.set("start", `${pagination.pageIndex}`);
    url.searchParams.set("size", `${pagination.pageSize}`);
    url.searchParams.set("activeTab", `${activeTab}`);
    url.searchParams.set("fundraiserId", `${fundraiserId}`);

    url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
    url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));

    try {
      const response = await getRequestWithTokenWithoutDataPartner(
        url.toString()
      );

      console.log(response);

      console.log(response.datalist);

      const dataWithSerialNumber = response.datalist.map((item, index) => ({
        ...item,
        id: index + 1,
      }));

      console.log(response.dataList);

      setDonorData(dataWithSerialNumber);
      setRowCount(response.totalCount);

      setIsRefetching(false);
    } catch (error) {
      console.error("Error in fetching the donor's history:", error);
      setIsError(true);
      setIsLoading(false);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
  };

  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      console.log("searchText:", searchText);

      const url = new URL(`${TQ_URL}/searchFundraiserListForImportedDonation`);
      url.search = new URLSearchParams();
      url.searchParams.set("start", `${pagination.pageIndex}`);
      url.searchParams.set("size", `${pagination.pageSize}`);
      url.searchParams.set("activeTab", `${activeTab}`);
      url.searchParams.set("keyword", `${searchText}`);
      url.searchParams.set("fundraiserId", `${fundraiserId}`);
      url.searchParams.set("partnerId", `${partnerData.partnerId}`);

      try {
        const response = await getRequestWithTokenWithoutDataPartner(
          url.toString()
        );

        console.log(response);

        console.log(response.dataList);

        const dataWithSerialNumber = response.dataList.map((item, index) => ({
          ...item,
          id: index + 1,
        }));

        console.log(response.dataList);

        setDonorData(dataWithSerialNumber);
        setRowCount(response.totalCount);

        setIsRefetching(false);
      } catch (error) {
        console.error("Error in fetching the searched donor's history:", error);
        setIsError(true);
        setIsLoading(false);
        console.error(error);
        return;
      }
    }
  };
  const handleChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleTabs = (e, value) => {
    console.log(e);
    setValue(e);
    console.log("Value:", value);
    if (e == 0) {
      setActiveTab(1);
    } else {
      setActiveTab(2);
    }
  };

  const handleAddDonationClick = () => {
    console.log("Add Donation button clicked");

    localStorage.setItem("fundraiserId", fundraiserId);
  };

  const table = useMantineReactTable({
    columns,
    data: donorData,

    initialState: { showColumnFilters: false },
    rowCount,

    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onColumnFiltersChange: setColumnFilters,

    onPaginationChange: setPagination,
    onSortingChange: setSorting,

    state: {
      columnFilters,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  return (
    <>
      {/* <TopHeader /> */}
      <DonorManagementTop />
      <div className="flex">
        <div className="md:w-64 w-1/12 absolute md:relative">
          <DonorManagementSideBar />
        </div>

        <main className="w-full verify-user">
          <div className=" mx-auto shadow-md">
            <div className="md:flex justify-between container items-center px-10 md:py-6 py-6 ">
              <div className="text-center">
                <h2 className="text-2xl font-bold">Fundraiser Inofrmation</h2>
              </div>
            </div>
          </div>

          <div
            className="m-0 md:flex py-8 justify-between  donor-info"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="md:w-3/4 md:mx-4 flex flex-col ">
              <h1 className="text-3xl font-bold md:mb-8 mb-4 md:mx-2">
                {fundraiserInfo.fundraiserName}
              </h1>

              <div className="md:flex space-y-10 md:space-y-0 justify-between  md:mx-8  md:mb-8">
                <div className="counter-item flex-1">
                  <p className="font-extrabold">Created By:</p>
                  <p>
                    {fundraiserInfo.createdBy != null
                      ? fundraiserInfo.createdBy
                      : "--"}
                  </p>
                </div>

                <div className="counter-item flex-1 ">
                  <p className="font-extrabold">Created date:</p>
                  <p>
                    {" "}
                    {fundraiserInfo.formattedDate != null
                      ? fundraiserInfo.formattedDate
                      : "--"}
                  </p>
                </div>

                <div className="counter-item flex-1 ">
                  <p className="font-extrabold">Target date:</p>
                  <p>
                    {" "}
                    {fundraiserInfo.formattedTargerDate != null
                      ? fundraiserInfo.formattedTargerDate
                      : "--"}
                  </p>
                </div>

                <div className="counter-item flex-1">
                  <p className="font-extrabold">Target Amount :</p>

                  <p>
                    $
                    {fundraiserInfo.targetAmt != null
                      ? fundraiserInfo.targetAmt
                      : "--"}
                  </p>
                </div>
              </div>

              <div className="md:flex space-y-10 md:space-y-0 justify-between mb:4 mt-8 md:mx-8 md:mb-4">
                <div className="counter-item flex-1">
                  <p className="font-extrabold">Generated Amount :</p>
                  <p>${fundraiserInfo.amountAchieved}</p>
                </div>
              </div>
            </div>

            {actTab == 2 && (
              <div className="md:w-1/2  px-8 flex flex-col">
                {/* <PieChart width={300} height={300} className="md:ml-4">
                <Pie
                  data={pieData}
                  dataKey="value"
                  outerRadius={100}
                  fill="green"
                />
                {pieData.map((entry, index) => (
                  <Cell key={`cell-${index}`} stroke="black" strokeWidth={1} />
                ))}
                <Tooltip />
              </PieChart> */}

                <PieChart
                  colors={["#E1A2C6", "#C6B4F0"]} // Use palette
                  // colors={["#CFBCE2", "#C6B4F0"]}
                  series={[
                    {
                      data: [
                        {
                          id: 0,
                          value: fundraiserInfo.targetAmt,
                          label: "Targtted Amount ",
                        },
                        {
                          id: 1,
                          value: fundraiserInfo.amountAchieved,
                          label: "Achieved Amount",
                        },
                      ],

                      // data: pieData,

                      innerRadius: 20,
                      outerRadius: 100,
                      paddingAngle: 2,
                      // cornerRadius: 5,
                      // startAngle: -90,
                      // endAngle: 180,
                      cx: 150,
                      cy: 100,
                    },
                  ]}
                />
              </div>
            )}
          </div>

          {/* *********************************** */}

          <div className="w-full flex flex-col md:flex-row items-center md:mt-4">
            <div className=" md:mx-8 rounded  p-1 flex  w-full  justify-between md:flex-grow-0 md:w-6/12">
              <input
                type="text"
                placeholder="Search..."
                className="py-2 pr-8 pl-4 block w-full rounded-lg bg-gray-200 border border-gray-300 focus:outline-none focus:ring focus:border-blue-300"
                onChange={handleChange}
                onKeyDown={handleKeyPress}
              />
            </div>
            <div className="flex mx-8 my-2">
              {/* Two buttons */}

              {actTab == 1 && (
                <Link to={`/addDonations`} onClick={handleAddDonationClick}>
                  <button className="btn-style-1 text-white py-1 px-1 md:py-2  md:px-4 rounded  md:block mx-2">
                    +Add Donation
                  </button>
                </Link>
              )}
            </div>
          </div>

          <div className="md:mx-8 md:py-8 py-6">
            <div sx={{ width: "100%" }}>
              {/* <div style={{ borderBottom: "1px solid #ddd" }}>
                <Tabs
                  value={value}
                  onChange={(event, newValue) => handleTabs(newValue)}
                  aria-label="basic tabs example"
                >
                  <Tab label="Imported donations" value={0} />
                  <Tab label="Website Donations" value={1} />
                </Tabs>
              </div> */}

              <div className="md:mx-2 md:py-4 py-6">
                {/* {value === 0 && <MantineReactTable table={table} />}
                {value === 1 && (
                  <MantineReactTable table={table} /> // Replace `table2` with your data for tab 2
                )} */}

                <MantineReactTable table={table} />
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};
