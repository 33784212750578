import React from "react";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Header from "../Home/Header";
import Footer from "../Home/Footer";
import { Link, useNavigate } from "react-router-dom";

const schema = yup.object().shape({
  bankname: yup.string().required("Bank name is required"),
  beneficiaryname: yup.string().required("Beneficiary name is required"),
  state: yup.string().required("State is required"),
  accountnumber: yup
    .number()
    .required("Account number is required")
    .positive()
    .integer(),
});

const AddBankDetails = () => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = (data) => {
    // Your form submission logic here
    console.log("Bank Details", data);
    navigate("/userprofile");
  };

  return (
    <>
      <Header />
      <div className="flex flex-col md:flex-row md:min-h-screen ">
        {/* Left Section */}
        <div className="md:flex-1 bg-cover bg-center relative fade-in-left add-bg-bg">
          <div className="absolute inset-0 bg-black opacity-40"></div>
          <div className="absolute inset-0 flex flex-col items-start justify-start md:px-20 px-5 mt-28">
            <h3 className="text-white txt-style-2 mb-10">
              Add Bank
              <br />
              Details
            </h3>
          </div>
        </div>

        {/* Right Section */}
        <div className="md:flex-1 flex items-center justify-start  p-10 sm:p-10 md:ms-10 max-w-screen-lg mx-auto w-full  h-full fade-in-right">
          <div className="max-w-full w-full sm:max-w-lg 2xl:max-w-2xl ">
            {/* Logo */}
            <div className="text-center md:text-left mb-10 sm:mb-20">
              <img
                src="assets/images/main-logo.svg"
                alt="Logo"
                className="h-10 mx-auto sm:mx-0 hidden md:block"
              />
            </div>

            <h1 className="txt-style-3 text-center my-10">ADD BANK DETAILS</h1>

            {/* Email and Password Fields */}
            <form onSubmit={handleSubmit(onSubmitHandler)}>
              <div className="mb-6">
                <Controller
                  name="bankname"
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      type="text"
                      id="bankname"
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                        errors.bankname && "border-red-500"
                      }`}
                      placeholder="Bank name*"
                    />
                  )}
                />
                <p className="text-red-500 text-sm mt-1">
                  {errors.bankname?.message}
                </p>
              </div>

              <div className="mb-6">
                <Controller
                  name="beneficiaryname"
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      type="text"
                      id="beneficiaryname"
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                        errors.beneficiaryname && "border-red-500"
                      }`}
                      placeholder="Beneficiary name*"
                    />
                  )}
                />
                <p className="text-red-500 text-sm mt-1">
                  {errors.beneficiaryname?.message}
                </p>
              </div>

              <div className="mb-6">
                <Controller
                  name="state"
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      type="text"
                      id="state"
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                        errors.state && "border-red-500"
                      }`}
                      placeholder="State*"
                    />
                  )}
                />
                <p className="text-red-500 text-sm mt-1">
                  {errors.state?.message}
                </p>
              </div>

              <div className="mb-6">
                <Controller
                  name="accountnumber"
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      type="number"
                      id="accountnumber"
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                        errors.accountnumber && "border-red-500"
                      }`}
                      placeholder="Account No*"
                    />
                  )}
                />
                <p className="text-red-500 text-sm mt-1">
                  {errors.accountnumber?.message}
                </p>
              </div>

              {/* Log In Button */}
              <div className="flex justify-center my-10">
                <button
                  type="submit"
                  className="w-1/2 text-white p-2 rounded-md mb-6 btn-style-1 "
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AddBankDetails;
