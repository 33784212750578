import React, { useEffect, useState, useMemo } from "react";
import TopHeader from "../Home/TopHeader";
import DonorManagementSideBar from "./DonorManagementSideBar";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import DonorManagementTop from "./DonorManagementTop";
import AOS from "aos";
import "aos/dist/aos.css";

import {
  getRequestWithTokenWithoutDataPartner,
  getRequestWithTokenWithDataWithAuthPartner,
} from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";

export const DonorInformation = () => {
  var donorId;

  const partnerData = JSON.parse(localStorage.getItem("partnerData"));
  const [donorData, setDonorData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);

  const actTab = localStorage.getItem("activeTab");

  console.log(actTab);

  const [sorting, setSorting] = useState([]);
  const [activeTab, setActiveTab] = useState(parseInt(actTab));
  const [value, setValue] = React.useState(parseInt(actTab) - 1);
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { state } = useLocation();

  const rowData = state?.rowData;

  const [addressDetails, setAddressDetails] = useState({});
  //   const [donorType, setDonorType] = useState();
  const [donorIdd, setDonorIdd] = useState();
  const [donorEmail, setDonorEmail] = useState();

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        sortable: true,
      },

      {
        accessorKey: "fundraiserName",
        header: "Fundraiser",
        sortable: true,
      },
      {
        accessorKey: "causeNames",
        header: "Cause Name",
        sortable: true,
      },
      {
        accessorKey: "donatedAmt",
        header: "Donated amount",
        sortable: true,
      },
      {
        accessorKey: "donationDate",
        header: "Donation Date",
        sortable: true,
      },
    ],
    []
  );

  useEffect(() => {
    AOS.init();
    console.log("RowData:", rowData);

    fetchDonorInfo();
    //   getAllDonationsDonorList();
  }, []);

  useEffect(() => {
    if (donorEmail != null || donorEmail != "undefined")
      getAllDonationsDonorList(donorEmail);
  }, [
    pagination.pageIndex, //refetch when page index changes
    pagination.pageSize, //refetch when page size changes
    sorting, //refetch when sorting changes
    activeTab,
    columnFilters,
    actTab,
  ]);

  const fetchDonorInfo = async () => {
    try {
      var donorType;

      if (rowData.importedDonorId != 0) {
        donorId = rowData.importedDonorId;
        setDonorEmail(rowData.donorEmail);
        donorType = 1;
      } else {
        donorId = rowData.donorId;
        setDonorEmail(rowData.donorEmail);
        donorType = 2;
      }

      console.log("donorId: ", donorId, "  ", "donorType: ", donorType);
      const response = await getRequestWithTokenWithDataWithAuthPartner(
        `${TQ_URL}/getDonorInformation`,
        {
          donorId: donorId,
          donorType: donorType,
        }
      );

      getAllDonationsDonorList(rowData.donorEmail);

      console.log(response.donorInfo);
      setAddressDetails(response.donorInfo);
    } catch (error) {
      console.error("Error in fetching data for fundraiser id:", error);
    }
  };

  const getAllDonationsDonorList = async (donorEmail) => {
    console.log(activeTab);
    console.log(donorIdd);
    const url = new URL(`${TQ_URL}/getDonationListForDonor`);
    url.search = new URLSearchParams();
    url.searchParams.set("start", `${pagination.pageIndex}`);
    url.searchParams.set("size", `${pagination.pageSize}`);
    url.searchParams.set("activeTab", `${activeTab}`);
    url.searchParams.set("donorEmail", `${donorEmail}`);
    url.searchParams.set("partnerId", `${partnerData.partnerId}`);

    url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
    url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));

    try {
      const response = await getRequestWithTokenWithoutDataPartner(
        url.toString()
      );

      //  setDonationData(response);
      console.log(response);

      console.log(response.datalist);

      const dataWithSerialNumber = response.datalist.map((item, index) => ({
        ...item,
        id: index + 1,
      }));

      console.log(response.dataList);

      setDonorData(dataWithSerialNumber);
      setRowCount(response.totalCount);

      setIsRefetching(false);
    } catch (error) {
      console.error("Error in fetching the donor's history:", error);
      setIsError(true);
      setIsLoading(false);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    //  setIsRefetching(false);
  };

  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      console.log("searchText:", searchText);

      const url = new URL(`${TQ_URL}/searchImportedDonationList`);
      url.search = new URLSearchParams();
      url.searchParams.set("start", `${pagination.pageIndex}`);
      url.searchParams.set("size", `${pagination.pageSize}`);
      url.searchParams.set("activeTab", `${activeTab}`);
      url.searchParams.set("keyword", `${searchText}`);
      url.searchParams.set("partnerId", `${partnerData.partnerId}`);
      url.searchParams.set("pageNo", 0);
      try {
        const response = await getRequestWithTokenWithoutDataPartner(
          url.toString()
        );

        //  setDonationData(response);
        console.log(response);

        console.log(response.dataList);

        const dataWithSerialNumber = response.dataList.map((item, index) => ({
          ...item,
          id: index + 1,
        }));

        console.log(response.dataList);

        setDonorData(dataWithSerialNumber);
        setRowCount(response.totalCount);

        setIsRefetching(false);
      } catch (error) {
        console.error("Error in fetching the searched donor's history:", error);
        setIsError(true);
        setIsLoading(false);
        console.error(error);
        return;
      }
    }
  };
  const handleChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleTabs = (e, value) => {
    console.log(e);
    setValue(e);
    console.log("Value:", value);
    if (e == 0) {
      setActiveTab(1);
      //  getAllDonationsDonorList(donorIdd);
      console.log("data from imported donors");
    } else {
      console.log("data from website donors");
      setActiveTab(2);
      //  getAllDonationsDonorList();
    }
  };

  const table = useMantineReactTable({
    columns,
    data: donorData,

    initialState: { showColumnFilters: false },
    rowCount,

    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onColumnFiltersChange: setColumnFilters,

    onPaginationChange: setPagination,
    onSortingChange: setSorting,

    state: {
      columnFilters,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  return (
    <>
      {/* <TopHeader /> */}
      <DonorManagementTop />
      <div className="flex">
        <div className="md:w-64 w-1/12 absolute md:relative">
          <DonorManagementSideBar />
        </div>

        <main className="w-full verify-user">
          <div className=" mx-auto shadow-md">
            <div className="md:flex justify-between container items-center px-10 md:py-6 py-6 ">
              <div className="text-center">
                <h2 className="text-2xl font-bold">Donor Inofrmation</h2>
              </div>
            </div>
          </div>

          <div
            className="flex flex-col py-8 donor-info "
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <h1 className="text-3xl font-bold md:mb-8 mb-4 md:mx-2">
              Donor #
              {rowData.importedDonorId != 0
                ? rowData.importedDonorId
                : rowData.moneyDonationDonorId}
            </h1>

            <div className="md:flex space-y-10 md:space-y-0 justify-between  md:mx-8  md:mb-8">
              <div className="counter-item flex-1">
                <p className="font-extrabold">Name:</p>
                <p>{rowData.donorName}</p>
              </div>

              <div className="counter-item flex-1">
                <p className="font-extrabold">Email:</p>
                <p>{rowData.donorEmail}</p>
              </div>

              <div className="counter-item flex-1 ">
                <p className="font-extrabold">Phone:</p>
                <p>{rowData.contactNo != null ? rowData.contactNo : "--"}</p>
              </div>

              <div className="counter-item flex-1">
                <p className="font-extrabold">Address :</p>
                <p>
                  {addressDetails.address != null
                    ? addressDetails.address
                    : "--"}
                </p>
              </div>

              <div className="counter-item flex-1">
                <p className="font-extrabold">City :</p>
                <p>
                  {addressDetails.city != null ? addressDetails.city : "--"}
                </p>
              </div>
            </div>

            <div className="md:flex space-y-10 md:space-y-0 justify-between mb:4 mt-8 md:mx-8 md:mb-4">
              <div className="counter-item flex-1">
                <p className="font-extrabold">Created At :</p>
                <p>
                  {addressDetails.createdAt != null
                    ? addressDetails.createdAt
                    : "--"}
                </p>
              </div>
              <div className="counter-item flex-1">
                <p className="font-extrabold">State :</p>
                <p>
                  {addressDetails.state != null ? addressDetails.state : "--"}
                </p>
              </div>

              <div className="counter-item flex-1 ">
                <p className="font-extrabold">Country :</p>
                <p>
                  {addressDetails.country != null
                    ? addressDetails.country
                    : "--"}
                </p>
              </div>

              <div className="counter-item flex-1">
                <p className="font-extrabold">Postal Code :</p>
                <p>
                  {addressDetails.postalCode != null
                    ? addressDetails.postalCode
                    : "--"}
                </p>
              </div>

              <div className="counter-item flex-1">
                <p className="font-extrabold">Total Donation :</p>
                <p>${rowData.donatedAmt != null ? rowData.donatedAmt : "--"}</p>
              </div>
            </div>
          </div>

          {/* *********************************** */}

          <div className="w-full flex flex-col md:flex-row items-center md:mt-4">
            <div className=" md:mx-8 rounded  p-1 flex  w-full  justify-between md:flex-grow-0 md:w-6/12">
              <input
                type="text"
                placeholder="Search..."
                className="py-2 pr-8 pl-4 block w-full rounded-lg bg-gray-200 border border-gray-300 focus:outline-none focus:ring focus:border-blue-300"
                onChange={handleChange}
                onKeyDown={handleKeyPress}
              />
            </div>
            <div className="flex mx-8 my-2">
              {/* Two buttons */}

              {actTab == 1 && (
                <Link to={`/addDonations`}>
                  <button className="btn-style-1 text-white py-1 px-1 md:py-2  md:px-4 rounded  md:block mx-2">
                    +Add Donation
                  </button>
                </Link>
              )}
            </div>
          </div>
          {/* <div className="md:mx-2 md:py-4 py-6">
            <MantineReactTable table={table} />
          </div> */}

          <div className="md:mx-8 md:py-8 py-6">
            <div sx={{ width: "100%" }}>
              <div style={{ borderBottom: "1px solid #ddd" }}>
                <Tabs
                  value={value}
                  onChange={(event, newValue) => handleTabs(newValue)}
                  aria-label="basic tabs example"
                >
                  <Tab label="Imported Donations" value={0} />
                  <Tab label="Website Doantions" value={1} />
                </Tabs>
              </div>

              <div className="md:mx-2 md:py-4 py-6">
                {value === 0 && <MantineReactTable table={table} />}
                {value === 1 && (
                  <MantineReactTable table={table} /> // Replace `table2` with your data for tab 2
                )}
              </div>
            </div>
            {/* <MantineReactTable table={table} /> */}
          </div>

          {/* ******************************************** */}
        </main>
      </div>
    </>
  );
};
