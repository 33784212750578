import React, { useState, useEffect, useRef } from "react";
import TopHeader from "../Home/TopHeader";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { Menu } from "@mantine/core";
import AddCausePopup from "./AddCausePopup";
import { ActionIcon, Box, Button } from "@mantine/core";
import { IconEdit, IconSend, IconTrash } from "@tabler/icons-react";
import { MRT_ColumnDef } from "mantine-react-table";
import {
  getRequestWithTokenWithData,
  getRequestWithTokenWithoutData,
  patchRequestWithTokenWithData,
} from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import { async } from "q";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditCause from "./EditCause";

const columns = [
  {
    accessorKey: "id",
    header: "Sr. No",
  },
  {
    accessorKey: "causeName",
    header: "Cause",
  },
];

const ViewCause = () => {
  const [isAddCausePopupOpen, setIsAddCausePopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState([]);
  const isMounted = useRef(true);
  const [config, setConfig] = useState({});

  const [editPopupOpen, setEditPopupOpen] = useState({
    isOpen: false,
    rowData: null,
  });

  useEffect(() => {
    fetch("/config.json")
      .then((response) => response.json())
      .then((data) => {
        setConfig(data);
        console.log(data.partnerId);
      })
      .catch((error) => console.error("Error loading config.json:", error));
  }, []);

  useEffect(() => {
    if (isMounted.current && config.partnerId) {
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      getAllCauseList();
      isMounted.current = false;
    }
  }, [config]);
  

  const getAllCauseList = async () => {
    const partnerId = config.partnerId;
    console.log(partnerId);
    if (!partnerId) {
      throw new Error("Partner ID is not defined");
    }
    try {
      const response = await getRequestWithTokenWithoutData(
        `${TQ_URL}/getCauseList?partnerId=${partnerId}`
      );
      console.log("CauseData:");
      console.log(response);

      const dataWithSerialNumber = response.data.map((item, index) => ({
        ...item,
        id: index + 1,
      }));

      setData(dataWithSerialNumber);

      //  setLoading(false);
    } catch (error) {
      console.error("Error in fetching the userData:", error);
      setIsError(true);
      setIsLoading(false);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  const updateRowInData = (updatedRow) => {
    setData((prevData) =>
      prevData.map((row) =>
        row.fundraiserId === updatedRow.fundraiserId ? updatedRow : row
      )
    );
  };

  const handleModalClose = (newCause) => {
    setIsAddCausePopupOpen(false);
    if (newCause) {
      //  setData(prevCauses => [...prevCauses, newCause]);

      const newCauseWithId = {
        ...newCause,
        id: data.length + 1, // Generate id based on the length of the data array
      };

      // Update data state with the newCause object
      setData((prevData) => [...prevData, newCauseWithId]);
    }
  };

  const table = useMantineReactTable({
    columns,
    data,
    enableRowActions: true,
    isLoading,
    positionActionsColumn: "last",
    state: {
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Error loading data" }
      : undefined,
    renderRowActions: ({ row }) => (
      <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
        <ActionIcon
          color="orange"
          onClick={() => {
            console.log(row);
            setEditPopupOpen({
              isOpen: true,
              rowData: row.original,
            });
          }}
        >
          {/* <IconEdit /> */}
        </ActionIcon>
        <ActionIcon
          color="red"
          onClick={async () => {
            const newData = [...data];
            newData.splice(row.index, 1);
            setData(newData);
            console.log(row.original.causeId);

            const response = await patchRequestWithTokenWithData(
              `${TQ_URL}/deleteCauseType`,
              {
                causeId: row.original.causeId,
              }
            );
            console.log(response);

            toast.success("Cause Deleted successfully");
          }}
        >
          <IconTrash />
        </ActionIcon>
      </Box>
    ),
  });

  return (
    <div>
      <TopHeader />
      <div className="flex">
        <div className=""></div>
        <main className="w-full verify-user">
          <div className=" mx-auto shadow-md">
            <div className="md:flex justify-between container items-center px-10 md:py-6 py-6 ">
              <div className="md:text-left text-center">
                <h2 className="text-2xl font-bold">List of Causes</h2>
                {/* <p className="text-black font-medium md:leading-10 mt-4 md:mt-0">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p> */}
              </div>
            </div>
          </div>
          <div className="flex justify-end container mx-auto pt-10">
            <button
              className="btn-style-3 !rounded-md px-6 py-2 me-8 md:me-0"
              onClick={() => setIsAddCausePopupOpen(true)}
            >
              + Add Cause
            </button>
          </div>
          <div className="md:px-10 md:py-16 py-8 container mx-auto">
            <MantineReactTable table={table} />
          </div>
          {isAddCausePopupOpen && (
            <AddCausePopup
              isOpen={isAddCausePopupOpen}
              // onClose={() => setIsAddCausePopupOpen(false)}
              onClose={handleModalClose}
            />
          )}
        </main>

      
        <ToastContainer theme="colored" />
      </div>
    </div>
  );
};

export default ViewCause;
