import React, { useState, useEffect } from "react";
import TopHeader from "../Home/TopHeader";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import {
  ActionIcon,
  Box,
  Button,
  Loader,
  MantineProvider,
} from "@mantine/core";

import { Link } from "react-router-dom";
import { IconTrash } from "@tabler/icons-react";

import HistorySidebar from "../Home/HistorySidebar";
import ReceiptPopup from "../Common/ReceiptPopup";
import { getRequestWithOutToken } from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import axios from "axios";

const columns = [
  {
    accessorKey: "id",
    header: "ID",
  },
  {
    accessorKey: "fundraiserName",
    header: "Fundraiser Name",
  },
  {
    accessorKey: "totalMoneyRaised",
    header: "Total Money Raised",
  },
  {
    accessorKey: "targetAmount",
    header: "Target Amount",
  },
  {
    accessorKey: "remainingAmount",
    header: "Ramining Amount",
  },
];

const initialData = [
  {
    orgIndividualName: "John Doe",
    totalMoneyRequired: "$30,000",
    targetDate: "2023-12-31",
    donationAmount: "$10,000",
  },
  {
    orgIndividualName: "Jane Smith",
    totalMoneyRequired: "$25,000",
    targetDate: "2023-11-30",
    donationAmount: "$8,500",
  },
  {
    orgIndividualName: "Robert Johnson",
    totalMoneyRequired: "$40,000",
    targetDate: "2023-10-31",
    donationAmount: "$15,000",
  },
  {
    orgIndividualName: "Sarah Williams",
    totalMoneyRequired: "$35,000",
    targetDate: "2023-09-30",
    donationAmount: "$12,000",
  },
  {
    orgIndividualName: "Michael Davis",
    totalMoneyRequired: "$20,000",
    targetDate: "2023-08-31",
    donationAmount: "$9,000",
  },
  {
    orgIndividualName: "Emma Wilson",
    totalMoneyRequired: "$15,000",
    targetDate: "2023-07-31",
    donationAmount: "$7,500",
  },
  {
    orgIndividualName: "Daniel Brown",
    totalMoneyRequired: "$10,000",
    targetDate: "2023-06-30",
    donationAmount: "$5,000",
  },
  // Add more data as needed
];

const AllFundraisers = () => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [data, setData] = useState([]);

  useEffect(() => {
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    getAllTransaction();
  }, [
    pagination.pageIndex, //refetch when page index changes
    pagination.pageSize, //refetch when page size changes
  ]);

  const getAllTransaction = async () => {
    const url = new URL(`${TQ_URL}/allFundraisers`);
    url.search = new URLSearchParams();
    url.searchParams.set("start", `${pagination.pageIndex}`);
    url.searchParams.set("size", `${pagination.pageSize}`);
    try {
      const response = await getRequestWithOutToken(url.toString());

      console.log(response);
      const dataWithSerialNumber = response.datalist.map((item, index) => ({
        ...item,
        id: index + 1,
      }));

      setData(dataWithSerialNumber);
      //setData(response.datalist);
      setRowCount(response.totalCount);
    } catch (error) {
      console.error("Error in fetching All Trasactions:", error);
      setIsError(true);
      setIsLoading(false);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  const table = useMantineReactTable({
    columns,
    data,
    initialState: { showColumnFilters: false },
    rowCount,

    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Error loading data" }
      : undefined,
  });

  return (
    <div>
      <TopHeader />
      <div className="flex">
        <div className=""></div>
        <main className="w-full verify-user ">
          <div className="mx-auto shadow-md">
            <div className="md:flex justify-between  items-center  md:ps-32 ">
              {/* <div className="text-left">
                <h2 className="text-2xl font-bold">
                  Checkout the list of all Fundraisers
                </h2>
              </div> */}
              {/* <div className="text-right bg-gray-200 px-4 md:px-20 py-6">
                <p className="text-black font-medium leading-10">
                  Total Donation Amount Left:
                </p>
                <p className="text-black text-2xl font-bold">4000$</p>
              </div> */}

              <div className="text-left flex items-center">
                <Link to="/money-details">
                  <img
                    src="/assets/images/main-logo.svg"
                    alt="Logo"
                    className="h-10 px-6 my-5 sm:mx-0"
                  />
                </Link>
                <h2 className="text-2xl font-bold">
                  Checkout the list of all Fundraisers
                </h2>
              </div>
            </div>
          </div>
          <div className="flex justify-end container mx-auto md:pt-10"></div>
          <div className="md:px-10 py-16 container mx-auto">
            <MantineReactTable table={table} />
          </div>
        </main>
      </div>
    </div>
  );
};

export default AllFundraisers;
