import React, { useState } from 'react';
import TopHeader from '../Home/TopHeader';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { Box } from '@mantine/core';

const columns = [
  {
    accessorKey: 'orgIndividualName',
    header: 'Org/Individual Name',
  },
  {
    accessorKey: 'cause',
    header: 'Cause',
  },
  {
    accessorKey: 'totalMoneyReimbursed',
    header: 'Total Money Reimbursed',
  },
  {
    accessorKey: 'reimbursedDate',
    header: 'Reimbursed Date',
  },
];

const initialData = [
  {
    orgIndividualName: 'John Doe',
    cause: 'Cancer',
    totalMoneyReimbursed: '$10,000',
    reimbursedDate: '2023-12-31',
  },
  {
    orgIndividualName: 'Jane Smith',
    cause: 'Animal Welfare',
    totalMoneyReimbursed: '$8,500',
    reimbursedDate: '2023-11-30',
  },
  {
    orgIndividualName: 'Robert Johnson',
    cause: 'Environmental Conservation',
    totalMoneyReimbursed: '$15,000',
    reimbursedDate: '2023-10-31',
  },
  {
    orgIndividualName: 'Sarah Williams',
    cause: 'Education',
    totalMoneyReimbursed: '$12,000',
    reimbursedDate: '2023-09-30',
  },
  {
    orgIndividualName: 'Michael Davis',
    cause: 'Healthcare',
    totalMoneyReimbursed: '$9,000',
    reimbursedDate: '2023-08-31',
  },
  {
    orgIndividualName: 'Emma Wilson',
    cause: 'Disaster Relief',
    totalMoneyReimbursed: '$7,500',
    reimbursedDate: '2023-07-31',
  },
  {
    orgIndividualName: 'Daniel Brown',
    cause: 'Poverty Alleviation',
    totalMoneyReimbursed: '$5,000',
    reimbursedDate: '2023-06-30',
  },
  // Add more data as needed
];

const TotalMoney = () => {
  const [isAddCausePopupOpen, setIsAddCausePopupOpen] = useState(false);
  const [data, setData] = useState(initialData);

  const table = useMantineReactTable({
    columns,
    data,
  });

  return (
    <div>
      <TopHeader />
      <div className="flex">
        <div className=""></div>
        <main className='w-full verify-user'>
          <div className=' mx-auto shadow-md'>
            <div className='md:flex justify-between container items-center px-10 md:py-6 py-6 '>
              <div className='text-left'>
                <h2 className="text-2xl font-bold">List Of Total money reimbursed to Specific Organizations/users for Specific Cause
                </h2>
                {/* <p className="text-black font-medium md:leading-10 mt-4 md:mt-0">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </p> */}
              </div>
            </div>
          </div>
          <div className='flex justify-end container mx-auto md:pt-10'>
          </div>
          <div className="md:px-10 py-16 container mx-auto">
            <MantineReactTable table={table} />
          </div>
        </main>
      </div>
    </div>
  );
};

export default TotalMoney;
