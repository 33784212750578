// import React from 'react'

// const ComingSoon = () => {
//     return (
//         <div className='flex justify-center items-center min-h-screen'>
//             <h2 className='text-5xl font-semibold'>
//                 This Page is Coming Soon
//             </h2>
//         </div>
//     )
// }

// export default ComingSoon
import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
  email: yup.string().email().required("Email bhar bhai "),
  password: yup.string().required("Email bhi bhar"),
  address: yup.string().required("Address is Required"),
  // age: yup.number().required("age is Required"),
  age: yup.number()
    .typeError("CUSTOM MESSAGE")
    .positive("Age must be a positive number")
    .integer("Age must be an integer")
    .required("Age is required"),
});

const App = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = (data) => {
    console.log({ data });
    reset();
  };
  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <h2>Lets sign you in.</h2>
      <br />

      <input {...register("email")} placeholder="email" type="email" />
      <p>{errors.email?.message}</p>
      <br />

      <input {...register("address")} placeholder="Address" type="text" />
      <p>{errors.address?.message}</p>
      <br />

      <input {...register("age")} placeholder="Age" type="text" />
      <p>{errors.age?.message}</p>
      <br />

      <input
        {...register("password")}
        placeholder="password"
        type="password"

      />
      <p>{errors.password?.message}</p>
      <br />

      <button type="submit">Sign in</button>
    </form>
  );
};

export default App;
