import React, { useState, useRef, useEffect } from "react";
import TopHeader from "../Home/TopHeader";

import ReactPlayer from "react-player";

import SocialMediaSideBar from "./SocialMediaSideBar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThumbsUp,
  faComment,
  faShare,
  faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import * as yup from "yup";

import OutlinedInput from "@mui/material/OutlinedInput";

import { useLocation, useNavigate, Link } from "react-router-dom";
import "react-big-calendar/lib/css/react-big-calendar.css";

import AOS from "aos";
import "aos/dist/aos.css";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const connectedPage = "Prabalta";

const schema = yup.object().shape({
  document: yup.mixed().required("Document is Required *"),

  description: yup.string().required("Description is Required *"),
});

const CreatePost = () => {
  const [selectedValues, setSelectedValues] = useState([]);
  const [isDragOver, setIsDragOver] = useState(false);

  const [documentImage, setDocumentImage] = useState(null);

  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [uploadReelFile, setUploadReelFile] = useState(null);

  const [selectedOption, setSelectedOption] = useState("post");

  const [videoUrl, setVideoUrl] = useState(null);

  const [loading, setLoading] = useState(false);
  const [textValue, setTextValue] = useState("");

  // let singleVideo;

  // if (uploadReelFile) {
  //   singleVideo = {
  //     src: URL.createObjectURL(uploadReelFile),
  //     title: "Video 1",
  //     thumbnail: "https://www.example.com/video1-thumbnail.jpg",
  //   };
  // }

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const tags = [
    {
      tag_id: "1",
      tag_name: "#NonProfit",
    },
    {
      tag_id: "2",
      tag_name: "#Volunteer",
    },
    {
      tag_id: "3",
      tag_name: "#CommunityService",
    },
    {
      tag_id: "4",
      tag_name: "#Charity",
    },
    {
      tag_id: "5",
      tag_name: "#Humanitarian",
    },
  ];

  useEffect(() => {
    AOS.init();
  }, []);

  const handleTextChange = (event) => {
    console.log("text:", event.target.value);
    setTextValue(event.target.value); // Update state with the input value
  };

  const handleFileChange = (event) => {
    const files = event.target.files;

    console.log(files);

    const existingFiles = uploadedFiles;

    const totalFiles = existingFiles.length + files.length;

    if (totalFiles > 10) {
      toast.error(
        `You can only upload a maximum of 10 files. You tried to upload ${files.length} more files.`
      );
      event.target.value = null;

      return;
    }

    const file = event.target.files[0];

    if (file) {
      const fileSizeInMegabytes = file.size / (1024 * 1024);
      const maxSize = 20;

      if (fileSizeInMegabytes > maxSize) {
        toast.error(
          `${file.name} File size exceeds the maximum limit of ${maxSize} MB`
        );
        event.target.value = null;
        return;
      }
    }

    if (files.length > 0) {
      const newFiles = Array.from(files);

      console.log("New files:", newFiles);

      setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
      newFiles.forEach((file) => {
        console.log("File added:", file.name);
      });
    }
  };

  const handleReelFileChange = (event) => {
    const files = event.target.files;

    console.log(files);

    const file = event.target.files[0];

    const url = URL.createObjectURL(file);
    setVideoUrl(url);

    setUploadReelFile(file);
  };

  const removeFile = (index) => {
    setUploadedFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      console.log("File removed:", prevFiles[index].name);
      return newFiles;
    });
  };

  const removeReelFile = () => {
    setUploadReelFile(null);
    setVideoUrl(null);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);

    const file = e.dataTransfer.files[0];
    if (file) {
      setValue("document", file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setDocumentImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const onSubmitHandler = async (data, event) => {
    console.log("Submit Clicked", data);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    const uniqueValues = [...new Set(value)];

    setSelectedValues(uniqueValues);
  };

  const groupImages = (images) => {
    const groupedImages = [];
    let tempGroup = [];

    const totalImages = images.length;

    if (totalImages >= 5) {
      tempGroup = images.slice(0, 2);
      groupedImages.push(tempGroup);
      tempGroup = images.slice(2, 5);
      groupedImages.push(tempGroup);

      return groupedImages;
    }

    images.forEach((image, index) => {
      tempGroup.push(image);

      if (
        (index % 5 === 0 && tempGroup.length === 1) ||
        (index % 5 === 1 && tempGroup.length === 2) ||
        (index % 5 === 3 && tempGroup.length === 2)
      ) {
        groupedImages.push(tempGroup);
        tempGroup = [];
      } else if (tempGroup.length === 3) {
        groupedImages.push(tempGroup);
        tempGroup = [];
      }
    });

    if (tempGroup.length) {
      groupedImages.push(tempGroup);
    }

    return groupedImages;
  };

  const renderImageRows = (groupedImages) => {
    console.log(groupedImages);

    const totalImageCount = uploadedFiles.length;

    return groupedImages.map((group, index) => {
      const groupSize = group.length;
      const divWidth = 100;
      const imageWidthPercentage = divWidth / groupSize;

      return (
        <div
          key={index}
          className={`flex w-full h-[150px] space-x-2 my-2 relative`}
        >
          {group.map((file, i) => {
            const isLastImage =
              index === groupedImages.length - 1 && i === group.length - 1;

            const shouldBlurLastImage = totalImageCount > 5 && isLastImage;

            const isFifthImage =
              index === groupedImages.length - 1 && i === group.length - 1;

            const shouldBlurFifthImage = totalImageCount >= 6 && isFifthImage;
            const extraImageCount = totalImageCount - 5;

            console.log(isFifthImage);
            console.log(shouldBlurFifthImage);

            return (
              <div key={i} className="flex-1 mx-1">
                <img
                  src={URL.createObjectURL(file)}
                  alt={file.name}
                  style={{
                    width: "100%",
                    height: "100%",

                    filter: shouldBlurFifthImage ? "blur(2px)" : "none",
                  }}
                  className="object-cover"
                />

                {shouldBlurFifthImage && (
                  <div className="counter-text">+{extraImageCount}</div>
                )}
              </div>
            );
          })}
        </div>
      );
    });
  };

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <>
      <TopHeader />
      <div className="flex">
        <div className="md:w-64 w-1/12 absolute md:relative">
          <SocialMediaSideBar />
        </div>

        <main className="w-full verify-user">
          <div className=" mx-auto shadow-md">
            <div className="md:flex justify-between container items-center px-10 md:py-6 py-6">
              <div className="text-center">
                <h2 className="text-2xl font-bold">Create Post</h2>
              </div>
            </div>
          </div>

          <div className="m-0 md:flex ">
            <div
              className="md:w-3/4 border border-gray-300 md:mx-4 p-5 flex flex-col justify-center"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="m-0 md:flex ">
                <div className="md:w-3/4 space-x-6 items-center me-10 md:flex">
                  <a
                    href="https://www.facebook.com/profile.php?id=61557894729486"
                    target="blank"
                  >
                    <svg
                      className="w-6"
                      fill="#000"
                      viewBox="0 0 22 32"
                      xmlns="http://www.w3.org/2000/svg"
                      stroke="#000"
                      stroke-width="0.00032"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <path d="M21.95 5.005l-3.306-.004c-3.206 0-5.277 2.124-5.277 5.415v2.495H10.05v4.515h3.317l-.004 9.575h4.641l.004-9.575h3.806l-.003-4.514h-3.803v-2.117c0-1.018.241-1.533 1.566-1.533l2.366-.001.01-4.256z"></path>
                      </g>
                    </svg>
                  </a>

                  <a href="https://twitter.com/PrabaltaOrg" target="_blank">
                    <svg
                      className="w-6 "
                      viewBox="0 -2 20 20"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      fill="#000000"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <title>twitter [#154]</title>{" "}
                        <desc>Created with Sketch.</desc> <defs> </defs>{" "}
                        <g
                          id="Page-1"
                          stroke="#000"
                          stroke-width="1"
                          fill="#000"
                          fill-rule="evenodd"
                        >
                          {" "}
                          <g
                            id="Dribbble-Light-Preview"
                            transform="translate(-60.000000, -7521.000000)"
                            fill="#000"
                          >
                            {" "}
                            <g
                              id="icons"
                              transform="translate(56.000000, 160.000000)"
                            >
                              {" "}
                              <path
                                d="M10.29,7377 C17.837,7377 21.965,7370.84365 21.965,7365.50546 C21.965,7365.33021 21.965,7365.15595 21.953,7364.98267 C22.756,7364.41163 23.449,7363.70276 24,7362.8915 C23.252,7363.21837 22.457,7363.433 21.644,7363.52751 C22.5,7363.02244 23.141,7362.2289 23.448,7361.2926 C22.642,7361.76321 21.761,7362.095 20.842,7362.27321 C19.288,7360.64674 16.689,7360.56798 15.036,7362.09796 C13.971,7363.08447 13.518,7364.55538 13.849,7365.95835 C10.55,7365.79492 7.476,7364.261 5.392,7361.73762 C4.303,7363.58363 4.86,7365.94457 6.663,7367.12996 C6.01,7367.11125 5.371,7366.93797 4.8,7366.62489 L4.8,7366.67608 C4.801,7368.5989 6.178,7370.2549 8.092,7370.63591 C7.488,7370.79836 6.854,7370.82199 6.24,7370.70483 C6.777,7372.35099 8.318,7373.47829 10.073,7373.51078 C8.62,7374.63513 6.825,7375.24554 4.977,7375.24358 C4.651,7375.24259 4.325,7375.22388 4,7375.18549 C5.877,7376.37088 8.06,7377 10.29,7376.99705"
                                id="twitter-[#154]"
                              >
                                {" "}
                              </path>{" "}
                            </g>{" "}
                          </g>{" "}
                        </g>{" "}
                      </g>
                    </svg>
                  </a>
                </div>

                <div className="md:w-1/2 space-x-6 items-center me-10 md:flex">
                  {tags.length > 0 ? (
                    <FormControl className="bg-gray-50 border border-gray-300 text-black-900 font-semibold text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block md:mt-7 w-full p-2.5">
                      <InputLabel
                        style={{ fontWeight: "bold" }}
                        className="text-black-900 font-semibold text-sm"
                        id="demo-multiple-checkbox-label"
                      >
                        Select tags
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={selectedValues}
                        onChange={handleChange}
                        input={<OutlinedInput label="Select Tags" />}
                        renderValue={(selected) =>
                          selectedValues.map((x) => x.tag_name).join(", ")
                        }
                        MenuProps={MenuProps}
                      >
                        {tags.map((variant) => (
                          <MenuItem
                            key={variant.tag_id}
                            value={variant}
                            style={{
                              padding: "5px",
                              fontSize: "10px",
                              margin: "5px",
                            }}
                          >
                            <Checkbox
                              checked={selectedValues.includes(variant)}
                              style={{
                                fontSize: "10px",
                                margin: "5px",
                              }}
                            />
                            <ListItemText primary={variant.tag_name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <div className="w-full flex items-center justify-between">
                      <span>No users available.</span>

                      <button className="btn-style-1 text-white py-1 px-1 md:py-1  md:px-2 rounded  md:block mx-2">
                        Add Tags
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <hr className="border-t border-gray-400 mt-4" />
              <form
                onSubmit={handleSubmit(onSubmitHandler)}
                className="fundraiser-for-other-form md:py-12 md:px-20 p-6 md:mx-8"
              >
                <div className="p-4">
                  <div className="mb-4">
                    <div className="flex items-center space-x-8">
                      {" "}
                      <div className="flex items-center">
                        <input
                          type="radio"
                          id="post"
                          name="radioOptions"
                          value="post"
                          checked={selectedOption === "post"}
                          onChange={handleRadioChange}
                          className="mr-2"
                        />
                        <label htmlFor="post" className="text-gray-700">
                          Post
                        </label>
                      </div>
                      {/* Radio button 2 */}
                      <div className="flex items-center">
                        <input
                          type="radio"
                          id="reel"
                          name="radioOptions"
                          value="reel"
                          checked={selectedOption === "reel"}
                          onChange={handleRadioChange}
                          className="mr-2"
                        />
                        <label htmlFor="reel" className="text-gray-700">
                          Reel
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <hr className="border-t border-gray-400 mt-4" />

                <div className="w-full md:mt-4">
                  <textarea
                    rows={6}
                    value={textValue}
                    onChange={handleTextChange}
                    style={{ resize: "none" }}
                    placeholder="Start writing here..."
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                      errors.description && "border-red-500"
                    }`}
                  />
                  <p className="text-red-500 text-sm mt-1">
                    {errors.description?.message}
                  </p>
                </div>
                {selectedOption == "post" ? (
                  <div className="md:flex">
                    <div
                      className="flex justify-between w-full items-center  my-5 md:my-0"
                      onDragOver={handleDragOver}
                      onDragLeave={handleDragLeave}
                      onDrop={handleDrop}
                    >
                      <div
                        className="mb-6 relative flex flex-col items-center w-full"
                        style={{
                          borderColor: isDragOver ? "blue" : "gray",
                          boxShadow: isDragOver
                            ? "0 0 10px rgba(0, 0, 255, 0.5)"
                            : "none",
                          borderRadius: "8px",

                          borderStyle: "dashed",
                          borderWidth: "2px",
                          paddingInline: "20px",
                          paddingBlock: "10px",
                          cursor: "pointer",
                          marginTop: "10px",
                        }}
                      >
                        {uploadedFiles.map((file, index) => (
                          <div key={index} className="flex items-center mb-2">
                            <p className="mr-2">{file.name}</p>
                            <button
                              type="button"
                              className="text-red-500 hover:text-red-700 cursor-pointer"
                              onClick={() => removeFile(index)}
                            >
                              &#x2715;
                            </button>
                          </div>
                        ))}

                        <label
                          htmlFor="documentInput"
                          className="cursor-pointer"
                        >
                          <img
                            src="assets/images/cloud-icon.svg"
                            className="mx-auto"
                            alt="Cloud Icon"
                          />
                          <p className="text-xs text-center">
                            <span className="font-bold underline">
                              Click to upload
                            </span>
                            or drag and drop <br />
                            PNG, JPG, JPEG,
                          </p>
                        </label>
                        <input
                          {...register("document")}
                          type="file"
                          accept=".jpg, .jpeg, .png"
                          onChange={handleFileChange}
                          className="hidden"
                          id="documentInput"
                          multiple
                        />
                      </div>
                    </div>

                    <p className="text-red-500 text-sm mt-1">
                      {errors.document?.message}
                    </p>
                  </div>
                ) : (
                  <div
                    className="flex justify-between w-full items-center  my-5 md:my-0"
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                  >
                    <div
                      className="mb-6 relative flex flex-col items-center w-full"
                      style={{
                        borderColor: isDragOver ? "blue" : "gray",
                        boxShadow: isDragOver
                          ? "0 0 10px rgba(0, 0, 255, 0.5)"
                          : "none",
                        borderRadius: "8px",
                        borderStyle: "dashed",
                        borderWidth: "2px",
                        paddingInline: "20px",
                        paddingBlock: "10px",
                        cursor: "pointer",
                      }}
                    >
                      {uploadReelFile && (
                        <div className="flex items-center mb-2">
                          {/* Display the name of the uploaded file */}
                          <p className="mr-2">{uploadReelFile.name}</p>

                          {/* Button to remove the file */}
                          <button
                            type="button"
                            className="text-red-500 hover:text-red-700 cursor-pointer"
                            // onClick={() => removeFile()} // Call removeFile without an index since there is only one file
                            onClick={removeReelFile}
                          >
                            &#x2715; {/* Unicode symbol for a cross */}
                          </button>
                        </div>
                      )}

                      <label htmlFor="documentInput" className="cursor-pointer">
                        <img
                          src="assets/images/cloud-icon.svg"
                          className="mx-auto"
                          alt="Cloud Icon"
                        />
                        <p className="text-xs text-center">
                          <span className="font-bold underline">
                            Click to upload Reel
                          </span>
                        </p>
                      </label>
                      <input
                        {...register("document")}
                        type="file"
                        accept=".mp4"
                        onChange={handleReelFileChange}
                        className="hidden"
                        id="documentInput"
                      />
                    </div>
                  </div>
                )}

                <div className="mb-6 md:flex justify-center">
                  <button
                    type="submit"
                    className="w-1/3 text-white p-2 rounded-md btn-style-1 mt-4 "
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>

            {/* *************************************************** */}

            <div
              className="md:w-1/2 md:mx-4 md:mt-8 md:mx-8"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div
                style={{
                  border: "2px solid #D3D3D3",
                  backgroundColor: "white",
                  padding: "6px 7px",
                  borderRadius: "4px",
                  height: "auto",
                  width: "400px",
                }}
              >
                <h2 className="text-lg font-bold">Facebook Preview</h2>

                <div
                  className={`md:mx-2 p-4 border border-gray-300 ${
                    textValue || uploadedFiles.length || uploadReelFile
                      ? ""
                      : "hidden"
                  }`}
                >
                  {selectedOption == "post" ? (
                    <div>
                      <div className="w-auto bg-gray  text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block break-words">
                        {" "}
                        {textValue}
                      </div>
                      <div className="w-full">
                        <div>{renderImageRows(groupImages(uploadedFiles))}</div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="w-full h-[500px]">
                        <div className="video-reel-container">
                          <div className="reel-view">
                            <div className="video-container">
                              {videoUrl && (
                                <ReactPlayer
                                  url={videoUrl}
                                  playing={true}
                                  loop={true}
                                  controls={false}
                                  width="340px"
                                  height="600px"
                                  muted={true}
                                  style={{ objectFit: "cover" }}
                                />
                              )}

                              <div className="reel-icons">
                                <button className="icon-button">
                                  <FontAwesomeIcon icon={faThumbsUp} />
                                </button>
                                <button className="icon-button">
                                  <FontAwesomeIcon icon={faComment} />
                                </button>
                                <button className="icon-button">
                                  <FontAwesomeIcon icon={faShare} />
                                </button>
                                <button className="icon-button">
                                  <FontAwesomeIcon icon={faEllipsisH} />
                                </button>
                              </div>
                              <div className="channel-name-overlay">
                                <div className="channel-info">
                                  <img
                                    src={
                                      "https://social.prabalta.org/assets/images/user.webp"
                                    }
                                    alt="Placeholder Avatar"
                                    className="rounded-full h-8 w-8"
                                  />

                                  <span className="font-semibold text-gray-800">
                                    {connectedPage}
                                  </span>
                                </div>

                                <div className="w-auto md:ml-2 text-sm focus:ring-blue-500 focus:border-blue-500 block break-words">
                                  {" "}
                                  {textValue.length > 30
                                    ? textValue.slice(0, 30) + "...more"
                                    : textValue}
                                </div>
                                {/* <div className="text-value">{textValue}</div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <ToastContainer theme="colored" />
    </>
  );
};

export default CreatePost;
