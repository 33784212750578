import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import {
  getRequestWithTokenWithData,
  getRequestWithTokenWithoutData,
} from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ForgetPassword = () => {
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm();

  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [email, setEmail] = useState("");
  const [config, setConfig] = useState({});

  const [verifiedMsg, setVerifiedMsg] = useState("");

  const [captchaResponse, setCaptchaResponse] = useState(null);

  const handleCaptchaResponseChange = (response) => {
    setCaptchaResponse(response);
    setVerifiedMsg("");
  };

  useEffect(() => {
    fetch("/config.json")
      .then((response) => response.json())
      .then((data) => {
        setConfig(data);
      })
      .catch((error) => console.error("Error loading config.json:", error));
  }, []);

  const handleTryAgain = () => {
    setEmail("");
    setFormSubmitted(false);
  };

  const handleSave = async (data) => {
    try {
      if (!captchaResponse) {
        setVerifiedMsg("Please complete the reCAPTCHA verification.");
        //  toast.error("Please complete the reCAPTCHA verification.");
        return;
      }
      setEmail(data.email);
      setVerifiedMsg("");
      const currentURL = window.location.origin;
      console.log("Current URL:", currentURL);
      const response = await getRequestWithTokenWithData(
        `${TQ_URL}/sendResetPasswordLink`,
        {
          email: data.email,
          path: `${currentURL}/changepassword`,
          partnerId: config.partnerId,
        }
      );
      console.log(response);

      if (response.url != "") setFormSubmitted(true);

      toast.success("Reset Password Link send To your email.", {
        position: "top-right",
      });
    } catch (error) {
      console.error(
        "This Email does not exits in our database. Please try with another email",
        error.response.data.message
      );
      toast.error("This Email does not exits in our database. Please try with another email", {
        position: "top-right",
      });
    }
  };

  return (
    <div className="flex min-h-screen">
      {/* Left Section */}
      <div className="forget-pw-bg flex-1 bg-cover bg-center relative fade-in-left hidden md:block">
        <div className="absolute inset-0 bg-black opacity-40"></div>
        <div className="absolute text-left m-20">
          <Link to="/">
            <img
              src="assets/images/main-logo.svg"
              alt="Logo"
              className="h-10 z-10"
            />
          </Link>
        </div>
        <div className="absolute inset-0 flex flex-col items-start justify-start md:px-20 px-5 mt-48">
          <h3 className="text-white txt-style-2 mb-5">Unlock Hope:</h3>
          <h3 className="txt-style-1 mb-16">
            Reset Your Password
            <br />& Reconnect with Our Mission
          </h3>
        </div>
      </div>

      {/* Right Section */}
      <div className="flex-1 flex items-center justify-center p-10 fade-in-right  fade-in-right">
        {!isFormSubmitted && (
          <div className="max-w-lg w-full">
            <h1 className="txt-style-3 text-center">FORGOT PASSWORD</h1>
            {/* Email Field */}
            <form onSubmit={handleSubmit(handleSave)}>
              <div className="mb-6">
                <Controller
                  control={control}
                  name="email"
                  rules={{
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Invalid email address",
                    },
                  }}
                  render={({ field }) => (
                    <div>
                      <input
                        {...field}
                        type="email"
                        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                          errors.email && "border-red-500"
                        }`}
                        placeholder="Enter Email ID"
                        required
                      />
                      {errors.email && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.email.message}
                        </p>
                      )}
                    </div>
                  )}
                />
              </div>

              <ReCAPTCHA
                sitekey="6LeaQ4QpAAAAACwPHgef7yz2B3tgaNqtNwsevXi9"
                onChange={handleCaptchaResponseChange}
              />
              <span className="text-red-500 text-sm mt-1">{verifiedMsg}</span>

              {/* Send Link Button */}
              <div className="flex justify-center mt-10">
                {/* <Link to="/changepassword"> */}
                <button
                  type="submit"
                  className={`w-full text-white p-2 px-6 rounded-md  btn-style-1 ${
                    !isValid && "opacity-50 cursor-not-allowed"
                  }`}
                  disabled={!isValid}
                >
                  Send Link
                </button>
                {/* </Link> */}
              </div>
            </form>

            <ToastContainer theme="colored" />
          </div>
        )}

        {isFormSubmitted && (
          <div className="max-w-lg w-full">
            <h1 className="txt-style-5 text-center">Reset Your Password</h1>
            {/* Email Field */}
            <br />
            <div>
              <p className="text-green text-center font-medium md:mb-10 md:leading-8 leading-6 text-lg">
                <b>
                  {" "}
                  We sent a reset password email to {email}. Please click the
                  reset password link to set your new password.
                </b>
              </p>
              {/* Add any additional content or message here */}
            </div>
            <p className="text-green text-center font-medium md:mb-10 md:leading-8 leading-6">
              Haven't received the email?
            </p>

            <p className="text-green text-center font-medium md:mb-10 md:leading-8 leading-6">
              Please check your spam folder, or{" "}
              <a
                href="#"
                className="underline text-blue-500"
                onClick={handleTryAgain}
              >
                try again
              </a>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgetPassword;
