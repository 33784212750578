import React, { useEffect, useState } from "react";
import { Triangle } from "react-loader-spinner";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Header from "../Home/Header";
import ProgressBar from "../Common/ProgressBar";
import Footer from "../Home/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { DatePickerInput } from "@mantine/dates";
import Slider from "react-slick";
import { TQ_URL } from "../../utility/baseUrl";

const schema = yup.object().shape({
  document: yup.mixed().required("Document is Required *"),
  description: yup.string().required("Description is Required *"),
  title: yup.string().required("Title is required"),
  fundraiserGoal: yup
    .number()
    .required("Fundraiser goal is required")
    .positive("Fundraiser goal should be positive or non-zero")
    .typeError("Fundraiser goal Amount should not be Empty"),
  targetDate: yup.date("Invalid date").required("Target date is required"),
  termsAndConditions: yup
    .boolean()
    .oneOf([true], "Please accept the terms and conditions"),
});

const Self = () => {
  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const [causeList, setCauseList] = useState(false);
  const [isImageHover, setIsImageHover] = useState(false);
  const { state } = useLocation();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [filePreviews, setFilePreviews] = useState([]);
  const [showSliderModal, setShowSliderModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetch("/config.json")
      .then((response) => response.json())
      .then((data) => {
        setConfig(data);
        console.log(data.partnerId);
      })
      .catch((error) => console.error("Error loading config.json:", error));
  }, []);

  useEffect(() => {
    const value1 = state?.selectedCauses || "defaultValue1";
    setCauseList(value1);
    console.log("selected causes:", value1);
  }, [state]);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // const handleFileChange = (event) => {
  //   const files = event.target.files;

  //   if (files.length > 0) {
  //     const newFiles = Array.from(files);
  //     const newPreviews = newFiles.map((file) => URL.createObjectURL(file));

  //     setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  //     setFilePreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);

  //     newFiles.forEach((file) => {
  //       console.log("File added:", file.name);
  //     });
  //   }
  // };

  const handleFileChange = (event) => {
    const files = event.target.files;
    const maxSize = 5 * 1024 * 1024; // 5 MB in bytes

    if (files.length > 0) {
      const newFiles = Array.from(files);
      const validFiles = newFiles.filter((file) => {
        if (file.size > maxSize) {
          toast.error(
            `File "${file.name}" is too large. Maximum size allowed is 5 MB.`
          );
          return false;
        }
        return true;
      });

      const newPreviews = validFiles.map((file) => URL.createObjectURL(file));

      setUploadedFiles((prevFiles) => [...prevFiles, ...validFiles]);
      setFilePreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);

      validFiles.forEach((file) => {
        console.log("File added:", file.name);
      });
    }
  };

  const removeFile = (index) => {
    setUploadedFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });

    setFilePreviews((prevPreviews) => {
      const newPreviews = [...prevPreviews];
      newPreviews.splice(index, 1);
      return newPreviews;
    });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);

    const files = e.dataTransfer.files;
    if (files.length > 0) {
      const newFiles = Array.from(files);
      const newPreviews = newFiles.map((file) => URL.createObjectURL(file));

      setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
      setFilePreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);

      newFiles.forEach((file) => {
        console.log("File added:", file.name);
      });
    }
  };

  const formatCurrency = (value) => {
    if (!value) return "";
    const options = {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    };
    return new Intl.NumberFormat("en-US", options).format(value);
  };

  const handleFundraiserGoalChange = (e, onChange) => {
    let value = e.target.value.replace(/\D/g, "");
    if (value === "") {
      onChange(null);
      return;
    }
    value = parseInt(value, 10);
    onChange(value);
  };

  const onSubmitHandler = async (data, event) => {
    console.log("Submit Clicked", data);
    const partnerId = config.partnerId;
    console.log(partnerId);
    if (!partnerId) {
      throw new Error("Partner ID is not defined");
    }
    if (uploadedFiles.length === 0) {
      toast.error("Please select document to upload..");
      return;
    }
    event.preventDefault();
    setLoading(true);
    const formData = new FormData();
    const targetDate = new Date(data.targetDate);
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const selectedDate = targetDate.toLocaleDateString("en-IN", options);

    formData.append("fundraiserName", "");
    formData.append("partnerId", partnerId);
    formData.append("relationId", 1);
    formData.append("otherInput", "");
    formData.append("address", "");
    formData.append("zipcode", "");
    formData.append("state", "");
    formData.append("country", "");
    formData.append("desc", data.description);
    formData.append("causeTypesIds", causeList);
    formData.append("resourceType", 1);
    formData.append("title", data.title);
    formData.append("currencyType", "USD");
    formData.append("goalAmt", parseFloat(data.fundraiserGoal));
    formData.append("status", 1);
    formData.append("targetDate", selectedDate);
    uploadedFiles.forEach((file) => {
      formData.append("file", file);
    });
    formData.append("ssnNo", "");

    const token = localStorage.getItem("accessToken");
    axios
      .post(`${TQ_URL}/saveFundraiserDetails`, formData, {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.Msg === "Fundraiser Details Saved Successfully")
          successFormSubmit();

        setTimeout(() => {
          navigate(`/fundraiser/${response.data.fundraiserId}`);
        }, 1000);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.data.message === "Failed to upload files..")
          toast.error("Failed to upload the image.. Please try again.");
        else toast.error("Something Went wrong");
        console.error("Error uploading file:", error);
      });
  };

  const successFormSubmit = () => toast.success("Form Submitted Successfully!");

  const openSliderModal = () => {
    setShowSliderModal(true);
  };

  const closeSliderModal = () => {
    setShowSliderModal(false);
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <Header />
      <ProgressBar value={50} size="sm" color="blue" />

      {loading ? (
        <div className="flex flex-col items-center justify-center h-screen w-full">
          <Triangle
            height="120"
            width="120"
            color="blue"
            ariaLabel="triangle-loading"
            visible={true}
          />
          <p className="text-blue-500 mt-4">
            Please wait, we are creating your fundraiser
          </p>
        </div>
      ) : (
        <div>
          <h2 className="md:text-3xl text-xl font-bold mb-4 text-center uppercase py-10">
            Add more details for organisation / Individual
          </h2>

          <div className="md:pb-20 md:flex justify-between">
            <div className="2xl:w-1/4 lg:w-1/3 md:me-20 mx-10 md:mx-0">
              <img src="assets/images/information.jpg" className="md:w-full" />
            </div>

            <div className="md:w-2/3 m-4 md:m-0">
              <form
                onSubmit={handleSubmit(onSubmitHandler)}
                className="fundraiser-for-other-form md:py-12 md:px-20 p-6 md:me-20"
              >
                {/* Upload Document */}
                <div className="md:flex items-center">
                  <label>Upload Documents</label>
                  <div
                    className="flex justify-between w-full items-center my-5 md:my-0"
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                  >
                    <div
                      className="mb-6 relative flex flex-col w-full"
                      style={{
                        borderColor: isDragOver ? "blue" : "gray",
                        boxShadow: isDragOver
                          ? "0 0 10px rgba(0, 0, 255, 0.5)"
                          : "none",
                        borderRadius: "8px",
                        borderStyle: "dashed",
                        borderWidth: "2px",
                        paddingInline: "20px",
                        paddingBlock: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <div className="flex justify-between items-center">
                        {/* Display uploaded files with remove button */}
                        <div className="flex flex-col w-full">
                          {uploadedFiles.map((file, index) => (
                            <div
                              key={index}
                              className="flex justify-between items-center mb-2"
                            >
                              <p className="mr-2">{file.name}</p>
                              <button
                                type="button"
                                className="text-red-500 hover:text-red-700 cursor-pointer"
                                onClick={() => removeFile(index)}
                              >
                                &#x2715;
                              </button>
                            </div>
                          ))}
                        </div>
                        {/* Preview Button */}
                        {uploadedFiles.length > 0 && (
                          <button
                            type="button"
                            onClick={openSliderModal}
                            className="text-blue-500 underline ml-2"
                          >
                            Show Preview
                          </button>
                        )}
                      </div>

                      <label
                        htmlFor="documentInput"
                        className="cursor-pointer mt-4"
                      >
                        <img
                          src="assets/images/cloud-icon.svg"
                          className="mx-auto"
                          alt="Cloud Icon"
                        />
                        <p className="text-xs text-center">
                          <span className="font-bold underline">
                            Click to upload
                          </span>
                          <br />
                          Doc, PNG, JPG, JPEG, MP4, PDF (Max. 800 x 400, Size up
                          to 2 MB)
                        </p>
                      </label>
                      <input
                        {...register("document")}
                        type="file"
                        accept=".jpg, .jpeg, .png, .doc, .pdf, .mp4"
                        onChange={handleFileChange}
                        className="hidden"
                        id="documentInput"
                        multiple
                      />
                    </div>
                  </div>
                </div>

                {/* title */}
                <div className="mb-6 relative md:flex">
                  <label>Title</label>
                  <div className="w-full">
                    <input
                      {...register("title")}
                      placeholder="title"
                      type="text"
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                        errors.title && "border-red-500"
                      }`}
                    />
                    <p className="text-red-500 text-sm mt-1">
                      {errors.title?.message}
                    </p>
                  </div>
                </div>

                {/* Description */}
                <div className="mb-6 relative md:flex">
                  <label>Description</label>
                  <div className="w-full">
                    <textarea
                      {...register("description")}
                      rows={3}
                      style={{ resize: "none" }}
                      placeholder="Description"
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                        errors.description && "border-red-500"
                      }`}
                    />
                    <p className="text-red-500 text-sm mt-1">
                      {errors.description?.message}
                    </p>
                  </div>
                </div>

                {/* Fundraiser Goal */}
                <div className="mb-6 relative md:flex">
                  <label>Fundraiser Goal</label>
                  <div className="w-full relative">
                    <Controller
                      name="fundraiserGoal"
                      control={control}
                      render={({ field }) => (
                        <>
                          <input
                            type="text"
                            placeholder="Fundraiser Goal"
                            className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                              errors.fundraiserGoal && "border-red-500"
                            }`}
                            value={
                              field.value ? formatCurrency(field.value) : ""
                            }
                            onChange={(e) =>
                              handleFundraiserGoalChange(e, field.onChange)
                            }
                          />
                          
                        </>
                      )}
                    />
                    <p className="text-red-500 text-sm mt-1">
                      {errors.fundraiserGoal?.message}
                    </p>
                  </div>
                </div>

                {/* Target Date */}
                <div className="mb-6 md:flex">
                  <label>Target Date</label>
                  <div className="w-full">
                    <Controller
                      name="targetDate"
                      control={control}
                      render={({ field }) => (
                        <>
                          <DatePickerInput
                            placeholder="Pick date"
                            {...field}
                            required
                            className={`bg-gray-50  border-gray-300 text-gray-900 text-sm focus:border-blue-500 ${
                              errors.targetDate && "border-red-500"
                            }`}
                            onChange={(value) => {
                              const dateValue =
                                value instanceof Date
                                  ? value
                                  : typeof value === "string"
                                  ? new Date(value)
                                  : null;

                              field.onChange(dateValue);
                            }}
                            minDate={new Date()}
                          />
                        </>
                      )}
                    />
                    {errors.targetDate && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.targetDate.message}
                      </p>
                    )}
                  </div>
                </div>

                {/* Terms and Conditions Checkbox */}
                <div className="mb-6 md:flex justify-between">
                  <div></div>

                  <div className="md:w-4/6">
                    <input
                      {...register("termsAndConditions")}
                      type="checkbox"
                      className={`mr-2`}
                    />
                    <label className="text-dark-200">
                      Clicking implies agreement with our{" "}
                      <a onClick={() => setShowTermsPopup(true)}>
                        {" "}
                        <u>Terms and Conditions</u>
                      </a>
                    </label>
                    <p className="text-red-500 text-sm mt-1">
                      {errors.termsAndConditions?.message}
                    </p>
                    <div className="flex w-full md:justify-between items-center justify-center">
                      {/* Submit Button */}
                      <button
                        type="submit"
                        className="md:w-1/3 w-1/2 text-white p-2 rounded-md btn-style-1 mt-4 "
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>

                {/* Terms and Conditions Popup */}
                {showTermsPopup && (
                  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-6 rounded-md max-w-md w-full">
                      <h2 className="text-xl font-bold mb-4">
                        Terms and Conditions
                      </h2>
                      <p>
                        By accessing or using our services, you agree to comply
                        with all applicable laws and regulations. You also agree
                        not to use our services for any illegal or unauthorized
                        purpose, and to respect the rights of others in the
                        community.
                      </p>
                      <div className="mt-4">
                        <button
                          onClick={() => {
                            setShowTermsPopup(false);
                          }}
                          className="mt-6 p-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 cursor-pointer"
                        >
                          Accept
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      )}

      {/* Modal for displaying images in a slider */}
      {showSliderModal && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-md max-w-lg w-full h-auto relative">
            <button
              onClick={closeSliderModal}
              className="absolute top-2 right-2 text-red-500 text-2xl font-bold"
            >
              &times;
            </button>
            <Slider {...sliderSettings}>
              {filePreviews.map((preview, index) => (
                <div key={index} className="flex justify-center items-center">
                  <img
                    src={preview}
                    alt={`Preview ${index}`}
                    className="w-full max-h-96 object-contain"
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      )}

      <ToastContainer theme="colored" />
      <Footer />
    </>
  );
};

export default Self;
