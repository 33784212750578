import React, { useState, useEffect } from "react";
import TopHeader from "../Home/TopHeader";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { ActionIcon, Box, Button } from "@mantine/core";

import { IconTrash } from "@tabler/icons-react";

import HistorySidebar from "../Home/HistorySidebar";
import ReceiptPopup from "../Common/ReceiptPopup";
import { getRequestWithOutToken } from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import axios from "axios";
import { Link } from "react-router-dom";

const columns = [
  {
    accessorKey: "id",
    header: "ID",
  },

  {
    accessorKey: "donerName",
    header: "Donor Name",
  },
  {
    accessorKey: "donationCause",
    header: "Cause Name",
  },
  {
    accessorKey: "subscriptionFrequncy",
    header: "Subscription Frequency",
  },
  {
    accessorKey: "donationAmount",
    header: "Donation Amount",
  },
  {
    accessorKey: "totalAmountDonated",
    header: "Total Donation Amount",
  },
  {
    accessorKey: "currentStatus",
    header: "Status",
    Cell: ({ cell }) => (
      <Box
        sx={(theme) => ({
          backgroundColor:
            cell.getValue() === "Active"
              ? "#DAF4EB"
              : cell.getValue() === "Stop"
              ? "#FDE4E4"
              : cell.getValue() === "Pending"
              ? "#FFE0A1"
              : "inherit",
          color:
            cell.getValue() === "Active"
              ? theme.colors.green[9]
              : cell.getValue() === "Stop"
              ? "#FF0000"
              : cell.getValue() === "Pending"
              ? "#FFA200"
              : "inherit",
          borderRadius: "4px",
          padding: "4px",
          width: "80px",
          textAlign: "center",
        })}
      >
        {cell.getValue()}
      </Box>
    ),
  },
];

const AllMoney = () => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [data, setData] = useState([]);

  useEffect(() => {
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    getAllSubscription();
  }, [
    pagination.pageIndex, //refetch when page index changes
    pagination.pageSize, //refetch when page size changes
  ]);

  const getAllSubscription = async () => {
    const url = new URL(`${TQ_URL}/allSubscriptions`);
    url.search = new URLSearchParams();
    url.searchParams.set("start", `${pagination.pageIndex}`);
    url.searchParams.set("size", `${pagination.pageSize}`);
    try {
      const response = await getRequestWithOutToken(url.toString());

      console.log(response);
      const dataWithSerialNumber = response.datalist.map((item, index) => ({
        ...item,
        id: index + 1,
      }));

      setData(dataWithSerialNumber);
      //setData(response.datalist);
      setRowCount(response.totalCount);
    } catch (error) {
      console.error("Error in fetching All Trasactions:", error);
      setIsError(true);
      setIsLoading(false);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  const table = useMantineReactTable({
    columns,
    data,

    initialState: { showColumnFilters: false },
    rowCount,

    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Error loading data" }
      : undefined,

    // renderRowActions: ({ row }) => (
    //   <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
    //     <img
    //       src='assets/images/reimburse.svg'
    //       className='cursor-pointer'
    //       alt="Reimburse"
    //       onClick={() => {
    //         table.setEditingRow(row);
    //       }}
    //     />
    //   </Box>
    // ),
  });

  return (
    <div>
      <TopHeader />
      <div className="flex">
        <div className=""></div>
        <main className="w-full verify-user">
          <div className=" mx-auto shadow-md">
            <div className="md:flex justify-between container items-center px-10 md:py-6 py-6 ">
              <div className="text-left flex items-center">
                <Link to="/money-details">
                  <img
                    src="/assets/images/main-logo.svg"
                    alt="Logo"
                    className="h-10 px-6 my-5 sm:mx-0"
                  />
                </Link>
                <h2 className="text-2xl font-bold">
                  List of All Recurring Money Donation Subscription list
                </h2>
              </div>
            </div>
          </div>
          <div className="flex justify-end container mx-auto md:pt-10"></div>
          <div className="md:px-10 py-16 container mx-auto">
            <MantineReactTable table={table} />
          </div>
        </main>
      </div>
    </div>
  );
};

export default AllMoney;
