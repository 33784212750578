import React, { useCallback, useEffect, useState, useMemo } from "react";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import {
  ActionIcon,
  Box,
  Button,
  Loader,
  MantineProvider,
} from "@mantine/core";
import { Tooltip as RxTooltip } from "@mantine/core";
import TopHeader from "../Home/TopHeader";
import HistorySidebar from "../Home/HistorySidebar";
import ReceiptPopup from "../Common/ReceiptPopup";
import {
  getRequestWithTokenWithData,
  getRequestWithTokenWithoutData,
} from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";

const DonorsHistory = () => {
  const [donationData, setDonationData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [showReceiptPopup, setShowReceiptPopup] = useState(false);
  const [receiptPopupData, setReceiptPopupData] = useState(null);

  useEffect(() => {
    if (!donationData.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    getDonorsHistory();
  }, [
    pagination.pageIndex, //refetch when page index changes
    pagination.pageSize, //refetch when page size changes
  ]);

  const handleViewReceipt = (rowdata) => {
    console.log("view reciept called ", rowdata.original.fundraiserDetailDTO);
    setShowReceiptPopup(true);
    setReceiptPopupData(rowdata.original);
  };

  const getDonorsHistory = async () => {
    const url = new URL(`${TQ_URL}/getAllDonationMadeByLoginUser`);
    url.search = new URLSearchParams();
    url.searchParams.set("start", `${pagination.pageIndex}`);
    url.searchParams.set("size", `${pagination.pageSize}`);
    try {
      const response = await getRequestWithTokenWithoutData(url.toString());

      const dataWithSerialNumber = response.dataList.map((item, index) => ({
        ...item,
        id: index + 1,
      }));

      console.log(response.dataList);

      setDonationData(dataWithSerialNumber);
      setRowCount(response.totalCount);
      setIsRefetching(false);
    } catch (error) {
      console.error("Error in fetching the donor's history:", error);
      setIsError(true);
      setIsLoading(false);
      setIsRefetching(false);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  const formatCurrency = (value) => {
    if (!value) return "";
    const amount = parseFloat(value);
    if (isNaN(amount)) return "";
    return `$${amount.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "formattedDate",
        header: "Date",
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "fundraiserCausename",
        header: "Fundraiser title",
        enableSorting: false,
        enableColumnFilter: false,

        Cell: ({ row }) => {
          const title = row.original.fundraiserCausename;
          return (
            <RxTooltip
              label={title || ""}
              styles={(theme) => ({
                tooltip: {
                  backgroundColor: theme.colors.blue[9],
                  color: theme.white,
                },
              })}
            >
              <span>
                {title && title.length > 15
                  ? `${title.substring(0, 15)}...`
                  : title}
              </span>
            </RxTooltip>
          );
        },
      },
      {
        accessorKey: "amount",
        header: "Donated Amount",
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ cell }) => <Box>{formatCurrency(cell.getValue())}</Box>,
      },
      {
        accessorKey: "donationStatus",
        header: "Status",
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ cell, row }) => (
          <Box
            sx={(theme) => ({
              backgroundColor:
                row.original.donationStatus === "Complete" ||
                row.original.donationStatus === "paid"
                  ? "#DAF4EB"
                  : row.original.donationStatus === "Cancel" ||
                    row.original.donationStatus === "Cancelled"
                  ? "#FDE4E4"
                  : row.original.donationStatus === "Pending"
                  ? "#FFE0A1"
                  : "inherit",
              color:
                row.original.donationStatus === "Complete" ||
                row.original.donationStatus === "paid"
                  ? theme.colors.green[9]
                  : row.original.donationStatus === "Cancel"
                  ? "#FF0000"
                  : row.original.donationStatus === "Pending"
                  ? "#FFA200"
                  : "inherit",
              borderRadius: "4px",
              padding: "4px",
              width: "80px",
              textAlign: "center",
            })}
          >
            {cell.getValue()}
          </Box>
        ),
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data: donationData,
    enableRowActions: true,
    positionActionsColumn: "last",
    initialState: { showColumnFilters: false },
    rowCount,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Error loading data" }
      : undefined,
    renderRowActions: ({ row }) => (
      <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
        <Button onClick={() => handleViewReceipt(row)} variant="outline">
          View Receipt
        </Button>
      </Box>
    ),
  });

  return (
    <>
      <TopHeader />
      <div className="flex">
        <div className="md:w-64 w-1/12 absolute md:relative">
          <HistorySidebar />
        </div>
        <main className="w-full verify-user">
          <div className=" mx-auto shadow-md">
            <div className="md:flex justify-between container items-center px-10 md:py-6 py-6 ">
              <div className="md:text-left text-center">
                <h2 className="text-2xl font-bold">Donor's History</h2>
              </div>
            </div>
          </div>
          <div className="md:mx-auto md:py-16 py-6">
            <MantineReactTable table={table} />
            {showReceiptPopup && (
              <ReceiptPopup
                onClose={() => {
                  setShowReceiptPopup(false);
                  setReceiptPopupData(null);
                }}
                rowData={receiptPopupData}
              />
            )}
          </div>
        </main>
      </div>
    </>
  );
};

export default DonorsHistory;
