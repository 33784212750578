import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useLocation } from "react-router-dom";
import { TQ_URL } from "../../utility/baseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const schema = yup.object().shape({
  address: yup.string().required("Address is Required * "),
  city: yup.string().required("City is Required * "),
  state: yup.string().required("State is Required * "),
  zipcode: yup
    .number()
    .required("Zip Postal Code is Required * ")
    .positive("Zip Postal should be positive or non-zero")
    .typeError("Zip Postal should not be Empty"),

  // password: yup.string().required("Password is Required *"),
  country: yup.string().required("Country is Required *"),
});

const NgoAddressDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [storedUserId, setStoredUserId] = useState(null);
  var emailAddress = new URLSearchParams(location.search).get("email");

  console.log(emailAddress);

  const [stateName, setStateName] = useState(null);
  const [cityName, setCityName] = useState(null);
  const [notFoundMessage, setNotFoundMessage] = useState("");
  const [countryFlag, setCountryFlag] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState(false);

  const [userType, setUserType] = useState("");
  const [flag, setFlag] = useState();

  // let userTypeLocal = '';
  useEffect(() => {
    const fetchDataFromStorage = async () => {
      try {
        const storedData = localStorage.getItem("userData");
        setFlag(localStorage.getItem("OauthFlag"));
        if (storedData) {
          const userData = JSON.parse(storedData);
          const userTypeFromStorage = userData.userType;
          setUserType(userTypeFromStorage);
        }
      } catch (error) {
        console.error("Error fetching and setting userType:", error);
      }
    };

    fetchDataFromStorage();
  }, []);
  console.log(userType);

  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleSubmitVerify = (userId) => {
    // event.preventDefault();

    const values = {
      userId: userId,
    };

    // navigate("/verifyuser", { state: values });
    navigate("/success");
  };

  const onSubmitHandler = async (data, event) => {
    console.log("page is hitting success ");
    console.log({ data });

    if (emailAddress == null) {
      const storedData = localStorage.getItem("userData");
      if (storedData) {
        const userData = JSON.parse(storedData);
        emailAddress = userData.email;
        console.log(emailAddress);
      }
    }

    event.preventDefault();
    const response = await fetch(`${TQ_URL}/savePartnerAddressDetails`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: emailAddress,
        address: data.address,
        city: data.city,
        state: data.state,
        country: data.country,
        postalCode: data.zipcode,
        userType: data.organization,
        isPartner: true
      }),
    });

    localStorage.setItem("redirectFrom", "addressDetails");

    const res = await response.json();

    console.log("BODY >>", res);

    if (response.status === 200) {
      console.log(res);
      // setStoredUserId(res.userId);
      userCreated( res.message);
      setTimeout(() => {
        navigate("/ngo-login");
      }, 2000);
     
    }
    else{
      userCreated( res.message);
    }
  };

  const userCreated = (msg) => {
    toast.success(msg, {
      position: "top-right",
    });

    // setTimeout(() => {
    //   navigate("/success");
    // }, 2000);
  };
  const userNotCreated = () => {
    toast.error("Failed to saved the details", { position: "top-right" });
  };
  const onErrors = (error) => {
    console.log("page is hitting  ERRORORORORO", error);
  };

  const zipCodeDetails = async (event) => {
    const zipcode = event.target.value;
    console.log(zipcode);

    if (!zipcode) {
      setNotFoundMessage("Zip Postal Code is required");
      setStateName("");
      setCityName("");
      setCountryFlag(false);
      return; // Exit early if the zip code is empty
    }
    if (zipcode.length === 5) {
      try {
        const response = await fetch(`${TQ_URL}/getAddressDetails`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            zipcode: zipcode,
          }),
        });

        if (response.status == 200) {
          const res = await response.json();
          if (res.Status == "Succsess") {
            setStateName(res.State.state);
            setCityName(res.State.city);
            setValue("state", res.State.state);
            setValue("city", res.State.city);

            setValue("country", "United States");
            setNotFoundMessage("");
            setCountryFlag(true);
          } else {
            setNotFoundMessage("Incorrect Zip Code");
            setStateName("");
            setCountryFlag(false);
          }
        }
      } catch (error) {
        console.error("Error making post request:", error);
      }
    } else {
      setStateName("");
      setCountryFlag(false);
      setValue("state", "");
      setValue("city", "");
      setValue("country", "");
      setCityName("");
      setNotFoundMessage("Incorrect Zip Code");
    }
  };

  return (
    <div className="flex flex-col md:flex-row min-h-screen ">
      {/* Left Section */}
      <div className="md:flex-1 bg-cover bg-center relative fade-in-left create-profile-bg">
        <div className="absolute inset-0 bg-black opacity-40"></div>
        <div className="absolute inset-0 flex flex-col items-start justify-start md:px-20 px-5 mt-28">
          <h3 className="text-white txt-style-1 mb-5">
            Work For charity? <br />
            Sign in for a Charity Account?
          </h3>
          <h3 className="text-white txt-style-2 mb-10">
            Add Address
            <br />
            Details
          </h3>
        </div>
      </div>

      {/* Right Section */}
      <div className="md:flex-1 flex items-center justify-start  p-10 sm:p-10 md:ms-10 max-w-screen-lg mx-auto w-full  h-full fade-in-right">
        <div className="max-w-full w-full sm:max-w-lg 2xl:max-w-2xl ">
          {/* Logo */}
          <div className="text-center md:text-left mb-10 sm:mb-12">
            <Link to="/">
              <img
                src="assets/images/main-logo.svg"
                alt="Logo"
                className="h-10 mx-auto sm:mx-0"
              />
            </Link>
          </div>

          {/* FORM */}
          <form onSubmit={handleSubmit(onSubmitHandler, onErrors)}>
            {userType == "Individual" && flag == 1 ? (
              <>
                <div className="flex justify-between mb-5 w-1/2">
                  <div className="flex items-center">
                    <Controller
                      name="organization"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Please select an option" }}
                      render={({ field }) => (
                        <div className="flex">
                          <input
                            {...field}
                            type="radio"
                            id="organization-radio"
                            value="Organization"
                            className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                              errors.organization && "border-red-500"
                            }`}
                          />
                          <label
                            htmlFor="organization-radio"
                            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 me-4"
                          >
                            Organization
                          </label>
                        </div>
                      )}
                    />
                  </div>

                  <div className="flex items-center">
                    <Controller
                      name="organization"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Please select an option" }}
                      render={({ field }) => (
                        <div className="flex">
                          <input
                            {...field}
                            defaultChecked
                            type="radio"
                            id="individual-radio"
                            value="Individual"
                            className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                              errors.organization && "border-red-500"
                            }`}
                          />
                          <label
                            htmlFor="individual-radio"
                            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            Individual
                          </label>
                        </div>
                      )}
                    />
                  </div>
                </div>

                <p className="text-red-500 text-sm mb-6">
                  {!selectedOrganization &&
                    errors.organization &&
                    errors.organization.message}
                </p>

                {!watch("organization") && selectedOrganization && (
                  <p className="text-red-500 text-sm mt-1">
                    Please select an organization type before proceeding.
                  </p>
                )}
              </>
            ) : null}

            <div className="mb-6">
              <input
                {...register("address")}
                placeholder="Address *"
                type="text"
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                  errors.address && "border-red-500"
                }`}
              />
              <p className="text-red-500 text-sm mt-1">
                {errors.address?.message}
              </p>
            </div>

            <div className="mb-6">
              <input
                {...register("zipcode")}
                placeholder="Zip Postal Code *"
                onFocus={(e) =>
                  e.target.addEventListener(
                    "wheel",
                    function (e) {
                      e.preventDefault();
                    },
                    { passive: false }
                  )
                }
                onBlur={zipCodeDetails}
                type="number"
                maxLength={6}
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                  errors.zipcode && stateName == null ? "border-red-500" : ""
                }`}
              />
              <p className="text-red-500 text-sm mt-1">
                {errors.zipcode?.message}
              </p>

              {!errors.zipcode?.message && (
                <p className="text-red-500 text-sm mt-1">{notFoundMessage}</p>
              )}
            </div>
            <div className="mb-6">
              <input
                {...register("city")}
                placeholder="City *"
                type="text"
                value={cityName}
                readOnly
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                  errors.city && stateName == null ? "border-red-500" : ""
                }`}
              />
              <p
                className={`text-red-500 text-sm mt-1 ${cityName && "hidden"}`}
              >
                {errors.city?.message}
              </p>
            </div>
            <div className="mb-6">
              <input
                {...register("state")}
                placeholder="State *"
                type="text"
                readOnly
                value={stateName}
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                  errors.state && stateName == null ? "border-red-500" : ""
                }`}
              />
              <p
                className={`text-red-500 text-sm mt-1 ${stateName && "hidden"}`}
              >
                {errors.state?.message}
              </p>
            </div>

            {/* Country Dropdown */}
            <div className="flex items-center my-8">
              <label
                htmlFor="countries"
                className="block me-8 text-sm font-medium text-gray-400"
              >
                Country*
              </label>
              <select
                {...register("country", { required: "Country is Required *" })}
                id="countries"
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/2 p-2.5 ${
                  errors.state && !countryFlag ? "border-red-500" : ""
                }`}
                value={countryFlag ? "United States" : ""}
                disabled
              >
                <option value="">Select Country</option>
                <option value="United States">United States</option>
                <option value="Canada">Canada</option>
                <option value="France">France</option>
                <option value="Germany">Germany</option>
              </select>
            </div>
            <p
              className={`text-red-500 text-sm mt-1 ${countryFlag && "hidden"}`}
            >
              {errors.country?.message}
            </p>

            {/* Log In Button */}
            <div className="flex  justify-between mt-10 space-x-3 ">
              <div>
                {/* <Link to='/success'> */}
                <button
                  type="submit"
                  className="text-white px-6 py-2 rounded-sm btn-style-1"
                >
                  Continue
                </button>
                {/* </Link> */}
              </div>
              {/* <div>
                <a href="#">
                  <button
                    className=" text-white px-8 py-2 rounded-sm btn-style-2 "
                    onClick={handleSubmitVerify}
                  >
                    Verify Yourself
                  </button>
                </a>
                <button className=" text-white px-10 py-2 rounded-sm btn-style-1 ms-5">
                  Skip
                </button>
              </div> */}
            </div>
          </form>
          <ToastContainer theme="colored" />
        </div>
      </div>
    </div>
  );
};

export default NgoAddressDetails;
