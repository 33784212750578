import React, { useState, useRef, useEffect } from "react";
import TopHeader from "../Home/TopHeader";
import DonorManagementSideBar from "../DonorManagement/DonorManagementSideBar";
import { useNavigate, Link } from "react-router-dom";
import SocialMediaSideBar from "./SocialMediaSideBar";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { DatePickerInput } from "@mantine/dates";
import AOS from "aos";
import "aos/dist/aos.css";

const localizer = momentLocalizer(moment);
const statusColors = {
  Completed: "#4CAF50", // Green
  Pending: "#FFC107", // Yellow
  Canceled: "#F44336", // Red

};
const SchedulePost = () => {
  const [value, setValue] = useState(new Date());
  const [hoveredSlot, setHoveredSlot] = useState(null);
  const [buttonVisible, setButtonVisible] = useState(false);
  const [buttonPosition, setButtonPosition] = useState({ x: 0, y: 0 });
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [currentTimeSlot, setCurrentTimeSlot] = useState(null);

  const navigate = useNavigate();

  const [events, setEvents] = useState([
    {
      start: new Date(),
      end: new Date(),
      title: "Default Event",
      status: "Completed",
    },
    {
      start: new Date("2024-04-26T10:00:00"),
      end: new Date("2024-04-26T10:00:00"),
      title: "Default Event",
      status: "Pending",
    },
  ]);
  const handleSelectSlot = (slotInfo) => {
    setButtonVisible(true);

    const start = slotInfo.start;
    const end = slotInfo.end;

    setSelectedSlot({ start, end });
    const x = slotInfo.box.x; // Adjust as needed based on your calendar structure
    const y = slotInfo.box.y;
    console.log(x, "  ", y);
    setButtonPosition({ x, y });
    console.log(slotInfo);
    setCurrentTimeSlot(slotInfo);
  
  };

  useEffect(() => {
    AOS.init();
  }, []);

  const handleAddPost = () => {
    console.log("Add post button clicked");
    setButtonVisible(false);
    console.log(currentTimeSlot);

    navigate("/createPost", { state: { currentTimeSlot } });
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    const backgroundColor = statusColors[event.status] || "#FFFFFF"; // Default white if status not found
    const style = {
      backgroundColor,
      borderRadius: "4px",
      opacity: 0.8,
      color: "#000000", // Text color (adjust as needed)
      border: "none",
      display: "block",
    };

    return {
      style,
    };
  };

  const handleSlotHover = (slot) => {
    setHoveredSlot(slot);
  };

  // Function to handle mouse leave
  const handleMouseLeave = () => {
    setHoveredSlot(null);
  };

  const slotPropGetter = (date) => {
   

    let style = {};

    
    if (
      selectedSlot &&
      moment(date).isSameOrAfter(selectedSlot.start) &&
      moment(date).isBefore(selectedSlot.end)
    ) {
      style = {
        backgroundColor: "lightblue", // Change to your desired color
      };
    }

  
    if (
      hoveredSlot &&
      moment(date).isSameOrAfter(hoveredSlot.start) &&
      moment(date).isBefore(hoveredSlot.end)
    ) {
      style = {
        backgroundColor: "yellow", 
      };
    }

    return { style };
  };

  const defaultDate = new Date();
  return (
    <>
      <TopHeader />
      <div className="flex">
        <div className="md:w-64 w-1/12 absolute md:relative">
          <SocialMediaSideBar />
        </div>

        <main className="w-full verify-user">
          <div className=" mx-auto shadow-md">
            <div className="md:flex justify-between container items-center px-10 md:py-6 py-6">
              <div className="text-center">
                <h2 className="text-2xl font-bold">Schedule Post</h2>
              </div>
            </div>
          </div>

          <div className="md:w-2/12 md:mx-8 md:py-2 py-4">
            <DatePickerInput
              label="Pick date"
              placeholder="Pick date"
              value={value}
              onChange={setValue}
            />
          </div>

          <div className="md:mx-8 md:py-8 py-6">
            <Calendar
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              view="day" // Specify the view as "day"
              views={["day"]}
              style={{ height: "100vh", width: "100%" }}
              defaultDate={defaultDate}
              toolbar={false}
              onSelectSlot={handleSelectSlot}
              selectable // Enable slot selection
              eventPropGetter={eventStyleGetter}
              slotPropGetter={slotPropGetter}
              onSelectEvent={null}
              onMouseEnter={(slot) => handleSlotHover(slot)}
              onMouseLeave={handleMouseLeave}
            />

            {buttonVisible && (
              <button
                style={{
                  position: "absolute",
                  top: `${buttonPosition.y}px`,
                  left: `${buttonPosition.x}px`,
                  zIndex: 1000,
                  border: "2px solid #000", // Border color (e.g. green)
                  backgroundColor: "#fff", // Background color (e.g. light gray)
                  padding: "6px 10px", // Padding for button
                  borderRadius: "4px", // Optional: rounded corners
                  boxShadow: "2px 2px 4px gray",
                }}
                onClick={handleAddPost}
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                Add Post
              </button>
            )}
          </div>
        </main>
      </div>
    </>
  );
};

export default SchedulePost;
